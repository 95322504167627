/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useReducer, useEffect, useCallback, useContext } from 'react'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/react-hooks'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { SHOW as ALERTSNACKBARSHOW } from 'reducer/alertSnackbar'
import { SHOW as POPOVERSHOW } from 'reducer/popover'
import AccessMethodContext from 'context/accessMethod'
import EditSurveyContext from 'context/editSurvey'
import PropsContext from 'context/props'
import { scroller, Element } from 'react-scroll'
import { useIntl } from 'react-intl'
import { withPrevState, withSurveySetReplys } from 'hoc'
import { getBackground } from 'utils/survey'
import { isEqual } from 'lodash-es'
import { CREATE_SURVEY_QUESTION_COPY, CREATE_SURVEY_QUESTION_APPEND, UPDATE_SURVEY_QUESTIONS_SORT, DELETE_SURVEY_QUESTION } from 'gql/survey_question'
import { DELETE_SURVEY_PAGE } from 'gql/survey_page'
import { makeStyles } from '@material-ui/core/styles'
import { withSurveyFetchQuestionsWithJumpMulti } from 'hoc'
import useLimitQuestionMessage from 'hooks/useLimitQuestionMessage'
import { memoryReply } from 'container/Survey/Live/Container'
import { 
    SurveyCreateModuleMoveComponent, 
    SurveyCreateModuleOtherCopyComponent, 
    SurveyCreateModuleAppendComponent, 
    SurveyCreateModuleBodyComponent,
    SurveyCreateModuleQuestionsBodyComponent,
    SurveyCreateModuleRemovePageComponent,
    SurveyCreateModuleCautionComponent
} from 'component'
import { SurveyCreateModuleItemsContainer } from 'container'
import HelpComponent from 'component/Survey/Create/Module/Help'
import { mode } from '../ModuleRoot'


const useStyles = makeStyles(theme => ({
    element: props => {
        return {
            ...props,
            height: 'calc(100vh - 64px)',
            [theme.breakpoints.down('md')]: {
                height: 'calc(100vh - 124px)'
            },
            [theme.breakpoints.down('xs')]: {
                height: 'calc(100vh - 12.3em)'
            },
            backgroundAttachment: 'scroll'
        }
    }
}))

// 초기값
const initialState = {
    setting: {
        anchorEl: null, // 셋팅 클릭시 뜨는 레이어
        anchorElSub: null, // 클릭에서 나오는메뉴에서 클릭시 나오는 레이어
        selected: null
    },
    questions: [],
    designContents: {
        numbering: '',
        question: '',
        answer: '',
        answer_button: '',
        answer_sbutton: ''
    },
    designBackground: {
        background_color: '',
        background_image: '',
        background_filter: '',
        background_repeat: '',
        background_opacity: ''
    },
    copy: {
        survey_page_no: '', // 타켓 서베이페이지 번호
        source_survey_question_no: '',
        target_survey_question_no: '',
        position: 1,
        anchorEl: null,
        type: 'copy' // copy, move
    },
    movePage: {
        target_survey_page_no: '', // 타켓 서베이페이지 번호
        source_survey_page_no: '', // 오리진 서베이페이지번호
        position: true,
        anchorEl: null
    },
    append: {
        survey_question_no: '',
        anchorEl: null
    },
    target: '', // 문항 복사 후 해당위치로 가기위해 survey_question_no를 담는다
    sorting: false // 설문 리스트 정렬중인지 아닌지 판단 (설문소팅일때는 로딩이미지 안보여준다)
}



const handleReducer = (state, action) => {
    switch (action.type) {
        case 'setting': { // 설문설정 open
            return { ...state, setting: action.setting }
        }
        case 'questions': { // 설문문항
            return { ...state, questions: action.questions }
        }
        case 'designContents': { // 설문디자인 내용 (질문, 보기 버튼등..)
            return { ...state, designContents: action.designContents }
        }
        case 'designBackground': { // 설문디자인 (백그라운드 이미지 색상등)
            return { ...state, designBackground: action.designBackground }
        }
        case 'copy': { // 설문문항 복사
            return { ...state, copy: action.copy }
        }
        case 'movePage': { // 페이지 이동
            return { ...state, movePage: action.movePage }
        }
        case 'append': { // 모듈 추가
            return { ...state, append: action.append }
        }
        case 'target': { // 설문문항 복사 후 해당번호로 타겟
            return { ...state, target: action.target }
        }
        case 'sorting': {
            return { ...state, sorting: action.sorting }
        }
        default: {
            throw new Error(`unexpected action.type: ${action.type}`)
        }
    }
}


// 질문 복사 이동등할때 해당문항으로 스크롤 포커스간다
function focusQuestion(props) {
    const { scrollerObj, time=1000, option={} } = props

    return new Promise((resolve, reject) => {
        setTimeout(() => {
            scroller.scrollTo(scrollerObj.target, {
                ...scrollerObj.scrollTo,
                containerId: scrollerObj.id,
                ...option
            })
    
            resolve()
        }, time)
    })
}

const Create = (props) => {

    const { mbox, option, scrollerObj, viewType, editQuestion={}, editDesign, focus={}, propsSetReplys, rowsJump } = props
    const { 
        handleChangeReplys, 
        refComments01,
        refComments02,
        refComments09,
        refComments10,
        refComments12,
        refComments13,
        refComments17,
        refComments18,
        refComments20,
        refCommentsWith,
        format,
        replys    
    } = propsSetReplys

    const { editOpen } = useContext(EditSurveyContext)

    const history = useHistory()
    const location = useLocation()
    const params = useParams()
    const dispatch = useDispatch()

    const { pathname } = location

    const survey_no = Number(params.survey_no)
    
    const callLimitQuestionMessage = useLimitQuestionMessage()

    // editQuestion 이전값을 저장
    const editQuestionPrev = props.usePrevState(editQuestion)

    const questionsPrev = props.usePrevState(props.questions)

    const { formatMessage: f } = useIntl()

    const [ states, dispatchStates ] = useReducer(handleReducer, initialState)

    const classes = useStyles({...scrollerObj.style, ...getBackground(states.designBackground) })

    const [ createSurveyQuestionCopy ] = useMutation(CREATE_SURVEY_QUESTION_COPY, {
        onCompleted: () => {
            dispatch({ type: BACKDROPHIDE })
        }
    })

    const [ createSurveyQuestionAppend ] = useMutation(CREATE_SURVEY_QUESTION_APPEND, {
        onCompleted: async(data) => {
            props.refetchQuestions()

            const target = data.createSurveyQuestionAppend

            await dispatchStates({ type: 'target', target  })

            await focusQuestion({ scrollerObj } )

            dispatchStates({type: 'target', target: ''})

            dispatch({ type: BACKDROPHIDE })
        },
        onError: (e) => {
            callLimitQuestionMessage(e)
        }
    })

    const [ updateSurveyQuestionsSort ] = useMutation(UPDATE_SURVEY_QUESTIONS_SORT, {
        onCompleted: async (data) => {
            try {
                props.refetchQuestions()
            } finally {
                dispatch({ type: BACKDROPHIDE })
            }
        },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [ deleteSurveyQuestion ] = useMutation(DELETE_SURVEY_QUESTION, {
        onCompleted: async () => {
            try {
                props.refetchQuestions()
            } catch(e) {
                throw e
            } finally {
                dispatch({ type: BACKDROPHIDE })
            }
        },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [ deleteSurveyPage ] = useMutation(DELETE_SURVEY_PAGE, {
        onCompleted: async () => {
            const { data: { surveyPage } } = await props.refetchPages()
            
            const { survey_page_no } = surveyPage[surveyPage.length - 1]

            goPage(survey_page_no)
        },
        onError: (e) => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })
    
    // 페이지 이동
    const goPage = useCallback((survey_page_no) => {
        history.push(`${pathname}#page=${survey_page_no}`)
    }, [])

    // 페이지 삭제  컴포넌트
    const onRemovePage = useCallback(() => {
        try {
            const survey_page_no = Number(props.page)
            deleteSurveyPage({variables: { survey_no, survey_page_no }})
        } catch(e) {
            throw e
        }
    }, [props.page, props.pages, survey_no])
    

    // 문항 리스트에 들어가는 기능
    
    const onQuestionConfirmRemove = useCallback((e, survey_question_no, module_type) => {
        dispatch({ 
            type: POPOVERSHOW, 
            title:  f({id: 'container.Survey.Create.Module.Container.confirmRemove.title'}), 
            message: f({id: 'container.Survey.Create.Module.Container.confirmRemove.message'}),
            anchorEl: e.currentTarget,
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center'
            },
            transformOrigin: {
                vertical: 'top',
                horizontal: 'center'
            },
            onActions: [ 
                {
                    on: () => onQuestionRemove(survey_question_no, module_type), 
                    name: f({id: 'container.Survey.Create.Module.Container.confirmRemove.remove'}), 
                    color: 'secondary', 
                    hide: true 
                } 
            ]
        })
    }, [])


    const onQuestionRemove = useCallback((survey_question_no, module_type) => {
        dispatch({ type: BACKDROPSHOW })
        deleteSurveyQuestion({variables: { survey_no, survey_question_no, module_type  }})
    }, [survey_no, deleteSurveyQuestion, dispatch])


    const onQuestionOpenCopy = useCallback((e, source_survey_question_no, type) => {
        dispatchStates({ type: 'copy', copy: {
            ...states.copy,
            survey_page_no: props.page,
            source_survey_question_no,
            target_survey_question_no: source_survey_question_no,
            type,
            anchorEl: e.currentTarget
            
        } })
    }, [states.copy, props.page])


    const onQuestionOpenAppend = useCallback((e, survey_question_no) => {
        dispatchStates({ type: 'append', append: {
            anchorEl: e.currentTarget,
            survey_question_no
        } })
    }, [])

    const handleChangeCopyPage = useCallback((e) => {
        const survey_page_no = e.target.value
        dispatchStates({ type: 'copy', copy: {
            ...states.copy,
            survey_page_no,
            target_survey_question_no: ''
        }})
    }, [states.copy])

    const handleChangeCopyQuestion = useCallback((e) => {
        const target_survey_question_no = e.target.value
        dispatchStates({ type: 'copy', copy: {
            ...states.copy,
            target_survey_question_no
        }})
    }, [states.copy])

    const handleChangeCopyPosition = useCallback((e) => {
        const position = e.target.value
        dispatchStates({ type: 'copy', copy: {
            ...states.copy,
            position
        }})
    }, [states.copy])

    const handleCloseCopy = useCallback(() => {
        dispatchStates({ type: 'copy', copy: initialState.copy })
    }, [])

    const handleSaveCopy = async(other_survey_no) => {
        const { survey_page_no, source_survey_question_no, target_survey_question_no, type, position } = states.copy

        const input = {
            survey_no: other_survey_no || survey_no,
            survey_page_no, 
            source_survey_question_no, 
            target_survey_question_no: target_survey_question_no || null, 
            type,
            position: Boolean(position)
        }

        dispatch({ type: BACKDROPSHOW })
        if (!other_survey_no) await dispatchStates({ type: 'copy', copy: initialState.copy})

        try {
            const res = await createSurveyQuestionCopy({variables: { input }})

            if (res) {
                if (other_survey_no) {
                    dispatch({type: ALERTSNACKBARSHOW, message: f({id: 'container.Survey.Create.Module.Container.handleSaveCopy.success'}), variant: 'success'})
                    return
                }

                goPage(survey_page_no)
                props.refetchQuestions()
                
                const target = res.data.createSurveyQuestionCopy
    
                await dispatchStates({ type: 'target', target  })

                await focusQuestion({ scrollerObj, option: { offset: -50 }})
                
                dispatchStates({type: 'target', target: ''})
            }
        } catch(e) {
            callLimitQuestionMessage(e)
        }
    }

    const handleAppend = {
        close: () => dispatchStates({ type: 'append', append: initialState.append }),
        append: async (module_type) => {

            const input = {
                survey_no,
                survey_page_no: props.page,
                module_type, 
                target_survey_question_no: states.append.survey_question_no
            }

            dispatch({ type: BACKDROPSHOW })
            dispatchStates({ type: 'append', append: initialState.append})

            createSurveyQuestionAppend({variables: { input }})
        }
    }
    
    // Drag and Drop
    const onDnDEnd = useCallback(async(result) => {
  
        if (!result.destination || editOpen) {
            return
        }

        // survey_question 복사
        const copy = states.questions.map(c => c)

        const sourceIndex = result.source.index //원래 인덱스
        const destinationIndex = result.destination.index
    
        // 옮겨져야할 값 을 배열에서 삭제하고 value값은 저장
        const [removedData] = copy.splice(sourceIndex, 1)
    
        // 옮겨져야할 값 추가
        copy.splice(destinationIndex, 0, removedData)
        
        dispatchStates({type: 'questions', questions: copy})
        
        const survey_question_nos = copy.map(({_question}) => _question.survey_question_no)

        updateSurveyQuestionsSort({variables: { survey_no, survey_question_nos }})
    }, [states.questions, editOpen])

    useEffect(() => {
        if (!isEqual(questionsPrev, props.questions)) {
            dispatchStates({type: 'questions', questions: [...props.questions]})
        }
    }, [questionsPrev, props.questions])

    useEffect(() => {
        if (JSON.stringify(editQuestion) !== JSON.stringify(editQuestionPrev)) {
            const ds = async() => {
                
                // 편집문항 열고 닫을때 reply값 초기화
                memoryReply.rows = []

                if (typeof editQuestion._question === 'undefined') {
                    
                    dispatchStates({type: 'questions', questions: [...props.questions] })
                } else {
                    // 편집시 포커스
                    //await propsSetReplys.setReplys([])

                    if (typeof editQuestionPrev._question === 'undefined') {
                        
                        const target = editQuestion._question.survey_question_no
    
                        dispatchStates({ type: 'target', target  })

                        const option = { offset: -200 }
                        const time = 100
                        focusQuestion({ scrollerObj, option, time })

                    } else {
                        dispatchStates({type: 'questions', questions: [...states.questions.map(c => {
                            return c._question.survey_question_no === editQuestion._question.survey_question_no ? editQuestion : c
                        }) ]  })
                    }   
                }
            }
            
            ds()
        }
    }, [editQuestion, editQuestionPrev, states.questions, props.questions, propsSetReplys])

    useEffect(() => {

        if (focus.survey_question_no) {
            async function _do() {
                await dispatchStates({ type: 'target', target: focus.survey_question_no })

                const option = { offset: -200 }
                const time = 100
                focusQuestion({ scrollerObj, option, time })
            }

            _do()
        }
    }, [focus])

    // 스타일 설정값을 분리해서 내용에들어가는거랑, 백그라운이 이미지랑 분리한다
    // 배경이미지에서 opacity 설정때 문항까지 랜더링 되니까 버벅되서....
    const changeDesignState = useCallback((props) => {
        const { 
            question, answer, answer_button, answer_sbutton, font_family, numbering,
            background_color, background_image, background_filter, background_repeat, background_opacity, background_size
        } = props

        const designContents = { numbering, background_color, question, answer, answer_button, answer_sbutton, font_family }
        const designBackground = {  background_color, background_filter, background_image, background_repeat, background_opacity, background_size }

        if (!isEqual(designContents, states.designContents)) {
            dispatchStates({ type: 'designContents', designContents })
        }

        if (!isEqual(designBackground, states.designBackground)) {
            dispatchStates({ type: 'designBackground', designBackground })
        }

    }, [states.designContents, states.designBackground])

    // 처음 랜더링시 DB design값 적용
    useEffect(() => {
        changeDesignState(props.design)
    }, [props.design])

    // 디자인 편집페이지에서 스타일 변경시 실시간 변화를 위헤..
    useEffect(() => {
        if (typeof editDesign === 'object') {
            changeDesignState(editDesign)
        }
    }, [editDesign])

    useEffect(() => {
        dispatch({ type: BACKDROPHIDE })
    }, [states.questions, dispatch])

    const { indexs: pageIndex } = props.pages.find(c => c.survey_page_no === Number(props.page)) || { indexs: 1 }

    return (
        <>  
            <Element id={scrollerObj.id}  className={classes.element}>
                <SurveyCreateModuleCautionComponent method={props.mbox.method}/>
                {
                    useMemo(() => {
                        // 성능문제로 인해 SurveyCreateModuleBodyComponent에도 재랜더링이 필요한경우 값을 보내야한다
                        
                        return (
                            <SurveyCreateModuleBodyComponent
                                mbox={mbox}
                                rowsJump={rowsJump}
                                option={option} 
                                questions={states.questions}
                                design={states.designContents}
                                replys={replys}
                                target={states.target}
                                viewType={viewType}
                                pageIndex={pageIndex}
                                editOpen={editOpen}
                            >
                                <SurveyCreateModuleQuestionsBodyComponent onDnDEnd={onDnDEnd}>
                             
                                    <AccessMethodContext.Provider value={{ mode }}>
                                        <PropsContext.Provider value={{
                                            onChangeReplys: handleChangeReplys, 
                                            refComments01,
                                            refComments02,
                                            refComments09,
                                            refComments10,
                                            refComments12,
                                            refComments13,
                                            refComments17,
                                            refComments18,
                                            refComments20,
                                            refCommentsWith,
                                            format,
                                            replys,
                                            design: states.designContents,
                                            mbox,
                                            rowsJump,
                                            option
                                        }}>
                                            {
                                                pageIndex === 1 && states.questions.length === 0 ? (
                                                    !editOpen && <HelpComponent/> 
                                                ) : pageIndex > 1 && states.questions.length === 0 ? (
                                                    !editOpen && <SurveyCreateModuleRemovePageComponent onRemovePage={onRemovePage}/> 
                                                ) : (
                                                    <SurveyCreateModuleItemsContainer 
                                                        questions={states.questions}
                                                        target={states.target}
                                                        scrollerObj={scrollerObj}
                                                        mbox={mbox}
                                                        onConfirmRemove={onQuestionConfirmRemove}
                                                        onRemove={onQuestionRemove}
                                                        onOpenCopy={onQuestionOpenCopy}
                                                        onOpenAppend={onQuestionOpenAppend}
                                                        replys={propsSetReplys.replys}
                                                    />
                                                )
                                            }
                                        </PropsContext.Provider>
                                    </AccessMethodContext.Provider>
                                </SurveyCreateModuleQuestionsBodyComponent>  
                            </SurveyCreateModuleBodyComponent>
                        )
                    }, [
                        handleChangeReplys, 
                        refComments01,
                        refComments02,
                        refComments09,
                        refComments10,
                        refComments12,
                        refComments13,
                        refComments17,
                        refComments18,
                        refComments20,
                        refCommentsWith,
                        format,
                        replys,
                        states.designContents,
                        states.questions,
                        states.target,
                        onDnDEnd,
                        onQuestionConfirmRemove,
                        onQuestionRemove,
                        onQuestionOpenCopy,
                        onQuestionOpenAppend,
                        onRemovePage,
                        mbox,
                        rowsJump,
                        viewType,
                        option,
                        pageIndex
                    ])
                }
                      
            </Element>
       
            {
                useMemo(() => {
                    return (
                        <>
                        {
                            states.copy.type === 'move' ? (
                                <SurveyCreateModuleMoveComponent 
                                    anchorEl={states.copy.anchorEl}
                                    type={states.copy.type}
                                    onChangePage={handleChangeCopyPage}
                                    onChangeQuestion={handleChangeCopyQuestion}
                                    onChangePosition={handleChangeCopyPosition}
                                    onClose={handleCloseCopy}
                                    onSave={handleSaveCopy}
                                    defaults={{
                                        survey_page_no: states.copy.survey_page_no, 
                                        survey_question_no: states.copy.target_survey_question_no, 
                                        position: states.copy.position
                                    }}
                                />
                            ) : (
                                <SurveyCreateModuleOtherCopyComponent 
                                    survey_no={survey_no}
                                    anchorEl={states.copy.anchorEl}
                                    type={states.copy.type}
                                    onChangePage={handleChangeCopyPage}
                                    onChangeQuestion={handleChangeCopyQuestion}
                                    onChangePosition={handleChangeCopyPosition}
                                    onClose={handleCloseCopy}
                                    onSave={handleSaveCopy}
                                    defaults={{
                                        survey_page_no: states.copy.survey_page_no, 
                                        survey_question_no: states.copy.target_survey_question_no, 
                                        position: states.copy.position
                                    }}
                                />
                            )
                        }
                        </>
                    )
                }
                    
                , [states.copy])
            }
            
            {
                 useMemo(() => (
                    <SurveyCreateModuleAppendComponent append={states.append} on={handleAppend}/>
                ), [states.append])
            }
        </>
    )
}

export default withSurveySetReplys(withPrevState(withSurveyFetchQuestionsWithJumpMulti({mode, loading: false})(Create)))