import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import queryString from "query-string"
import { getSearchCookie } from 'ts-utils/summary'
import { SEARCH } from 'reducer/analysis/summary/targetUser'
import useSurveyTokenInfo from 'hooks/useSurveyTokenInfo'
import { SurveyCreateAnalysisSummaryReportContainer } from 'container'
import { LangComponent } from 'component'
import { getCookieName } from 'component/Survey/Create/NewAnalysis/Summary/Title'
import { withSurveyFetchAnalysisSurveyNo, withSurveyFetchData } from 'hoc'

const  Component = (props) => {
    const { propsFetchData }  = props
    const { mbox, surveyMboxAnalysisDesign } = propsFetchData
    const { survey_no, view_type } = surveyMboxAnalysisDesign

    const location = useLocation()
    const { search } = location

    const params = queryString.parse(search)

    const surveyToken = useSurveyTokenInfo(survey_no, params.m === 'email' ? 'email' : 'ip')

    const [ mount, setMount ] = useState(false)

    const dispath = useDispatch()

    useEffect(() => {
        const Do = () => {
            const survey_analysis_users_no = surveyToken ? surveyToken.survey_analysis_users_no : 1

            dispath({ type: SEARCH, survey_analysis_users_no })
        }

        // 뷰타입이 나의결과/전체결과 중 나의결과로 선택이 되어있을경우에 타겟유저번호와 같이 보낸다.

        if (view_type === 'all') {
            const name = getCookieName(survey_no)
            const value = getSearchCookie(name)

            if (Number(value) !== 1) Do()
        } else if (view_type === 'self') Do()

        setMount(true)
       
    }, [survey_no, view_type, surveyToken, dispath])

    return (
        <LangComponent lang={mbox.lang}>
            {mount && <SurveyCreateAnalysisSummaryReportContainer {...props}/>}
        </LangComponent>
    )
}

export default withSurveyFetchAnalysisSurveyNo()(withSurveyFetchData('live')({
    mbox: true,
    surveyMboxAnalysisDesign: true
})(false)(Component))