import { OverResponse } from 'gql/user_response'
import withUserFetchData from 'hoc/User/FetchData'
import OverComponent from 'component/Survey/Over/Component'

interface Props {
    tab: string;
    propsFetchData: {
        overResponse: OverResponse;
    }
    children: Element
}

function PermitContainer(props: Props) {
    const { propsFetchData, children } = props
    const { overResponse } = propsFetchData

    if (overResponse > 0) return <OverComponent/>

    return children
}

export default withUserFetchData({
    overResponse: true
})(false)(PermitContainer)