import { useState, useMemo, useEffect } from 'react'
import { SurveyEditContainer } from 'container'

export default (props) => {
    const [ state, setState ] = useState(props)

    useEffect(() => {
       setState(props)
    }, [props])

    return (
        <>
        {
            useMemo(() => (
                <SurveyEditContainer survey_question_no={state.edit.survey_question_no} {...state}/>
            ), [state])
        }
        </>
    )
}