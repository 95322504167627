import { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { RootState } from 'reducer'
import { HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { INIT as REFRESHINIT } from 'reducer/analysis/summary/refresh'
import { Mode, ViewMode } from 'gql/survey_analysis_summary'
import withSurveyRowsPaging from 'hoc/Survey/RowsPaging'
import LinerProgressComponent from '../LinerProgress'
import SurveyCreateAnalysisRowsComponent from 'component/Survey/Create/NewAnalysis/Rows/Component'
import ErrorUserMessage from '../Summary/ErrorUserMessage'

const Component = (props) => {
    const { survey_no, rowsData, input, questions, onChangeStartpoint, onChangeOffset } = props
    const { userCount, fileCount, rows, loading, refetch, isError } = rowsData
    const { startpoint, offset, order, step, date, search, conditionsPlay } = input

    const where = { step, date, search }

    const dispatch = useDispatch()
    
    const { formatMessage: f } = useIntl()

    const { refresh } = useSelector((state: RootState) => state.analysisSummaryRefresh)

    useEffect(() => {
        if (!loading) {
            dispatch({ type: REFRESHINIT })
            dispatch({ type: BACKDROPHIDE })
        }
    }, [loading, dispatch])

    useEffect(() => {
        if (refresh > 0) refetch()
    }, [refresh, refetch])


    useEffect(() => {
        return () => {
            dispatch({ type: REFRESHINIT })
        }
    }, [dispatch])

    if (isError) {
        return <ErrorUserMessage />
    }

    return (
        <>
        {
            loading && <LinerProgressComponent viewMode={ViewMode.View} message={f({id: 'hoc.Survey.Rows.loadingMsg'})} />
        }
        <SurveyCreateAnalysisRowsComponent 
            survey_no={survey_no}
            questions={questions}
            data={rows} 
            userCount={userCount}
            fileCount={fileCount}
            where={where}
            loading={loading}
            startpoint={startpoint}
            offset={offset} 
            order={order} 
            conditionsPlay={conditionsPlay}
            step={step}
            onChangeStartpoint={onChangeStartpoint} 
            onChangeOffset={onChangeOffset} 
            refetch={refetch}
        />
        </>
    )
}

export default withSurveyRowsPaging(Mode.Live)(true)((memo(Component)))