import { useState, useEffect, useRef, useCallback } from 'react'
import { SurveyCreatePublishComponent } from 'component'
import { useMutation } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { SHOW as ALERTSNACKBARSHOW } from 'reducer/alertSnackbar'
import { 
    UPDATE_SURVEY_MBOX_LANG,
    UPDATE_SURVEY_MBOX_PROGRESS,
    UPDATE_SURVEY_MBOX_PREV_BUTTON,
    UPDATE_SURVEY_MBOX_ALLOW_IPS,
    UPDATE_SURVEY_MBOX_END_DATE_USED,
    UPDATE_SURVEY_MBOX_END_DATE,
    UPDATE_SURVEY_MBOX_CUT_PEOPLE,
    UPDATE_SURVEY_MBOX_RESTART
} from 'gql/survey_mbox'
import { 
    UPDATE_SURVEY_MBOX_OPTION_JUST, 
    UPDATE_SURVEY_MBOX_OPTION_ENDBUTTON, 
    UPDATE_SURVEY_MBOX_OPTION_ALARM, 
    UPDATE_SURVEY_MBOX_OPTION_IP_BLOCKS, 
    UPDATE_SURVEY_MBOX_OPTION_FIRST_QUESTION 
} from 'gql/survey_mbox_option'
import moment from 'moment'
import { useIntl } from 'react-intl'
import useSurveyOpen from 'hooks/useSurveyOpen'

function parsingEndDate(date) {

    return date ? moment(Number(date)) : null
}

export default (props) => {
    const { mboxWithRelation, mboxMessage, refetchMboxWithRelation, onViewExplain } = props

    const params = useParams()
    const dispatch = useDispatch()

    const survey_no = Number(params.survey_no)

    const [ open, setOpen, {end_date, end_date_used} ] = useSurveyOpen()
    
    const [ state, setState ] = useState('')

    // auth_inputs ref
    const labelRef = useRef([])

    const { formatMessage: f } = useIntl()

    const [ updateSurveyMboxLang ] = useMutation(UPDATE_SURVEY_MBOX_LANG, {
        onCompleted: async () => {
        },
        onError: async() => {
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [ updateSurveyMboxProgress ] = useMutation(UPDATE_SURVEY_MBOX_PROGRESS, {
        onCompleted: async () => {
        },
        onError: async() => {
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        }
    })


    const [ updateSurveyMboxPrevButton ] = useMutation(UPDATE_SURVEY_MBOX_PREV_BUTTON, {
        onCompleted: async () => {
        },
        onError: async() => {
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [ updateSurveyMboxOptionJust ] = useMutation(UPDATE_SURVEY_MBOX_OPTION_JUST, {
        onCompleted: async () => {
        },
        onError: async() => {
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [ updateSurveyMboxOptionAlarm ] = useMutation(UPDATE_SURVEY_MBOX_OPTION_ALARM, {
        onCompleted: async () => {
        },
        onError: async() => {
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [ updateSurveyMboxOptionIpBlocks ] = useMutation(UPDATE_SURVEY_MBOX_OPTION_IP_BLOCKS, {
        onCompleted: async () => {
        },
        onError: async() => {
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [ updateSurveyMboxOptionEndButton ] = useMutation(UPDATE_SURVEY_MBOX_OPTION_ENDBUTTON, {
        onCompleted: () => {
          
            setState(prev => ({
                ...prev,
                end_button: false,
                end_button_text: '',
                end_button_link: ''
            }))
            dispatch({ type: BACKDROPHIDE })
        },
        onError: () => {
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [ updateSurveyMboxAllowIps ] = useMutation(UPDATE_SURVEY_MBOX_ALLOW_IPS, {
        onCompleted: async () => {
            refetchMboxWithRelation()
            dispatch({ type: BACKDROPHIDE })
        },
        onError: async() => {
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        }
    })
    
    const [ updateSurveyMboxEndDateUsed ] = useMutation(UPDATE_SURVEY_MBOX_END_DATE_USED, {
        onCompleted: async () => {
            refetchMboxWithRelation()
            dispatch({ type: BACKDROPHIDE })
        },
        onError: async() => {
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [ updateSurveyMboxEndDate ] = useMutation(UPDATE_SURVEY_MBOX_END_DATE, {
        onCompleted: async () => {
        },
        onError: async() => {
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [ updateSurveyMboxRestart ] = useMutation(UPDATE_SURVEY_MBOX_RESTART, {
        onCompleted: async () => {
            dispatch({ type: BACKDROPHIDE })
        },
        onError: async() => {
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [ updateSurveyMboxCutPeople ] = useMutation(UPDATE_SURVEY_MBOX_CUT_PEOPLE, {
        onCompleted: async (data) => {
            const { cut_people, cut_people_type } = data.updateSurveyMboxCutPeople

            await setState(prev => ({...prev, cut_people, cut_people_type  }))
            dispatch({ type: BACKDROPHIDE })
        },
        onError: async() => {
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [ updateSurveyMboxOptionFirstQuestion ] = useMutation(UPDATE_SURVEY_MBOX_OPTION_FIRST_QUESTION, {
        onCompleted: async () => {
            dispatch({ type: BACKDROPHIDE })
        },
        onError: async() => {
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const handle = {
        changeLang: async(e) => {
            const lang = e.target.value
            await setState(prev => ({...prev, lang}))
            await dispatch({ type: BACKDROPSHOW })
            await updateSurveyMboxLang({variables: { survey_no, lang }})
            dispatch({ type: BACKDROPHIDE })
        },
        changeProgress: async(progress) => {
            await dispatch({ type: BACKDROPSHOW })
            await updateSurveyMboxProgress({variables: { survey_no, progress }})
            await setState(prev => ({...prev, progress}))
            dispatch({ type: BACKDROPHIDE })

        },
        changePrevButton: async(e) => {
            const prev_button = e.target.checked
        
            await dispatch({ type: BACKDROPSHOW })
            await updateSurveyMboxPrevButton({variables: { survey_no, prev_button }})
            await setState(prev => ({...prev, prev_button}))
            dispatch({ type: BACKDROPHIDE })
        },
        changeJust: async(e) => {
            const just = e.target.checked
        
            await dispatch({ type: BACKDROPSHOW })
            await updateSurveyMboxOptionJust({variables: { survey_no, just }})
            await setState(prev => ({...prev, just}))
            dispatch({ type: BACKDROPHIDE })
        },
        changeAllowIps: async(e) => {
            const allow_ips = e.target.checked
        
            dispatch({ type: BACKDROPSHOW })
            updateSurveyMboxAllowIps({variables: { survey_no, allow_ips }})
        },
        changeRestart: async(e) => {
            const restart = e.target.checked
        
            dispatch({ type: BACKDROPSHOW })
            updateSurveyMboxRestart({variables: { survey_no, restart }})
            setState(prev => ({...prev, restart}))
        },
        changeEndDateUsed: async(e) => {
            const end_date_used = e.target.checked
        
            await dispatch({ type: BACKDROPSHOW })
            updateSurveyMboxEndDateUsed({variables: { survey_no, end_date_used }})
  
        },
        changeEndDate: async(end_date) => {
            setState(prevState => ({
                ...prevState,
                end_date
            }))
        },
        changeEndButton: async(e) => {
            const end_button = e.target.checked
            if (end_button) {
                setState(prev => ({...prev, end_button}))
                return
            }

            await dispatch({ type: BACKDROPSHOW })
            updateSurveyMboxOptionEndButton({variables: { survey_no, end_button: '', end_button_link: '' }})
        },
        saveEndDate: async() => {
            const { end_date } = state
            
            const _ori = JSON.stringify(moment(Number(mboxWithRelation.end_date)))
            const _new = JSON.stringify(end_date)

            setTimeout(async() => {
                if (_ori !== _new) {
                    await dispatch({ type: BACKDROPSHOW })
                    const result = await updateSurveyMboxEndDate({variables: { survey_no, end_date }})
                    await dispatch({ type: BACKDROPHIDE })

                    if (!result.data.updateSurveyMboxEndDate) {
                        dispatch({ 
                            type: ALERTSNACKBARSHOW, variant: 'error', 
                            message: f({id: 'container.Survey.Create.Publish.Container.endDate'}),
                            duration: 3500 
                        })
                        refetchMboxWithRelation()
                    }
                }
            }, 80)
        },
        changeOpen: useCallback(async(e) => {
            // 공개 상태값은 subscribe로 받는다. 상단 우측버튼에도 위치하기때문에 값이 변경되면 현재 페이지와 상단 버튼에도 바뀌어야한다
            await dispatch({ type: BACKDROPSHOW })
            await setOpen()
            dispatch({ type: BACKDROPHIDE })
        }, [setOpen, dispatch]),
        changeCutPeople: async(e) => {
            const cut_people_flag = e.target.checked
        
            await dispatch({ type: BACKDROPSHOW })
            await updateSurveyMboxCutPeople({variables: { survey_no, cut_people_flag }})
        },
        changeAlarm: async(e) => {
            const alarm = e.target.value
        
            await dispatch({ type: BACKDROPSHOW })
            await updateSurveyMboxOptionAlarm({variables: { survey_no, alarm }})
            await setState(prev => ({...prev, alarm}))
            dispatch({ type: BACKDROPHIDE })
        },
        changeIpBlocks: async(e) => {
            const ip_blocks = e.target.checked
        
            await dispatch({ type: BACKDROPSHOW })
            await updateSurveyMboxOptionIpBlocks({variables: { survey_no, ip_blocks }})
            await setState(prev => ({...prev, ip_blocks}))
            dispatch({ type: BACKDROPHIDE })
        },
        changeFirstQuestion: async(first_question) => {
            dispatch({ type: BACKDROPSHOW })
            updateSurveyMboxOptionFirstQuestion({variables: { survey_no, first_question }})
            setState(prev => ({...prev, first_question}))
        }
    }

    useEffect(() => {
        setState({ 
            lang: mboxWithRelation.lang,
            progress: mboxWithRelation.progress,
            prev_button: mboxWithRelation.prev_button,
            just: mboxWithRelation.survey_mbox_option.just,
            allow_ips: mboxWithRelation.allow_ips,
            end_date_used: mboxWithRelation.end_date_used,
            open: mboxWithRelation.open,
            restart: mboxWithRelation.restart,
            cut_people: mboxWithRelation.cut_people,
            cut_people_type: mboxWithRelation.cut_people_type,
            end_date: parsingEndDate(mboxWithRelation.end_date),
            end_button: Boolean(mboxWithRelation.survey_mbox_option.end_button || mboxWithRelation.survey_mbox_option.end_button_link),
            end_button_text: mboxWithRelation.survey_mbox_option.end_button,
            end_button_link: mboxWithRelation.survey_mbox_option.end_button_link,
            alarm: mboxWithRelation.survey_mbox_option.alarm,
            ip_blocks: mboxWithRelation.survey_mbox_option.ip_blocks,
            ip_blocks_list: mboxWithRelation.survey_mbox_option.ip_blocks_list,
            first_question: mboxWithRelation.survey_mbox_option.first_question
        })
    }, [mboxWithRelation])
    
    useEffect(() => {
        setState(prev => ({
            ...prev,
            open,
            end_date_used,
            end_date: parsingEndDate(end_date)
        }))
    }, [open, end_date, end_date_used])

    if (!state) return null

    return (
        <SurveyCreatePublishComponent 
            {...state} 
            survey_no={survey_no} 
            mboxMessage={mboxMessage} 
            labelRef={labelRef} 
            on={handle} 
            onViewExplain={onViewExplain}
            refetch={refetchMboxWithRelation}
        />
    )
}

