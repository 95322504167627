import { useState, useMemo, useCallback } from 'react'
import { SurveyCreateLeftComponent, SurveyCreateComponent, SurveyCreatePublishPreviewComponent } from 'component'
import { SurveyCreatePublishContainer } from 'container'
import { withSurveyFetchData } from 'hoc'

const initialState = {
    explainType: '' // lang  progress prev_button allow_ips end_date_used auth_inputs_used  각 기능별 설명을 보여준다
}

const Component = (props) => {
    const { category, onChangeCategory, propsFetchData } = props
    const { mboxWithRelation, mboxMessage, refetchMboxWithRelation } = propsFetchData

    const [ explainType, setExplainType ] = useState(initialState.explainType)

    const handleViewExplain = useCallback((val) => {
        setExplainType(val)
    }, [])

    const handleCloseExplain = useCallback(() => {
        setExplainType('')
    }, [])
    
    return (
        <>
        <div className='OuterPillar'>
            {
                useMemo(() => (
                    <SurveyCreateLeftComponent category={category} onChange={onChangeCategory}/>
                ), [category, onChangeCategory])
            }
        </div>
        <div className='OuterPillar'>
            <SurveyCreateComponent category={category}>
                <div className='Pillar'>
                    <SurveyCreatePublishContainer
                        mboxWithRelation={mboxWithRelation}
                        mboxMessage={mboxMessage}
                        refetchMboxWithRelation={refetchMboxWithRelation}
                        onViewExplain={handleViewExplain} 
                    />
                </div>
                <div className='Pillar'>
                    <SurveyCreatePublishPreviewComponent explainType={explainType} onClose={handleCloseExplain}/>
                </div>
            </SurveyCreateComponent> 
        </div>
        </>
    )
}

export default withSurveyFetchData('edit')({ mboxWithRelation: true, mboxMessage: true })(false)(Component)