import { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { OuterContainer } from 'container'
import SurveyCreateModuleRootContainer from 'container/Survey/Create/ModuleRoot'
import SurveyCreateThanksStartRootContainer from 'container/Survey/Create/ThanksStartRoot'
import SurveyCreateThanksEndingRootContainer from 'container/Survey/Create/ThanksEndingRoot'
import SurveyCreateBrandContainer from 'container/Survey/Create/Brand/Container'
import SurveyCreateDesignRootContainer from 'container/Survey/Create/DesignRoot'
import SurveyCreateLogicRootContainer from 'container/Survey/Create/LogicRoot'
import SurveyCreateMethodRootContainer from 'container/Survey/Create/MethodRoot'
import SurveyCreateSettingRootContainer from 'container/Survey/Create/SettingRoot'
import SurveyCreatePublishRootContainer from 'container/Survey/Create/PublishRoot'
import SurveyCreateShareContainer from 'container/Survey/Create/Share/Container'
import SurveyCreateNewAnalysisContainer from 'container/Survey/Create/NewAnalysis/Container'
import { 
    SurveyCreateOuterComponent, 
    SurveyCreateStepComponent, 
    SurveyCreateStepMobileComponent, 
    SurveyCreateUsersConfirmModalComponent,
} from 'component'
import ApplicationListsComponent from 'component/Application/Lists'

const Component = (props) => {
    const { containerState, step, activeStep, labels, backgroundcolor, onChangeCategory, onChangeStep } = props

    const { category } = containerState

    const history = useHistory()
    const params = useParams()

    const survey_no = Number(params.survey_no)

    // 페이지 이동
    const goPage = useCallback(survey_page_no => {
        history.push(`/survey/form/create/${survey_no}#page=${survey_page_no}`)
    }, [history, survey_no])

    return (
        <>
        <OuterContainer 
            toolbar={
                <SurveyCreateStepComponent 
                    survey_no={survey_no} 
                    labels={labels} 
                    activeStep={activeStep} 
                    onChangeStep={onChangeStep}
                />
            } 
            backgroundcolor={backgroundcolor} 
            color="inherit"
            toolbarLine={true}
        >
            {
                step === 'create' ? (
                    <>
                    <SurveyCreateStepMobileComponent labels={labels} activeStep={activeStep} onChangeStep={onChangeStep}/>   
                    <SurveyCreateOuterComponent>
                        {
                            category === 'module' ? (
                                <SurveyCreateModuleRootContainer category={category} goPage={goPage} onChangeCategory={onChangeCategory}/>
                            ) : category === 'thanks-start' ? (
                                <SurveyCreateThanksStartRootContainer category={category} onChangeCategory={onChangeCategory}/>
                            ) : category === 'thanks-end' ? (
                                <SurveyCreateThanksEndingRootContainer category={category} survey_ending_no={containerState.survey_ending_no} onChangeCategory={onChangeCategory}/>
                            ) : category === 'design' ? (
                                <SurveyCreateDesignRootContainer category={category} goPage={goPage} onChangeCategory={onChangeCategory}/>
                            ) : category === 'logic' ? (
                                <SurveyCreateLogicRootContainer category={category} goPage={goPage} onChangeCategory={onChangeCategory}/> 
                            ) : category === 'method' ? (
                                <SurveyCreateMethodRootContainer category={category} goPage={goPage} onChangeCategory={onChangeCategory}/> 
                            ) : category === 'setting' ? (
                                <SurveyCreateSettingRootContainer category={category} goPage={goPage} onChangeCategory={onChangeCategory}/> 
                            ) : category === 'publish' ? (
                                <SurveyCreatePublishRootContainer category={category} goPage={goPage} onChangeCategory={onChangeCategory}/>
                            ) : category === 'brand' && (
                                <SurveyCreateBrandContainer survey_no={survey_no} category={category} goPage={goPage} onChangeCategory={onChangeCategory}/>
                            )

                            
                        }
                    </SurveyCreateOuterComponent>
                    <SurveyCreateUsersConfirmModalComponent survey_no={survey_no}/>
                    </>
                ) : step === 'share' ? (
                    <>
                    <SurveyCreateStepMobileComponent labels={labels} activeStep={activeStep} onChangeStep={onChangeStep}/>   
                    <SurveyCreateShareContainer/>
                    </>
                ) : step === 'analysis' ? (
                    /**
                     * 밖에 탭으로 뺄때 사용하는데,, 정책이 바뀌어서 현재 미사용
                     */
                    <>
                    <SurveyCreateStepMobileComponent labels={labels} activeStep={activeStep} onChangeStep={onChangeStep}/>
                    <SurveyCreateNewAnalysisContainer/> 
                    </>
                ) : step === 'intergration' && (
                    <>
                    <SurveyCreateStepMobileComponent labels={labels} activeStep={activeStep} onChangeStep={onChangeStep}/>   
                    <ApplicationListsComponent/>
                    </>
                )
            }   
        </OuterContainer>
        </>
    )
}

export default Component