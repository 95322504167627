// 레포트 편집을 제외하고는...다른 페이지는 실제 데이터를 보여준다
import { memo } from 'react'
import { useIntl } from 'react-intl'
import { Mode, ViewMode } from 'gql/survey_analysis_summary'
import withSurveySummary from 'hoc/Survey/Summary'
import LinerProgressComponent from '../LinerProgress'
import { Props as ParentProps } from './EditData'
import ViewContainer from './View'
import ErrorUserMessage from './ErrorUserMessage'
import ErrorPannelMessage from './ErrorPannelMessage'

function EditDataComponent(props: ParentProps) {
    const { mbox, design, dialog, survey_no, input, summaryData } = props
    const { viewMode, target_survey_analysis_users_no } = input
    const { isError, loading } = summaryData

    const { formatMessage: f } = useIntl()

    // 나의 결과는 limit 에러가 나올수가 없다.
    if (!target_survey_analysis_users_no && isError) {
        if (ViewMode.View === viewMode) return <ErrorUserMessage />
        else return <ErrorPannelMessage/>
    }

    if (loading) return (<LinerProgressComponent viewMode={viewMode} message={f({id: 'hoc.Survey.Summary.loadingMsg'})} />)

    return (
        <ViewContainer
            mbox={mbox}
            design={design}
            dialog={dialog}
            survey_no={survey_no}
            input={input}
            summaryData={summaryData}
        />
    )
}

export default withSurveySummary(Mode.Live)(true)(memo(EditDataComponent))