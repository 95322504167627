import { useEffect, useState, useContext, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import { getImageAverageRGB } from 'utils'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { SHOW as ALERTSNACKBARSHOW } from 'reducer/alertSnackbar'
import { 
    SurveyCreateDesignCustomComponent,
    SurveyCreateDesignCustomTabsComponent,
    SurveyCreateDesignCustomTabComponent,
    SurveyCreateDesignCustomOptionComponent
} from 'component'
import { ColorPickerContainer } from 'container'
import { SHOW as POPOVERSHOW } from 'reducer/popover'
import EditDesignContext from 'context/editDesign'
import { useIntl } from 'react-intl'
import ImageComponent from './Image'
import { withSurveyDefaultDesign } from 'hoc'
import { UPDATE_SURVEY_MBOX_DESIGN } from 'gql/survey_mbox_design'
import { CREATE_SURVEY_DESIGNS, UPDATE_SURVEY_DESIGNS, GET_SURVEY_DESIGNS } from 'gql/survey_designs'

const initialState = {
    showImage: false
}

// 배경이미지 가져와서 색상 처리
const getImageBackgroundColor = (background_image) => {
    return new Promise(async(resolve, reject) => {
        try {
            let blob = await fetch(background_image).then(r => r.blob())
            let dataUrl = await new Promise(resolve => {
                let reader = new FileReader();
                reader.onload = () => resolve(reader.result)
                reader.readAsDataURL(blob)
            });
            
            // In order to use the data url, we need to create an img and wait for it to load
            let img = document.createElement('img');
            document.body.appendChild(img);
            img.onload = function(e) {
                const rgb = getImageAverageRGB(img)
    
                img.remove()

                resolve(rgb)
            }
            img.src = dataUrl
        } catch (e) {
            reject(e)
        }
    }) 
}

// setTimeout 객체
const Component = props => {
    const dispatch = useDispatch()
    const params = useParams()

    const survey_no = Number(params.survey_no)

    const { survey_designs_no, template, propsSurveyDefaultDesign, onClose } = props
    const { design: defaultDesign } = propsSurveyDefaultDesign


    const { onChange, originDesign } = useContext(EditDesignContext)
    
    const refTitle = useRef()

    const { formatMessage: f } = useIntl()

    const [ design, setDesign ] = useState(defaultDesign)
    const [ showImage, setShowImage ] = useState(initialState.showImage)

    const [ getDesigns, { data, refetch } ] = useLazyQuery(GET_SURVEY_DESIGNS, {
        onCompleted: (data) => {
            setDesign(data.surveyDesigns)
            dispatch({ type: BACKDROPHIDE })
        },
        onError: (e) => {
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        },
        fetchPolicy: 'network-only'
    })

    const [ createSurveyDesigns ] = useMutation(CREATE_SURVEY_DESIGNS, {
        onCompleted: async (data) => {
            
            // 저장하고 나면은 리스토베이 테마가 아니라 나의 테마이기때문에 template은 false 이다
            getDesigns({
                variables: {
                    survey_no,
                    survey_designs_no: data.createSurveyDesigns,
                    template: false
                }
            })
        },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [ updateSurveyDesigns ] = useMutation(UPDATE_SURVEY_DESIGNS, {
        onCompleted: async () => {
          
        },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })
    
    const [ updateSurveyMboxDesign ] = useMutation(UPDATE_SURVEY_MBOX_DESIGN, {
        onCompleted: async (data) => {
            
        },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const handleTabs = {
        close: () => {
            onChange(originDesign)
            onClose(template)
        },
        init: () => {
            setDesign(data.surveyDesigns)
            refTitle.current.value = data.surveyDesigns.title
        },
        save: async() => {

            if (!refTitle.current.value) {
                dispatch({ 
                    type: ALERTSNACKBARSHOW, 
                    variant: 'error', 
                    message: f({id: 'container.Survey.Create.Design.Custom.save.title'})
                
                })
                
                return
            }

            const input = {
                survey_no,
                numbering: design.numbering,
                question: design.question,
                answer: design.answer,
                answer_button: design.answer_button,
                answer_sbutton: design.answer_sbutton,
                background_color: design.background_color,
                line_color: design.line_color,
                background_image: design.background_image,
                background_size: design.background_size,
                background_filter: design.background_image ? design.background_filter : '',
                background_opacity: design.background_opacity,
                font_family: design.font_family
            }

            try {
                dispatch({ type: BACKDROPSHOW })

                // survey_design_no가 있어도 template이라면, createSurveyDesigns을 한다.
                await Promise.all([
                    ((design.survey_designs_no && !template)
                        ? updateSurveyDesigns({variables: { input: {...input, title: refTitle.current.value, survey_designs_no: design.survey_designs_no } }})
                        : createSurveyDesigns({variables: { input: {...input, title: refTitle.current.value} }})),
                    updateSurveyMboxDesign({variables: { input }})
                ])
                
                // update 일때만 refetch가 있다.
                if (design.survey_designs_no) refetch()

                dispatch({ type: BACKDROPHIDE })
                dispatch({ type: ALERTSNACKBARSHOW, variant: 'info', message: f({id: 'container.Survey.Create.Design.Custom.save.success'}), duration: 2000 })
            } catch(e) {
                dispatch({ type: BACKDROPHIDE })
            }

        }
    }

    const handleOption = {
        changeFontFamily: (value) => {
            const font_family = value
            setDesign(prevState => {
                return {
                    ...prevState,
                    font_family
                }
            })
        },
        openColorPicker: (e) => {
            const key = e.currentTarget.getAttribute('name')
            const color = design[key]

            dispatch({ 
                type: POPOVERSHOW, 
                component: <ColorPickerContainer color={color} onChange={handleOption.changeColorPicker(key)}/>,
                anchorEl: e.currentTarget,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                },
                isClose: false
            })
        },
        changeColorPicker: (key) => (color) => {
            setDesign(prevState => {
                const { r, g, b, a } = color.rgb
                return {
                    ...prevState,
                    [key]: `${r},${g},${b},${a}`
                }
            })
        },
        changeBackgroundSize: (e, background_size) => {
            setDesign(prevState => {
                return {
                    ...prevState,
                    background_size
                }
            })
        },
        changeBackgroundOpacity: (e, background_opacity) => {            
            setDesign(prevState => {
                return {
                    ...prevState,
                    background_opacity: (background_opacity / 100)
                }
            })
        },
        changeBackgroundFilter: async(background_filter) => {
            setDesign(prevState => {
                return {
                    ...prevState,
                    background_filter
                }
            })
        },
        openImage: () => setShowImage(true),
        removeImage: () => {
            // 설문과 테마템플릿에서 이미지 값 지운다
            setDesign(prevState => {
                return {
                    ...prevState,
                    background_image: defaultDesign.background_image,
                    background_size: defaultDesign.background_size,
                    background_filter: defaultDesign.background_filter,
                    background_opacity: defaultDesign.background_opacity
                }
            })
        }
    }

    const handleImage = {
        close: () => setShowImage(false),
        append: async (props) => {
            const { img_src: background_image, background_color  } = props

            let replacements = { background_image, background_opacity: .5, background_color: '255, 255, 255, 1' }

            if (background_color) {
                // 언스플래시로 할경우 칼라값이 잇다.
                replacements = { ...replacements, background_color: `${background_color.join(' ,')}, 1` }

                setDesign(prevState => {
                    return {
                        ...prevState,
                        ...replacements
                    }
                })
                return
            }

            try {
                const rgb = await getImageBackgroundColor(background_image)
                replacements = { ...replacements, background_color: `${rgb.r}, ${rgb.g}, ${rgb.b}, 1` }
                setDesign(prevState => {
                    return {
                        ...prevState,
                        ...replacements
                    }
                })
            } catch (e) {
                setDesign(prevState => {
                    return {
                        ...prevState,
                        ...replacements
                    }
                })
            }
        }
    }

    // 새로운 디자인 추가일때는 survey_designs_no 가없으므로 useLazyQuery로 처리
    useEffect(() => {
        if (survey_designs_no) {
            dispatch({ type: BACKDROPSHOW })
            getDesigns({
                variables: { 
                    survey_no,
                    survey_designs_no,
                    template
                }
            })
        }
    }, [survey_no, survey_designs_no, template, getDesigns, dispatch])

    useEffect(() => {
        const { question, answer, answer_button, answer_sbutton, background_color, line_color, background_image, background_size, background_opacity, background_filter, numbering, font_family } = design
        
        onChange({
            question,
            answer,
            answer_button,
            answer_sbutton,
            background_color,
            line_color,
            background_image,
            background_size,
            background_opacity,
            background_filter,
            numbering,
            font_family
        })
    }, [design, onChange])

    return (
        <>
        <SurveyCreateDesignCustomComponent show={!showImage}>
            <SurveyCreateDesignCustomTabsComponent on={handleTabs}/>
            <SurveyCreateDesignCustomTabComponent>
                {
                    // 수정일때는 데이터 다받아온다음에 랜더링해야 defaultValue 가 먹는다...
                    ((survey_designs_no && typeof design.__typename === 'string') || !survey_designs_no) && (
                        <SurveyCreateDesignCustomOptionComponent refTitle={refTitle} design={design} on={handleOption}/>
                    )
                }
            </SurveyCreateDesignCustomTabComponent>
        </SurveyCreateDesignCustomComponent>
        <SurveyCreateDesignCustomComponent show={showImage}>
            <ImageComponent onClose={handleImage.close} onAppendUrl={handleImage.append} onChange={handleImage.append}/>
        </SurveyCreateDesignCustomComponent>
        </>
    )
}


export default withSurveyDefaultDesign(Component)