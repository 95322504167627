import { useCallback, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { HIDE } from 'reducer/backdrop'
import ExplainComponent from 'component/Survey/Create/Logic/QuestionRandom/Explain'
import EditContainer from './Edit'
import PreviewContainer from './Preview'

function QuestionRandomContainer() {
    const dispatch = useDispatch()

    const [ page, setPage ] = useState(0)

    const handleChangePage = useCallback((myPage: number) => {
        // 현재 문항리스트가 열린상태이고 보이는 페이지랑 클릭한 페이지가 같다면 닫히게하자
        setPage(myPage)
    }, [])

    useEffect(() => {
        dispatch({ type: HIDE })
    }, [dispatch])


    return (
        <>
            <div className="Pillar">
                <EditContainer page={page} onChangePage={handleChangePage}/>
            </div>
            {
                page ? (
                    <div className="Pillar">
                        <PreviewContainer page={page}/>
                    </div>
                    
                ) :  (
                    <div className="Pillar">
                        <ExplainComponent/>
                    </div>
                )
            }
        </>
    )
}


export default QuestionRandomContainer