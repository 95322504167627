import { memo, useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'reducer'
import { INIT } from 'reducer/analysis/rows/order'
import { SurveyQuestionModuleProps } from 'gql/survey_question_modules'
import { SurveyAnalysisRowsPagingVariables } from 'gql/survey_analysis_rows'
import ViewContainer from './View'
import { OnChangeStartPoint } from '../Box'

interface Props {
    questions: SurveyQuestionModuleProps[];
    survey_no: SurveyAnalysisRowsPagingVariables['survey_no'];
    step: SurveyAnalysisRowsPagingVariables['input']['step']; 
    date: SurveyAnalysisRowsPagingVariables['input']['date'];
    startpoint: number;
    conditionsPlay: SurveyAnalysisRowsPagingVariables['input']['conditionsPlay'];
    onChangeStartPoint: OnChangeStartPoint;
}

type OderType = {
    key: 'id' | 'einterval' | 'accesstime' | 'ip';
    value: boolean;
}

export type OnChangeStartpoint = (value: number) => void;
export type OnChangeOffset = (value: number) => void;
export type OnChangeOrder = (props: OderType) => void;

function Container(props: Props) {
    const { questions, survey_no, step, date, startpoint, conditionsPlay, onChangeStartPoint } = props

    const offsetCookieName = `rows-offset-${survey_no}`

    const [ offset, setOffset ] = useState(sessionStorage.getItem(offsetCookieName) ? Number(sessionStorage.getItem(offsetCookieName)) : 50)

    const dispatch = useDispatch()

    const order = useSelector((states: RootState) => states.analysisRowsOrder)

    const handleChangeOffset: OnChangeOffset = useCallback(value => {
        setOffset(value)
        sessionStorage.setItem(offsetCookieName, String(value))
    }, [offsetCookieName])

    const input = {
        step,
        date,
        search: '', 
        conditionsPlay,
        startpoint,
        offset,
        order
    }

    // 처음마운트시 초기값 가져온다.
    useEffect(() => {
    
        dispatch({ type: INIT, survey_no })
    }, [survey_no, dispatch])

    if (conditionsPlay === null) return null
    
    return (
        <ViewContainer 
            questions={questions} 
            survey_no={survey_no} 
            input={input}
            onChangeStartpoint={onChangeStartPoint} 
            onChangeOffset={handleChangeOffset}
        />
    )  
}

export default memo(Container)