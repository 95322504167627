import { useEffect, createContext, useContext, ReactNode, memo } from 'react'
import { useDispatch } from 'react-redux'
import { INIT } from 'reducer/survey/create/logic/jumpPoint'
import { INIT as INIT_TEXT } from 'reducer/survey/create/logic/jumpPointText'
import { INIT as INIT_ERROR } from 'reducer/survey/create/logic/jumpPointError'
import { INIT as INIT_ALL_POINT } from 'reducer/survey/create/logic/jumpPointAllPoint'
import { INIT as INIT_INIT } from 'reducer/survey/create/logic/jumpPointInit'
import { v4 as uuidv4 } from 'uuid'
import { getKeyOfArray } from 'ts-utils'
import { withSurveyFetchData } from 'hoc'
import { JumpNo } from 'gql/jump'
import { JumpPointProps, JumpPointTextProps } from 'gql/jump_point'
import { SurveyQuestionModuleProps, ModuleType } from 'gql/survey_question_modules'
import { Context } from './Context'

// 컴포넌트 Props
interface Props {
    propsFetchData: {
        jumpPointWithRelation: {
            jumpPoint: JumpPointProps[];
            jumpPointText: JumpPointTextProps[];
        };
        refetchJumpPointWithRelation: () => void | undefined;
    },
    children: ReactNode
}

// survey_question_no를 기준으로... 변경
type JumpPointPropsInKey = { [a: number]: JumpPointProps[] }
type JumpPointTextPropsInKey = { [a: number]: JumpPointTextProps[] }

// DB에서 가져온 초기값 Context
interface InitContextProps {
    jumpPoint: JumpPointProps[];
    jumpPointText: JumpPointTextProps[];
}

// survey_question_no를 기준으로 Context
interface PointContextProps {
    jumpPointRowsKeyQuestionNo: JumpPointPropsInKey;
    jumpPointTextRowsKeyQuestionNo: JumpPointTextPropsInKey;
} 

// DB Refetch Context
interface PointEventContextProps {
    refetchJumpPointWithRelation: () => void | undefined;
}

// create pointText 
interface ParseJumpPointTextRow {
    survey_question_no: number;
}

// create point 
export interface ParseJumpPointRow {
    survey_question_no: number;
    module_type: ModuleType;
    jump_point_text_no?: JumpNo | null;
    survey_module_no?: number | null;
    survey_module_answer_no?: number | null;
    point?: number;
}

export const InitContext = createContext<InitContextProps>({
    jumpPoint: [],
    jumpPointText: []
})

export const PointContext = createContext<PointContextProps>({
    jumpPointRowsKeyQuestionNo: [],
    jumpPointTextRowsKeyQuestionNo: []
})

export const PointEventContext = createContext<PointEventContextProps>({
    refetchJumpPointWithRelation: () => {}
})

export function parseJumpPointTextRow({ survey_question_no }: ParseJumpPointTextRow):JumpPointTextProps {
    return {
        jump_point_text_no: uuidv4(),
        survey_question_no,
        value: ''
    }
}

export function parseJumpPointRow({ survey_question_no, module_type, jump_point_text_no=null, survey_module_no=null, survey_module_answer_no=null, point=0 }: ParseJumpPointRow):JumpPointProps {
    return {
        jump_point_no: uuidv4(),
        jump_point_text_no,
        survey_question_no,
        survey_module_no,
        survey_module_answer_no,
        module_type,
        point
    }
}

function createJumpPointRows(jumpPointKeyQuestionNo: JumpPointPropsInKey, questions: SurveyQuestionModuleProps[]) {
    if (!jumpPointKeyQuestionNo) return []

    const rows = questions.reduce((acc, question) => {

        const { _question, _modules, _answers } = question
        const { survey_question_no, module_type } = _question

        const jumpPoint = jumpPointKeyQuestionNo[survey_question_no] || []

        let _acc:JumpPointProps[] = []
        switch(module_type) {
            case '_01':
            case '_02':
            case '_05':
            case '_07':
            case '_12':
            case '_13':
            case '_14':
            case '_21':
                _acc = _modules.map((_module) => {
                    const { survey_module_no } = _module

                    const is = jumpPoint.find(c => c.survey_module_no === survey_module_no)

                    if (!is) return parseJumpPointRow({ survey_question_no, survey_module_no, module_type })
                    return is
                })
                break
            case '_03':
            case '_04':
            case '_06':
            case '_08':
            case '_15':
                _acc = _answers.map((answer) => {
                    const { survey_module_answer_no } = answer

                    const is = jumpPoint.find(c => c.survey_module_answer_no === survey_module_answer_no)

                    if (!is) return parseJumpPointRow({ survey_question_no, survey_module_answer_no, module_type })
                    return is
                })
                break
            case '_09':
            case '_10':
            case '_20':
                if (jumpPoint) _acc = jumpPoint
                break
            case '_11':
            case '_17':
            case '_18':
                for (let i=0; i<_modules.length; i++) {
                    const { survey_module_no } = _modules[i]

                    const row = _answers.map(answer => {
                        const { survey_module_answer_no } = answer

                        const is = jumpPoint.find(c => c.survey_module_no === survey_module_no && c.survey_module_answer_no === survey_module_answer_no)

                        if (!is) return parseJumpPointRow({ survey_question_no, survey_module_no, survey_module_answer_no, module_type })
                        return is
                    })
                    _acc = [ ..._acc, ...row]
                }
                break
            default:
        }

        return [...acc, ..._acc]
    
    }, [])

    return rows
}

function PointContextContanier(props: Props) {
    const { propsFetchData, children } = props
    const { jumpPointWithRelation, refetchJumpPointWithRelation } = propsFetchData
    const { jumpPoint, jumpPointText } = jumpPointWithRelation

    const { questions } = useContext(Context)

    const dispatch = useDispatch()

    const jumpPointKeyQuestionNo = getKeyOfArray('survey_question_no', jumpPoint)

    // 값이 없는 데이터는 임의로 빈값을 채운다. (주관식 제외...)
    const jumpPointRows = createJumpPointRows(jumpPointKeyQuestionNo, questions)

    const jumpPointRowsKeyQuestionNo = getKeyOfArray('survey_question_no', jumpPointRows)
    const jumpPointTextRowsKeyQuestionNo = getKeyOfArray('survey_question_no', jumpPointText)
    
    // 빈값을 채워서 발행한다
    useEffect(() => {
        dispatch({
            type: INIT,
            O_INIT: {
                rows: jumpPointRows
            }
        })
    }, [jumpPointRows, dispatch])

    // 오리지날 DB값을 발행한다
    useEffect(() => {
        dispatch({
            type: INIT_TEXT,
            O_INIT: {
                rows: jumpPointText
            }
        })
    }, [jumpPointText, dispatch])

    // 언마운트시 JumpNullTextNull 돌려놓는다
    useEffect(() => {
        return () => {
            dispatch({ type: INIT_ERROR })
            dispatch({ type: INIT_ALL_POINT })
            dispatch({ type: INIT_INIT })
        }
    }, [dispatch])

    return (
        <InitContext.Provider value={{
            jumpPoint: jumpPointRows,
            jumpPointText
        }}> 
            <PointContext.Provider value={{ 
                jumpPointRowsKeyQuestionNo: jumpPointRowsKeyQuestionNo || [], 
                jumpPointTextRowsKeyQuestionNo: jumpPointTextRowsKeyQuestionNo || [] 
            }}>
                <PointEventContext.Provider value={{ refetchJumpPointWithRelation }}>
                    {children}
                </PointEventContext.Provider>
            </PointContext.Provider>
        </InitContext.Provider>
    )
}

export default withSurveyFetchData('preview')({
    jumpPointWithRelation: true
})(false)(memo(PointContextContanier))

