import EditContainer from './Edit'
import ExplainComponent from 'component/Survey/Create/Logic/PageRandom/Explain'

function PageRandomContainer() {
    return (
        <>
        <div className="EditContainer">
            <EditContainer/>
        </div>
        <div className="Pillar">
            <ExplainComponent/>
        </div>
        </>
    )
}

export default PageRandomContainer