import { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { withSurveyFetchData } from 'hoc'
import { Grid } from '@material-ui/core'
import { 
    UPDATE_SURVEY_MBOX_ANALYSIS_DESIGN_COLOR,
    UPDATE_SURVEY_MBOX_ANALYSIS_DESIGN_IMAGE,
    UPDATE_SURVEY_MBOX_ANALYSIS_DESIGN_COVER_OPACITY,
    UPDATE_SURVEY_MBOX_ANALYSIS_DESIGN_OPEN,
    UPDATE_SURVEY_MBOX_ANALYSIS_DESIGN_RESPONSE,
    UPDATE_SURVEY_MBOX_ANALYSIS_DESIGN_VIEWTYPE
} from 'gql/survey_mbox_analysis_design'
import { Step, ViewMode } from 'gql/survey_analysis_summary'
import { LangComponent } from 'component'
import SurveyCreateAnalysisSummaryEditComponent from 'component/Survey/Create/NewAnalysis/Summary/Edit'
import SurveyCreateAnalysisSummaryEditDesignComponent from 'component/Survey/Create/NewAnalysis/Summary/EditDesign'
import SurveyCreateAnalysisSummaryEditDataBoxComponent from 'component/Survey/Create/NewAnalysis/Summary/EditDataBox'
import SurveyCreateAnalysisSummaryEditOpenComponent from 'component/Survey/Create/NewAnalysis/Summary/EditOpen'
import SurveyCreateAnalysisSummaryEditCloseComponent from 'component/Survey/Create/NewAnalysis/Summary/EditClose'
import SurveyCreateAnalysisSummaryTitleComponent from 'component/Survey/Create/NewAnalysis/Summary/Title'
import Container from './Container'

// 임의로 배열값준다 ( 디자인 edit 에서는 쓰이지 않는다)
const conditionsPlay = []

// 오퍼시티 저장경우 onchange 100 잇다가 한다. 실시간으로 하면 서버에 부담된다
let timeout = null

const Component = (props) => {

    const { onClose, propsFetchData } = props
    const { 
        mbox, 
        surveyMboxAnalysisDesign, 
        refetchMboxAnalysisDesign 
    } = propsFetchData

    const params = useParams()
    const survey_no = Number(params.survey_no) 

    const dispatch = useDispatch()

    const [ design, setDesign ] = useState(surveyMboxAnalysisDesign)

    const [ backgroundCoverOpacity, setBackgroundCoverOpacity ] = useState(surveyMboxAnalysisDesign.background_cover_opacity)

    const [ updateSurveyMboxAnalysisDesignColor ] = useMutation(UPDATE_SURVEY_MBOX_ANALYSIS_DESIGN_COLOR, {
        onCompleted: () => {
            refetchMboxAnalysisDesign()
            dispatch({ type: BACKDROPHIDE })
        },
        onError: (e) => {
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [ updateSurveyMboxAnalysisDesignImage ] = useMutation(UPDATE_SURVEY_MBOX_ANALYSIS_DESIGN_IMAGE, {
        onCompleted: () => {
            dispatch({ type: BACKDROPHIDE })
        },
        onError: (e) => {
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        }
    })


    const [ updateSurveyMboxAnalysisDesignCoverOpacity ] = useMutation(UPDATE_SURVEY_MBOX_ANALYSIS_DESIGN_COVER_OPACITY, {
        onCompleted: () => {
            dispatch({ type: BACKDROPHIDE })
        },
        onError: (e) => {
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [ updateSurveyMboxAnalysisDesignOpen ] = useMutation(UPDATE_SURVEY_MBOX_ANALYSIS_DESIGN_OPEN, {
        onCompleted: () => {
            refetchMboxAnalysisDesign()
            dispatch({ type: BACKDROPHIDE })
        },
        onError: (e) => {
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [ updateSurveyMboxAnalysisDesignResponse ] = useMutation(UPDATE_SURVEY_MBOX_ANALYSIS_DESIGN_RESPONSE, {
        onCompleted: () => {
            refetchMboxAnalysisDesign()
            dispatch({ type: BACKDROPHIDE })
        },
        onError: (e) => {
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [ updateSurveyMboxAnalysisDesignViewType ] = useMutation(UPDATE_SURVEY_MBOX_ANALYSIS_DESIGN_VIEWTYPE, {
        onCompleted: () => {
            refetchMboxAnalysisDesign()
            dispatch({ type: BACKDROPHIDE })
        },
        onError: (e) => {
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const handle = {
        changeFontFamily: useCallback((value) => {
            const font_family = value

            setDesign(prevState => {
                return {
                    ...prevState,
                    font_family
                }
            })
        }, []),
        changeColorPicker: useCallback((key) => (color) => {
            setDesign(prevState => {
                const { r, g, b, a } = color.rgb

                return {
                    ...prevState,
                    [key]: `${r},${g},${b},${a}`
                }
            })
        }, []),
        revertColorPicker: () => {
            setDesign(prevState => ({
                ...prevState,
                question: surveyMboxAnalysisDesign.question,
                background_color: surveyMboxAnalysisDesign.background_color,
                bar: surveyMboxAnalysisDesign.bar
            }))
        },
        saveColorPicker: () => {
            const { font_family, question, background_color, bar } = design
            const input = { survey_no, font_family, question, background_color, bar }

            dispatch({ type: BACKDROPSHOW })

            updateSurveyMboxAnalysisDesignColor({ variables: { input } })
                            
        },
        changeImage: async(props) => {
            const { type, src } = props

            const input = {
                survey_no,
                type,
                src
            }

            dispatch({ type: BACKDROPSHOW })

            await updateSurveyMboxAnalysisDesignImage({ variables: { input } })
                
            setDesign(prevState => ({
                ...prevState,
                [type]: src
            }))
        },
        changeCoverOpacity: async(e, _background_cover_opacity) => {

            clearTimeout(timeout)

            const background_cover_opacity = _background_cover_opacity / 100
            
            await setBackgroundCoverOpacity(background_cover_opacity)

            timeout = setTimeout(() => {
                updateSurveyMboxAnalysisDesignCoverOpacity({ variables: { survey_no, background_cover_opacity } })
            }, 100)
        },
        saveOpen: async() => {
            dispatch({ type: BACKDROPSHOW })

            const open = !design.open

            await updateSurveyMboxAnalysisDesignOpen({ variables: { survey_no, open } })
                
            setDesign(prevState => ({ ...prevState, open }))
            
        },
        saveResponse: async() => {
            dispatch({ type: BACKDROPSHOW })

            const response = !design.response

            await updateSurveyMboxAnalysisDesignResponse({ variables: { survey_no, response } })
                
            setDesign(prevState => ({ ...prevState, response }))
            
        },
        saveViewType: async(e) => {
            dispatch({ type: BACKDROPSHOW })

            const view_type = e.target.value

            await updateSurveyMboxAnalysisDesignViewType({ variables: { survey_no, view_type } })
                
            setDesign(prevState => ({ ...prevState, view_type }))
        }
    }

    return (
        <>
        <SurveyCreateAnalysisSummaryEditComponent>
            <Grid item xs={1} md={3}>
               <SurveyCreateAnalysisSummaryEditCloseComponent onClose={onClose}/>
            </Grid>
            <Grid item xs={11} md={9}>
                <SurveyCreateAnalysisSummaryEditOpenComponent survey_no={survey_no} args={design.args}/>
            </Grid>
            <Grid item xs={12} md={3}>
                <SurveyCreateAnalysisSummaryEditDesignComponent
                    design={design} 
                    originDesign={surveyMboxAnalysisDesign} 
                    backgroundCoverOpacity={backgroundCoverOpacity} 
                    on={{
                        changeFontFamily: handle.changeFontFamily,
                        openColorPicker: handle.openColorPicker,
                        changeColorPicker: handle.changeColorPicker,
                        revertColorPicker: handle.revertColorPicker,
                        saveColorPicker: handle.saveColorPicker,
                        changeImage: handle.changeImage,
                        changeCoverOpacity: handle.changeCoverOpacity,
                        saveOpen: handle.saveOpen,
                        saveResponse: handle.saveResponse,
                        saveViewType: handle.saveViewType
                    }}
               />
            </Grid>
            <Grid item xs={12} md={9}>
                <SurveyCreateAnalysisSummaryEditDataBoxComponent design={design} backgroundCoverOpacity={backgroundCoverOpacity}>
                    <LangComponent lang={mbox.lang}>
                        <SurveyCreateAnalysisSummaryTitleComponent 
                            survey_no={survey_no}
                            design={design} 
                            title={mbox.title}
                            edit={true}
                        />
                        <Container 
                            mbox={mbox}
                            dialog={false}
                            conditionsPlay={conditionsPlay}
                            design={design}
                            survey_no={survey_no}
                            step={Step.End}
                            date=""
                            search=""
                            viewMode={ViewMode.Edit}
                            print={false}
                        />
                    </LangComponent>
                </SurveyCreateAnalysisSummaryEditDataBoxComponent>
            </Grid>
        </SurveyCreateAnalysisSummaryEditComponent>
        </>
    )
}

export default withSurveyFetchData('edit')({
    mbox: true,
    surveyMboxAnalysisDesign: true,
    _fetchPolicy: {
        mbox: 'cache-first'
    }
})(true)(Component)
