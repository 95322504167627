import { useCallback } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import PropsContext from 'context/props'
import InsightContainer from './Insight'
import BoxContainer from './Box'
import ApplicationListsComponent from 'component/Application/Lists'

function NewContainer() {
    const history = useHistory()
    const location = useLocation()
    const { hash, pathname } = location
    const hashParams = queryString.parse(hash)

    const tab = hashParams.tab || 'insight'

    const handleChange = useCallback((tab) => {
        history.push(`${pathname}#tab=${tab}`)
    }, [history, pathname])

    return (
        <PropsContext.Provider value={{
            tab, 
            onChange: handleChange
        }}>
             {
                tab === 'insight' ? <InsightContainer/> : 
                tab === 'integration' ? <ApplicationListsComponent/> : <BoxContainer tab={tab}/>
            }
        </PropsContext.Provider>
        
    )
}

export default NewContainer