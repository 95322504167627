import { useEffect, createContext, useContext, ReactNode, memo } from 'react'
import { useDispatch } from 'react-redux'
import { INIT } from 'reducer/survey/create/logic/jumpExampleCount'
import { INIT as INIT_TEXT } from 'reducer/survey/create/logic/jumpExampleCountText'
import { INIT as INIT_ERROR } from 'reducer/survey/create/logic/jumpPointError'
import { INIT as INIT_INIT } from 'reducer/survey/create/logic/jumpExampleCountInit'
import { v4 as uuidv4 } from 'uuid'
import { getKeyOfArray } from 'ts-utils'
import { withSurveyFetchData } from 'hoc'
import { JumpNo } from 'gql/jump'
import { JumpExampleCountProps, JumpExampleCountTextProps } from 'gql/jump_example_count'
import { SurveyQuestionModuleProps, ModuleType } from 'gql/survey_question_modules'
import { SurveyEndingProps } from 'gql/survey_ending'
import { Context } from './Context'

// 컴포넌트 Props
interface Props {
    propsFetchData: {
        endings: SurveyEndingProps[];
        jumpExampleCountWithRelation: {
            jumpExampleCount: JumpExampleCountProps[];
            jumpExampleCountText: JumpExampleCountTextProps[];
        };
        refetchJumpExampleCountWithRelation: () => void | undefined;
    },
    children: ReactNode
}

// survey_question_no를 기준으로... 변경
type JumpExampleCountPropsInKey = { [a: number]: JumpExampleCountProps[] }
type JumpExampleCountTextPropsInKey = { [a: number]: JumpExampleCountTextProps[] }

// DB에서 가져온 초기값 Context
interface InitContextProps {
    jumpExampleCount: JumpExampleCountProps[];
    jumpExampleCountText: JumpExampleCountTextProps[];
}

// survey_question_no를 기준으로 Context
interface ExampleCountContextProps {
    jumpExampleCountRowsKeyQuestionNo: JumpExampleCountPropsInKey;
    jumpExampleCountTextRowsKeyQuestionNo: JumpExampleCountTextPropsInKey;
} 

// DB Refetch Context
interface ExampleCountEventContextProps {
    refetchJumpExampleCountWithRelation: () => void | undefined;
}

// create pointText 
interface ParseJumpExampleCountTextRow {
    survey_question_no: number;
}

interface EndingContextProps {
    endings: SurveyEndingProps[];
    endingIndexs: {[a: number ]: number}
} 

// create point 
export interface ParseJumpExampleCountRow {
    survey_question_no: number;
    module_type: ModuleType;
    jump_example_count_text_no?: JumpNo | null;
    survey_module_no?: number | null;
    survey_module_answer_no?: number | null;
    survey_ending_nos: number[];
}

export const InitContext = createContext<InitContextProps>({
    jumpExampleCount: [],
    jumpExampleCountText: []
})

export const ExampleCountContext = createContext<ExampleCountContextProps>({
    jumpExampleCountRowsKeyQuestionNo: [],
    jumpExampleCountTextRowsKeyQuestionNo: []
})

export const ExampleCountEventContext = createContext<ExampleCountEventContextProps>({
    refetchJumpExampleCountWithRelation: () => {}
})

export const EndingContext = createContext<EndingContextProps>({
    endings: [],
    endingIndexs: {}
})

export function parseJumpExampleCountTextRow({ survey_question_no }: ParseJumpExampleCountTextRow):JumpExampleCountTextProps {
    return {
        jump_example_count_text_no: uuidv4(),
        survey_question_no,
        value: ''
    }
}

export function parseJumpExampleCountRow({ survey_question_no, module_type, jump_example_count_text_no=null, survey_module_no=null, survey_module_answer_no=null, survey_ending_nos }: ParseJumpExampleCountRow):JumpExampleCountProps {
    return {
        jump_example_count_no: uuidv4(),
        jump_example_count_text_no,
        survey_question_no,
        survey_module_no,
        survey_module_answer_no,
        module_type,
        survey_ending_nos
    }
}

function createJumpExampleCountRows(jumpExampleCountKeyQuestionNo: JumpExampleCountPropsInKey, questions: SurveyQuestionModuleProps[]) {

    if (!jumpExampleCountKeyQuestionNo) return []

    const rows = questions.reduce((acc, question) => {

        const { _question, _modules, _answers } = question
        const { survey_question_no, module_type } = _question

        const jumpExampleCount = jumpExampleCountKeyQuestionNo[survey_question_no] || []

        let _acc:JumpExampleCountProps[] = []
        switch(module_type) {
            case '_01':
            case '_02':
            case '_05':
            case '_07':
            case '_12':
            case '_13':
            case '_14':
            case '_21':
                _acc = _modules.map((_module) => {
                    const { survey_module_no } = _module

                    const is = jumpExampleCount.find(c => c.survey_module_no === survey_module_no)

                    if (!is) return parseJumpExampleCountRow({ survey_question_no, survey_module_no, module_type, survey_ending_nos: [] })
                    return is
                })
                break
            case '_03':
            case '_04':
            case '_06':
            case '_08':
            case '_15':
                _acc = _answers.map((answer) => {
                    const { survey_module_answer_no } = answer

                    const is = jumpExampleCount.find(c => c.survey_module_answer_no === survey_module_answer_no)

                    if (!is) return parseJumpExampleCountRow({ survey_question_no, survey_module_answer_no, module_type, survey_ending_nos: [] })
                    return is
                })
                break
            case '_09':
            case '_10':
            case '_20':
                if (jumpExampleCount) _acc = jumpExampleCount
                break
            case '_11':
            case '_17':
            case '_18':
                for (let i=0; i<_modules.length; i++) {
                    const { survey_module_no } = _modules[i]

                    const row = _answers.map(answer => {
                        const { survey_module_answer_no } = answer

                        const is = jumpExampleCount.find(c => c.survey_module_no === survey_module_no && c.survey_module_answer_no === survey_module_answer_no)

                        if (!is) return parseJumpExampleCountRow({ survey_question_no, survey_module_no, survey_module_answer_no, module_type, survey_ending_nos: [] })
                        return is
                    })
                    _acc = [ ..._acc, ...row]
                }
                break
            default:
        }

        return [...acc, ..._acc]
    
    }, [])

    return rows
}

function ExampleCountContextContanier(props: Props) {
    const { propsFetchData, children } = props
    const { endings, jumpExampleCountWithRelation, refetchJumpExampleCountWithRelation } = propsFetchData
    const { jumpExampleCount, jumpExampleCountText } = jumpExampleCountWithRelation

    const { questions } = useContext(Context)

    const dispatch = useDispatch()

    const jumpExampleCountKeyQuestionNo = getKeyOfArray('survey_question_no', jumpExampleCount)

    // 값이 없는 데이터는 임의로 빈값을 채운다. (주관식 제외...)
    const jumpExampleCountRows = createJumpExampleCountRows(jumpExampleCountKeyQuestionNo, questions)

    const jumpExampleCountRowsKeyQuestionNo = getKeyOfArray('survey_question_no', jumpExampleCountRows)
    const jumpExampleCountTextRowsKeyQuestionNo = getKeyOfArray('survey_question_no', jumpExampleCountText)

    const endingIndexs = endings.reduce((acc, c, i) => {
        return { ...acc, [c.survey_ending_no]: (i + 1)}
    }, [])
    
    // 빈값을 채워서 발행한다
    useEffect(() => {
        dispatch({
            type: INIT,
            O_INIT: {
                rows: jumpExampleCountRows
            }
        })
    }, [jumpExampleCountRows, dispatch])

    // 오리지날 DB값을 발행한다
    useEffect(() => {
        dispatch({
            type: INIT_TEXT,
            O_INIT: {
                rows: jumpExampleCountText
            }
        })
    }, [jumpExampleCountText, dispatch])

    // 언마운트시 JumpNullTextNull 돌려놓는다
    useEffect(() => {
        return () => {
            dispatch({ type: INIT_ERROR })
            dispatch({ type: INIT_INIT })
        }
    }, [dispatch])

    return (
        <InitContext.Provider value={{
            jumpExampleCount: jumpExampleCountRows,
            jumpExampleCountText
        }}> 
            <ExampleCountContext.Provider value={{ 
                jumpExampleCountRowsKeyQuestionNo: jumpExampleCountRowsKeyQuestionNo || [], 
                jumpExampleCountTextRowsKeyQuestionNo: jumpExampleCountTextRowsKeyQuestionNo || [] 
            }}>
                <ExampleCountEventContext.Provider value={{ refetchJumpExampleCountWithRelation }}>
                    <EndingContext.Provider value={{ endings, endingIndexs }}>
                        {children}
                    </EndingContext.Provider>
                </ExampleCountEventContext.Provider>
            </ExampleCountContext.Provider>
        </InitContext.Provider>
    )
}

export default withSurveyFetchData('preview')({
    jumpExampleCountWithRelation: true,
    endings: true
})(false)(memo(ExampleCountContextContanier))

