import  { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/react-hooks'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { SHOW as ALERTSNACKBARSHOW } from 'reducer/alertSnackbar'
import { HIDE as SURVEYCREATEMENUHIDE } from 'reducer/survey/create/menu'
import { useIntl } from 'react-intl'
import { animateScroll as scroll } from 'react-scroll'
import { SurveyCreatePagesListsComponent, SurveyCreatePagesMoveComponent } from 'component'
import { UPDATE_SURVEY_PAGE_SORT } from 'gql/survey_page'
import useLimitQuestionMessage from 'hooks/useLimitQuestionMessage'

const initialState = {
    movePage: {
        target_survey_page_no: '', // 타켓 서베이페이지 번호
        position: ''
    }
}

export default (props) => {
    const { 
        survey_no, 
        page, 
        pages,
        pageMenu, 
        goPage, 
        onOpenPageLists,
        onOpenPageMove,
        onClosePage,
        refetchPages, 
        refetchQuestions 
    } = props

    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    const callLimitQuestionMessage = useLimitQuestionMessage()

    const [ movePage, setMovePage ] = useState(initialState.movePage)

    const [ updateSurveyPageSort ] = useMutation(UPDATE_SURVEY_PAGE_SORT, {
        onCompleted: async (data) => {
            await refetchPages()
            refetchQuestions()
            onClosePage()

            goPage(data.updateSurveyPageSort)
        },
        onError: (e) => {
            dispatch({ type: BACKDROPHIDE })
            callLimitQuestionMessage(e)
        }
    })

    const handleChange = useCallback(async(survey_page_no) => {
         // 페이지 닫기
         onClosePage()
         
        if (survey_page_no === page) return
        
        dispatch({ type: BACKDROPSHOW })
       
        // 메뉴 닫기 (모바일일경우 햄버거 메뉴닫기)
        dispatch({ type: SURVEYCREATEMENUHIDE })

        await setTimeout(async() => {
           
            await goPage(survey_page_no)
        }, 1)

        setTimeout(() => {
            scroll.scrollToTop({
                scrollTo: {
                    duration: 800,
                    smooth: 'easeInOutQuart'
                }
            })
        }, 1000)
    }, [dispatch, page, goPage, onClosePage])

    const handleChangeMovePage = useCallback((e) => {
        const target_survey_page_no = e.target.value
        setMovePage(prevState => ({
            ...prevState,
            target_survey_page_no
        }))
    }, [])

    const handleChangeMovePosition = useCallback((position) => {
        setMovePage(prevState => ({
            ...prevState,
            position
        }))
    }, [])


    const handleSaveMovePage = useCallback(async(method) => {
        const { target_survey_page_no, position } = movePage

        if (!target_survey_page_no) {
            dispatch({ 
                type: ALERTSNACKBARSHOW, 
                variant: 'error', 
                message: f({id: 'container.Survey.Create.Pages.Container.page'})
            
            })
            
            return
        }

        if (position === '') {
            dispatch({ 
                type: ALERTSNACKBARSHOW, 
                variant: 'error', 
                message: f({id: 'container.Survey.Create.Pages.Container.position'})
            
            })
            
            return
        }

        dispatch({ type: BACKDROPSHOW })

        setTimeout(() => {
            // 메뉴 닫기 (모바일일경우 햄버거 메뉴닫기)
            dispatch({ type: SURVEYCREATEMENUHIDE })

            updateSurveyPageSort({variables: { input: {
                survey_no,
                target_survey_page_no,
                source_survey_page_no: page,
                position,
                method
            } }})
        }, 100)
    }, [f, page, survey_no, movePage, dispatch, updateSurveyPageSort])

    return (
        <>
        {
            pageMenu === 'lists' ? (
                <SurveyCreatePagesListsComponent 
                    {...props} 
                    onChange={handleChange}
                    onOpenPageLists={onOpenPageLists}
                /> 
            ) : (
                <SurveyCreatePagesMoveComponent 
                    currentPage={page}
                    pages={pages}
                    position={movePage.position}
                    onChangeMovePage={handleChangeMovePage}
                    onChangeMovePosition={handleChangeMovePosition}
                    onSaveMovePage={handleSaveMovePage}
                    onClosePage={onClosePage}
                    onOpenPageMove={onOpenPageMove}
                    defaults={{
                        survey_page_no: movePage.target_survey_page_no
                    }}
                />
            )
        }
        </>
        
    )
}