import { useIntl } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import { parseHtmlIntl } from 'ts-utils'
import WarningTwoToneIcon from '@material-ui/icons/WarningTwoTone'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import withBreakPoints, { BreakPointsProps } from 'hoc/BreakPoints'

interface Props {
    breakpoints: BreakPointsProps;
    onChangeCategory: (category: string) => void
}

const useStyles = makeStyles(theme => ({
    content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        flexDirection: 'column',
        paddingTop: 40,
        paddingBottom: 40
    },
    icon: {
        width: theme.spacing(10),
        height: theme.spacing(10)
    },
    typography: {
        marginTop: 10
    },
    button: {
        marginTop: 10
    }
}))

function DetectiveContainer(props: Props) {
    const { breakpoints, onChangeCategory } = props
    const { xs } = breakpoints

    const { formatMessage: f } = useIntl()

    const classes = useStyles()

    return (
        <Dialog open={true} fullScreen>
            <DialogContent className={classes.content}>
                <WarningTwoToneIcon color="secondary" className={classes.icon}/>
                <Typography variant={xs ? 'body1' : 'h6'} className={classes.typography}>
                    {parseHtmlIntl(f({id: 'container.Survey.Create.Logic.Jump.Detect.content'}))}
                </Typography>
                <Button variant="contained" color="primary" className={classes.button} disableElevation  onClick={() => onChangeCategory('module')}>
                    {f({id: 'container.Survey.Create.Logic.Jump.Detect.button.close'})}
                </Button>
            </DialogContent>
        </Dialog>
    )
}

export default withBreakPoints(DetectiveContainer)