import { useEffect, useMemo, useReducer, useState, memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'reducer'
import { CHANGE as CHANGEROWS } from 'reducer/analysis/summary/rows'
import { withBreakPoints } from 'hoc'
import { HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { INIT as REFRESHINIT } from 'reducer/analysis/summary/refresh'
import SurveyCreateAnalysisSummaryDataItemsComponent from 'component/Survey/Create/NewAnalysis/Summary/DataItems'
import SurveyCreateAnalysisSummaryDataComponent from 'component/Survey/Create/NewAnalysis/Summary/Data'

const initialState = {
    design: {},
    dataDownload: false, // 데이터 전체 다운로드 다이아로그 온오프
    items: [] // 통계자료
}

const handleReducer = (state, action) => {
    switch (action.type) {
        case 'design': { // 디자인
            return { ...state, design: action.design }
        }
        case 'items': { // 통계데이터
            return { ...state, items: action.items }
        }
        case 'dataDownload': { // 데이터 전체 다운로드 다이아로그 온오프
            return { ...state, dataDownload: action.dataDownload }
        }
        default: {
            throw new Error(`unexpected action.type: ${action.type}`)
        }
    }
}

// viewMode = "edit view" (edit - 레포트 편집, view - 관리자페이지, report - 공개페이지)
// dialog: 관리자페이지에서 우측버튼 활성화
// print: print Page
const Component = (props) => {
    const { summaryData, mbox, dialog, design, breakpoints, input } = props
    const { rows, response, loading, refetch } = summaryData
    const { print, viewMode, conditionsPlay } = input

    const { xs, sm } = breakpoints

    const dispatch = useDispatch()

    const [ states, dispatchStates ] = useReducer(handleReducer, { ...initialState, design })

    const [ top, setTop ] = useState(false)

    const { refresh } = useSelector((state: RootState) => state.analysisSummaryRefresh)

    const handleTop = useCallback(() => {
        setTop(prev => !prev)
    }, [])
    
    useEffect(() => {
        if (!xs && !sm) setTop(prev => !prev)
    }, [conditionsPlay, xs, sm])

    useEffect(() => {
        if (typeof design === 'object') dispatchStates({type: 'design', design})
    }, [design])

    useEffect(() => {
        if (!loading) {
            dispatch({ type: REFRESHINIT })
            dispatch({ type: BACKDROPHIDE })
        }
    }, [loading, dispatch])


    useEffect(() => {
        if (refresh > 0) refetch()
    }, [refresh, refetch])

    // 다운로드 버튼에 데이터 보내준다
    useEffect(() => {
        // 관리자 페이지에서만 필요한것이라..
        if (viewMode === 'view' || viewMode === 'report') dispatch({ type: CHANGEROWS, rows, response })
    }, [rows, response, viewMode, dispatch])

    useEffect(() => {
        return () => {
            dispatch({ type: REFRESHINIT })
        }
    }, [dispatch])
    
    return (
        <SurveyCreateAnalysisSummaryDataComponent print={print} top={top} viewMode={viewMode} dialog={dialog} onTop={handleTop}>
            {
                useMemo(() => (
                    <SurveyCreateAnalysisSummaryDataItemsComponent
                        mbox={mbox}
                        items={rows}
                        design={states.design}
                        viewMode={viewMode}
                        print={print}
                    />
                ), [rows, states.design, viewMode, mbox, print])
            }
        </SurveyCreateAnalysisSummaryDataComponent>
    )
}

export default withBreakPoints(memo(Component))