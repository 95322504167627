import { isBrowser } from 'react-device-detect'
import DetectContainer from './Detect'
import LayoutContainer from './Layout'
import ListsContainer from './Lists'
import ContextContainer from './Context'
import ListsContextContainer from './ListsContext'

interface Props {
    onChangeCategory: (a: string) => void
}

function JumpContainer(props: Props) {
    const { onChangeCategory } = props

    return (
        <>
        {
            isBrowser ? (                
                <ContextContainer>
                    <ListsContextContainer>
                        <div className="Pillar">
                            <ListsContainer/>
                        </div>
                        <div className="Pillar">
                            <LayoutContainer/>
                        </div>
                    </ListsContextContainer>
                </ContextContainer>
                
            ) : (
                <DetectContainer onChangeCategory={onChangeCategory}/>
            )
        }
        </>
    )
}

export default JumpContainer