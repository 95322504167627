import { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { useIntl } from 'react-intl'
import { parseHtmlIntl } from 'ts-utils'
import oc from 'open-color'
import styled, { createGlobalStyle } from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import LocalPrintshopTwoToneIcon from '@material-ui/icons/LocalPrintshopTwoTone'
import { withSurveyFetchData } from 'hoc'
import { defaultDesign } from '../Filter'
import HeadTag from 'styled/HeadTag'
import PrintDataContainer from './PrintData'
import { getCookieName } from 'component/Survey/Create/NewAnalysis/Summary/PrintIcon'
import SurveyCreateAnalysisSummaryTitleComponent from 'component/Survey/Create/NewAnalysis/Summary/Title'

const GlobalStyles = createGlobalStyle`
    @page {
        size:210mm 297mm; /*A4*/
        margin: 0mm;
    }
 
    @media print{
        html, body {
            margin:0;
            padding:0;
            page-break-after: avoid;
            page-break-before: avoid;
        }
    }

    html, body {
        font-size: 16px;
        background-color: white;
    }
`

const WrapperBox = styled.div`
    width: 750px;
    margin: 0 auto;
`
const ButtonBox = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 20px;
    position: sticky;
    background: white;
    top: 0;
    z-index: 1;
`
const Component = (props) => {
    const { propsFetchData } = props
    const { mbox } = propsFetchData

    const params = useParams()
    const survey_no = Number(params.survey_no)

    const [ msg, setMsg ] = useState(true)

    const storage = sessionStorage.getItem(getCookieName(survey_no))
    const { rows, response } = storage ? JSON.parse(storage) : { rows: [], response: 0 }

    const { formatMessage: f } = useIntl()

    function handlePrint() {
        document.getElementById('print').style.display = 'none'
        setMsg(false)
    }

    useEffect(() => {
        if (!msg) {
            window.print()
            document.getElementById('print').style.display = 'flex'
            setMsg(true)
        }
    }, [msg])
   
    return (
        <>
        <HeadTag type="analysis" title={mbox.title} url={window.location.href}/>
        <GlobalStyles/>
        <WrapperBox>
            <ButtonBox id="print">
                <div>
                    <Button 
                        variant='contained' 
                        startIcon={<LocalPrintshopTwoToneIcon fontSize="large" color="secondary"/>}
                        onClick={handlePrint}
                        style={{whiteSpace: 'nowrap'}}
                    >
                        {f({id: 'container.Survey.Create.Analysis.Summary.Print.button'})}
                    </Button>
                </div>
                <div>
                    <Box style={{padding: 5}}>
                        <Typography variant='body2' style={{lineHeight: '120%', color: oc.gray[7]}}>
                            {msg ? parseHtmlIntl(f({id: 'container.Survey.Create.Analysis.Summary.Print.tooltip'})) : ''}
                        </Typography>
                    </Box>
                </div>
            </ButtonBox>
            
            <div>
            <SurveyCreateAnalysisSummaryTitleComponent 
                survey_no={survey_no} 
                title={mbox.title} 
                design={defaultDesign} 
                edit={false}
            />
            <PrintDataContainer 
                mbox={mbox}
                dialog={false}
                design={defaultDesign}
                survey_no={survey_no}
                summaryData={{rows, response, loading: false, refetch: () => {}}}
            />
        </div>
    
        </WrapperBox>
        </>
    )
}

export default withSurveyFetchData('live')({
    mbox: true,
    surveyMboxAnalysisDesign: true
})(false)(Component)