import { SurveyQuestionModuleProps } from 'gql/survey_question_modules'
import { cloneDeep } from 'lodash-es'

/**
 * 타입 변경을 위해 전체 문항 타입에 대한 상태값을 가지고 있는다.
*/
type ModulesStateType = {
    [key: string]: any
}

class ModulesStateClass {
    private modulesState: ModulesStateType;

    constructor() {
        this.modulesState = {}
    }

    // 초기값 셋팅
    init(rows: SurveyQuestionModuleProps[]) {
        this.modulesState = {}

        const newRows = cloneDeep(rows)

        for (let i=0; i<newRows.length; i++) {
            const row = newRows[i]

            delete row._question

            const { __typename } = row
            const key = __typename.replace('Module', '')
       
            row['__typename'] = __typename
            this.modulesState[key] = row
        }
    }

    // 전체 모듈 값을 가지고온다.
    get() {
        return this.modulesState
    }

    // 문항 종류 변경하면 키값을 문자화 해야한다. db에서 새로 저장해야해서리..
    changePK(survey_question_no: number, obj: SurveyQuestionModuleProps) {
        const keys = Object.keys(obj)

        if (keys.includes('_modules')) {
            const _modules = obj._modules
            for (let j=0; j<_modules.length; j++) {
                const row = _modules[j]
                const { survey_module_no } = row
    
                row.survey_module_no = typeof survey_module_no === 'number' ? `c-${survey_module_no}` : survey_module_no
                row.survey_question_no = survey_question_no
            }
        }
    
        if (keys.includes('_answers')) {
            const _answers = obj._answers
            for (let j=0; j<_answers.length; j++) {
                const row = _answers[j]
                const { survey_module_answer_no } = _answers[j]
    
                row.survey_module_answer_no = typeof survey_module_answer_no === 'number' ? `c-${survey_module_answer_no}` : survey_module_answer_no
                row.survey_question_no = survey_question_no
            }
        }
    
        if (keys.includes('_option')) {
            const _option = obj._option
            _option.survey_option_no = 0
            _option.survey_question_no = survey_question_no
        }
    
        return obj
    }
    
    // 편집창 열면 편집기준으로 moduleState 값을 바꿔준다.
    changeState(row: SurveyQuestionModuleProps) {
        const { __typename } = row
    
        const typename = __typename.replace('Module', '')
    
        this.modulesState[typename] = { ...row }

        delete this.modulesState[typename]._question
     
        this.modulesState['_question'] = { ...row._question }
        
        // _modules, _answers 값 적용
        const customModules = (standard: any, value: any) => {         
            if (standard.length === 0) return []

            // 기준 오브젝트에서 사용되는 키값은 배열 0번째와 모두 동일
            const standardKeys = Object.keys(standard[0])

            const standardKeyLen = standardKeys.length

            const aaa =  value.reduce((acc: any, c: any) => {
                let obj: any = {}
                for (let i=0; i<standardKeyLen; i++) {
                    const key = standardKeys[i]

                    if (typeof c[key] === 'undefined') obj[key] = standard[0][key]
                    else obj[key] = c[key]
                }

                acc.push(obj)

                return acc

            }, [])

            return aaa
        }

        // _option 값 적용
        const customOption = (standard: any, value: any) => {         
            return Object.keys(standard).reduce((acc: any, c: string) => {

                // standard 오브젝트 기준으로 value에 있다면, value값을 아니면 standard 기준으로 값을 담는다
                if (typeof value[c] !== 'undefined') acc[c] = value[c]
                else acc[c] = standard[c]
        
                return acc
            }, {})
        }


        // modules, answers pk 키 변경
        const changeKey = (originKey: string, changeKey: string, values: any) => {
            const len = values.length

            if (len < 1) return values

            const keys = Object.keys(values[0])
            const keyLen = keys.length
            

            const newValues = []

            for (let i=0; i<len; i++) {
                const value = values[i]
                let newValue:any = {}
                for (let j=0; j<keyLen; j++) {
                    const key = keys[j]
                    if (key === originKey) {
                        newValue[changeKey] = value[key] 
                        continue
                    }

                    newValue[key] = value[key]
                }

                newValues.push(newValue)
            }

            return newValues
        } 
        
        switch(typename) {
            case '_01':
            case '_02':
            case '_21': {
                delete row._answers

                this.modulesState['_01']['_modules'] = customModules(this.modulesState['_01']['_modules'], row._modules)
                this.modulesState['_02']['_modules'] = customModules(this.modulesState['_02']['_modules'], row._modules)
                this.modulesState['_21']['_modules'] = customModules(this.modulesState['_21']['_modules'], row._modules.map((c, i) => ({...c, answer: c.answer || `Option ${i+1}`})))

                this.modulesState['_01']['_option'] = customOption(this.modulesState['_01']['_option'], row._option)
                this.modulesState['_02']['_option'] = customOption(this.modulesState['_02']['_option'], row._option)
                this.modulesState['_21']['_option'] = customOption(this.modulesState['_21']['_option'], row._option)
                break
            }

            case '_03':
            case '_04': {
                this.modulesState['_03']['_modules'] = row._modules
                this.modulesState['_04']['_modules'] = row._modules
                this.modulesState['_06']['_modules'] = row._modules
                this.modulesState['_08']['_modules'] = row._modules
                this.modulesState['_15']['_modules'] = row._modules

                this.modulesState['_03']['_answers'] = row._answers
                this.modulesState['_04']['_answers'] = row._answers
        
                this.modulesState['_03']['_option'] = customOption(this.modulesState['_03']['_option'], row._option) 
                this.modulesState['_04']['_option'] = customOption(this.modulesState['_04']['_option'], row._option)
                break
            }

            case '_05':
            case '_07': {

                delete row._answers

                this.modulesState['_05']['_modules'] = customModules(this.modulesState['_05']['_modules'], row._modules)
                this.modulesState['_07']['_modules'] = customModules(this.modulesState['_07']['_modules'], row._modules)

                // _05, _06의  modules는 매트릭스형에서는 06,08에 해당한다
                const answers = changeKey('survey_module_no', 'survey_module_answer_no', row._modules)


                this.modulesState['_06']['_answers'] = answers
                this.modulesState['_08']['_answers'] = answers

                this.modulesState['_05']['_option'] = customOption(this.modulesState['_05']['_option'], row._option) 
                this.modulesState['_07']['_option'] = customOption(this.modulesState['_07']['_option'], row._option)
                this.modulesState['_06']['_option'] = customOption(this.modulesState['_06']['_option'], row._option) 
                this.modulesState['_08']['_option'] = customOption(this.modulesState['_08']['_option'], row._option)
                break
            }

            case '_06':
            case '_08': {
                this.modulesState['_06']['_modules'] = row._modules
                this.modulesState['_08']['_modules'] = row._modules
                this.modulesState['_03']['_modules'] = row._modules
                this.modulesState['_04']['_modules'] = row._modules
                this.modulesState['_15']['_modules'] = row._modules

                this.modulesState['_06']['_answers'] = row._answers
                this.modulesState['_08']['_answers'] = row._answers

                // _06, _08의  answers는 일반형태에서는 05,07의 modules에 해당한다
                const modules = changeKey('survey_module_answer_no', 'survey_module_no', row._answers)

                
                this.modulesState['_05']['_modules'] = modules
                this.modulesState['_07']['_modules'] = modules

                this.modulesState['_05']['_option'] = customOption(this.modulesState['_05']['_option'], row._option) 
                this.modulesState['_07']['_option'] = customOption(this.modulesState['_07']['_option'], row._option)
                this.modulesState['_06']['_option'] = customOption(this.modulesState['_06']['_option'], row._option) 
                this.modulesState['_08']['_option'] = customOption(this.modulesState['_08']['_option'], row._option)
                break
            }

            case '_09': {
                delete row._modules
                delete row._answers

                this.modulesState['_09']['_option'] = customOption(this.modulesState['_09']['_option'], row._option) 
                break
            }

            case '_11':
            case '_17':
            case '_18': {
                this.modulesState['_11']['_modules'] = customModules(this.modulesState['_11']['_modules'], row._modules)
                this.modulesState['_17']['_modules'] = customModules(this.modulesState['_17']['_modules'], row._modules)
                this.modulesState['_18']['_modules'] = customModules(this.modulesState['_18']['_modules'], row._modules)

                this.modulesState['_11']['_answers'] = customModules(this.modulesState['_11']['_answers'], row._answers)
                this.modulesState['_17']['_answers'] = customModules(this.modulesState['_17']['_answers'], row._answers)
                this.modulesState['_18']['_answers'] = customModules(this.modulesState['_18']['_answers'], row._answers)

                this.modulesState['_11']['_option'] = customOption(this.modulesState['_11']['_option'], row._option)
                this.modulesState['_17']['_option'] = customOption(this.modulesState['_17']['_option'], row._option)
                this.modulesState['_18']['_option'] = customOption(this.modulesState['_18']['_option'], row._option)
                break
            }

            case '_12':
            case '_13': {
                delete row._answers

                this.modulesState['_12']['_modules'] = customModules(this.modulesState['_12']['_modules'], row._modules)
                this.modulesState['_13']['_modules'] = customModules(this.modulesState['_13']['_modules'], row._modules)

                this.modulesState['_12']['_option'] = customOption(this.modulesState['_12']['_option'], {...row._option, icon_type: row._option.icon_type === 'checkbox' ? 'radio' : row._option.icon_type})
                this.modulesState['_13']['_option'] = customOption(this.modulesState['_13']['_option'], {...row._option, icon_type: row._option.icon_type === 'radio' ? 'checkbox' : row._option.icon_type})
                break
            }

            case '_14': {
                delete row._answers

                this.modulesState['_14']['_modules'] = row._modules

                // _14의  modules는 매트릭스형에서는 _15에 해당한다
                const answers = changeKey('survey_module_no', 'survey_module_answer_no', row._modules)

                this.modulesState['_15']['_answers'] = customModules(this.modulesState['_15']['_answers'], answers)

                this.modulesState['_14']['_option'] = customOption(this.modulesState['_14']['_option'], row._option)
                this.modulesState['_15']['_option'] = customOption(this.modulesState['_15']['_option'], row._option)

                break
            }

            case '_15': {
                this.modulesState['_15']['_modules'] = customModules(this.modulesState['_15']['_modules'], row._modules)
                this.modulesState['_03']['_modules'] = customModules(this.modulesState['_03']['_modules'], row._modules)
                this.modulesState['_04']['_modules'] = customModules(this.modulesState['_04']['_modules'], row._modules)
                this.modulesState['_06']['_modules'] = customModules(this.modulesState['_06']['_modules'], row._modules)
                this.modulesState['_08']['_modules'] = customModules(this.modulesState['_08']['_modules'], row._modules)

                this.modulesState['_15']['_answers'] = customModules(this.modulesState['_15']['_answers'], row._answers)

                // _15의 answers는 _14에 survey_module_no 해당한다
                const modules = changeKey('survey_module_answer_no', 'survey_module_no', row._answers)

                this.modulesState['_14']['_modules'] = customModules(this.modulesState['_14']['_modules'], modules)

                this.modulesState['_14']['_option'] = customOption(this.modulesState['_14']['_option'], row._option)
                this.modulesState['_15']['_option'] = customOption(this.modulesState['_15']['_option'], row._option)
                break
            }

            case '_19': {
                delete row._modules
                delete row._answers

                this.modulesState['_19']['_option'] = customOption(this.modulesState['_19']['_option'], row._option)
                break
            }

            case '_20': {
                delete row._modules
                delete row._answers

                this.modulesState['_20']['_option'] = customOption(this.modulesState['_20']['_option'], row._option)
                break
            }

        }
    
    
        // required는 문항 종류 상관없이 모두 적용
        if (typeof row._option === 'object') {
            if (typeof row._option.required === 'boolean') {
                const keys = Object.keys(this.modulesState)
                const len = keys.length
                for (let i=0; i<len; i++) {
                    const key = keys[i]
                    if (typeof this.modulesState[key]._option === 'object') {
                        if (typeof this.modulesState[key]._option.required === 'boolean') {
                            this.modulesState[key]._option.required = row._option.required
                        }
                    }        
                }
            }
        }  
    }
}

export default ModulesStateClass