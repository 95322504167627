// 미리보기 props 가져오기

import { memo, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { RootState } from "reducer"
import { CHANGE } from 'reducer/survey/modules/cover'
import { SurveyMboxCoverProps } from 'gql/survey_mbox_cover'
import SurveyModulesCoverComponent, { Pos } from 'component/Survey/Modules/Cover'

import { Props as ParentProps } from './Container'

interface Props {
    brand: {
        src: SurveyMboxCoverProps['src'];
        animation: SurveyMboxCoverProps['animation'];
    }
    design: ParentProps['propsFetchData']['mboxDesign'];
}

function PreviewContainer(props: Props) {
    const { brand, design } = props
    const { src, animation } = brand

    const dispatch = useDispatch()
    const state = useSelector((state: RootState) => state.surveyModulesCover)

    // src, animation 값이 undefined라면 빈값을로 담는다.
    const newBrand = { src: state.src || '', animation: state.animation || '' }

    useEffect(() => {
        dispatch({ 
            type: CHANGE,
            src,
            animation
        })
    }, [dispatch, src, animation])

    return (
        <SurveyModulesCoverComponent pos={Pos.Edit} brand={newBrand} design={design}/>
    )
}

export default memo(PreviewContainer)