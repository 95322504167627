import { useEffect, createContext, useContext, useState, useCallback, ReactNode } from 'react'
import { useDispatch } from 'react-redux'
import { HIDE as COLLAPSEHIDE} from 'reducer/survey/create/logic/jumpCollapse'
import { NULL as JUMPNULL } from 'reducer/survey/create/logic/jumpNull'
import { NULL as JUMPFOCUSNULL } from 'reducer/survey/create/logic/jumpFocus'
import { INIT } from 'reducer/survey/create/logic/jump'
import { withSurveyFetchData } from 'hoc'
import { SurveyQuestionModuleProps } from 'gql/survey_question_modules'
import { SurveyPageProps } from 'gql/survey_page'
import { JumpProps, Kind, ID, Method } from 'gql/jump'
import { JumpPointAndExampleCountExistProps} from 'gql/jump_point'
import { v4 as uuidv4 } from 'uuid'
import { Context } from 'container/Survey/Create/Logic/Jump/Context'

interface Props {
    propsFetchData: {
        jump: JumpProps[];
        jumpPointAndExampleCountExist: JumpPointAndExampleCountExistProps;
        refetchJump: () => void | undefined;
    },
    children: ReactNode
}

interface QuizContextProps {
    point: JumpPointAndExampleCountExistProps['point'];
    exampleCount: JumpPointAndExampleCountExistProps['exampleCount'];
} 

interface ItemContextProps {
    jump: JumpProps[];
    onInit: () => void;
    refetchJump: () => void | undefined;
} 

export const QuizContext = createContext<QuizContextProps>({
    point: false,
    exampleCount: false
})


export const ListsContext = createContext<ItemContextProps>({
    jump: [],
    onInit: () => {},
    refetchJump: () => {}
})

export function createJumpRow(id: ID, kind: Kind ) {
    return {
        jump_no: uuidv4(),
        id,                      // survey_page_no or survey_question_no 
        kind,                    // page question
        method: Method.Move,
        survey_question_nos: [],
        rules: []
    }
}

export function createJumpRows(pages: SurveyPageProps[], questionsAll: SurveyQuestionModuleProps[], jump: JumpProps[]) {
    const rows: JumpProps[] = []

    for (let i=0; i<pages.length; i++) {
        const { survey_page_no } = pages[i]

        // 해당페이지의 jump 값이 있는지 체크
        const jumpRow = jump.find(c => c.id === survey_page_no && c.kind === Kind.Page)

        // jmup값이 없다면 임의로 만들어서준다
        if (jumpRow) rows.push(jumpRow)
        else rows.push(createJumpRow(survey_page_no, Kind.Page))

        // 페이지내 문항값 조회
        const questions = questionsAll.filter(row => {
            const { _question } = row
            const { survey_page_no: key } = _question

            return survey_page_no === key
        })

        for (let j=0; j<questions.length; j++) {
            const { _question } = questions[j]
            const { survey_question_no } = _question

            // jmup값이 없다면 임의로 만들어서준다
            const jumpRow = jump.find(c => c.id === survey_question_no && c.kind === Kind.Question)

            if (jumpRow) rows.push(jumpRow)
            else rows.push(createJumpRow(survey_question_no, Kind.Question))
        }
    }

    return rows
}

function ListsContextContanier(props: Props) {
    const { propsFetchData, children } = props
    const { jump, jumpPointAndExampleCountExist, refetchJump } = propsFetchData

    const { point, exampleCount } = jumpPointAndExampleCountExist

    const { pages, questions } = useContext(Context)

    const [ rows, setRows ] = useState(createJumpRows(pages, questions, jump))

    const dispatch = useDispatch()

    // 전체값 해제하면 임의로 rows 초기화 시킨다. (로직 수정만하고 저장안하고 전체해제누르면 jump값이 변화가 없기때문에 임의로 해준다.)
    const handleInit = useCallback(() => {
        setRows(createJumpRows(pages, questions, []))
    }, [pages, questions])

    // 리페치후에는 다시 rows를 만들고
    useEffect(() => {
        setRows(createJumpRows(pages, questions, jump))
    }, [pages, questions, jump])

    // rows값은 reducer로 보내 초기값으로 한다. 
    useEffect(() => {
        dispatch({
            type: INIT,
            O_INIT: {
                rows
            }
        })
    }, [rows, dispatch])

    // 언마운트시 collaspe를 다시 hide로 돌려놔야 다시 들어왔을때 설정값이 자동으로 안열린다.( 그전에 전체 닫기를 누르고 언마운트시 발생하는 이슈 )
    useEffect(() => {
        return () => {
            dispatch({ type: COLLAPSEHIDE })
            dispatch({ type: JUMPNULL })
            dispatch({ type: JUMPFOCUSNULL })
        }
    }, [dispatch])

    return (
        <QuizContext.Provider value={{ point, exampleCount }}>
            <ListsContext.Provider value={{ 
                jump: rows,
                onInit: handleInit,
                refetchJump
            }}>
                {children}
            </ListsContext.Provider>
        </QuizContext.Provider>
    )
}

export default withSurveyFetchData('preview')({
    jump: true,
    jumpPointAndExampleCountExist: true,
    _fetchPolicy: {
        jump: 'cache-first'
    }
})(false)(ListsContextContanier)

