import { useIntl } from 'react-intl'
import {
    SurveyCreateThanksEditTabsComponent, 
    SurveyCreateThanksEditTabComponent, 
    SurveyCreateThanksEditQuestionComponent,
    SurveyCreateThanksEditStartComponent,
    SurveyCreateThanksEditOptionStartComponent
} from 'component'


export default () => {
    
    const { formatMessage: f } = useIntl()

    const tabs = [f({id: 'container.Survey.Edit.01.tabs.0'})]

    return (
        <>
        <SurveyCreateThanksEditTabsComponent tabs={tabs}/>
        <SurveyCreateThanksEditTabComponent index={0}>
            <SurveyCreateThanksEditQuestionComponent/>
            <SurveyCreateThanksEditStartComponent/>
        </SurveyCreateThanksEditTabComponent>
        <SurveyCreateThanksEditTabComponent index={1}>
            <SurveyCreateThanksEditOptionStartComponent/>
        </SurveyCreateThanksEditTabComponent>
        </>
    )
}
