/**
 * 시작글 완료글 수정 사항 있는지 체크
 */
import { memo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { DO as ISEDITDO, INIT as ISEDITINIT } from 'reducer/survey/create/module/isEdit'
import BeforeUnload from 'styled/BeforeUnload'

interface Props {
    isEqualValue: boolean;
}

function IsEditComponent(props: Props) {
    const { isEqualValue } = props

    const dispatch = useDispatch()

    // 수정된 값이 있는지 체크 
    useEffect(() => {
        if (!isEqualValue) dispatch({ type: ISEDITDO })
        else dispatch({ type: ISEDITINIT })
    }, [isEqualValue, dispatch])

    useEffect(() => {
        return () => dispatch({ type: ISEDITINIT })
    }, [dispatch])
        
    return (
        <>
        {!isEqualValue && <BeforeUnload/>}
        </>
    )
}

export default memo(IsEditComponent)