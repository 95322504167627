// 레포트 편집을 제외하고는...다른 페이지는 실제 데이터를 보여준다
import { memo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { ViewMode } from 'gql/survey_analysis_summary'
import { INIT } from 'reducer/analysis/summary/order'
import { Props as ParentProps } from './EditData'
import ViewContainer from './View'

interface Props {
    mbox: ParentProps['mbox'];
    design: ParentProps['design'];
    dialog: ParentProps['dialog'];
    survey_no: ParentProps['survey_no'];
    summaryData: ParentProps['summaryData'];
}

const conditionsPlay:[] = []

function EditDataComponent(props: Props) {
    const { mbox, design, dialog, survey_no, summaryData } = props

    const dispatch = useDispatch()

    const input = { print: true, viewMode: ViewMode.View, conditionsPlay }

    useEffect(() => {
        dispatch({ type: INIT, survey_no })
    }, [dispatch, survey_no])

    return (
        <ViewContainer
            mbox={mbox}
            design={design}
            dialog={dialog}
            survey_no={survey_no}
            input={input}
            summaryData={summaryData}
        />
    )
}

export default memo(EditDataComponent)