import { useState, useMemo, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { isEqual } from 'lodash'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { DO as ISEDITDO, INIT as ISEDITINIT } from 'reducer/survey/create/module/isEdit'
import EditSurveyContext from 'context/editSurvey'
import { v4 as uuidv4 } from 'uuid'
import randomstring from 'randomstring'
import { scrollerObj as _scrollerObj } from 'utils/survey'
import { useIntl } from 'react-intl'
import { Typography } from '@material-ui/core'
import { withSurveyFetchMbox, withSurveyFetchQuestions, withSurveyFetchData } from 'hoc'
import BeforeUnload from 'styled/BeforeUnload'
import ModulesStateClass from './func/moduleState'
import { 
    SurveyCreateModuleContainer,
    SurveyCreateModuleOneContainer,
    SurveyCreateMenuContainer, 
    SurveyCreateEditContainer,
    SurveyCreatePagesContainer
} from 'container'
import { 
    SurveyCreateLeftComponent, 
    SurveyCreateComponent, 
    SurveyCreateEditComponent,
    SurveyCreateModuleMethodComponent,
    SurveyCreateModuleMethodViewComponent 
} from 'component'

const initialState = {
    viewType: 'multi', // one, multi
    edit: { module_type: '', survey_question_no: 0},
    focus: {survey_question_no: '', uuid: ''},
    imageAttach: false, // 이미지 첨부함 보일지 여부
    editQuestion: {} // 편집하면서 실시간으로 바뀌는값을 저장한다 (아직 DB에 저장되기전이다 미리보기 하기위해 필요한값)
}

let timeoutQuestion = null
let timeoutConfirm = null
let timeoutModules = {
    _answers: [],
    _modules: []
}
let timeoutOption = {}

// 텍스트값 입력후 반영 딜레이 시간
function getDelay(viewType) {
    return viewType === 'one' ? 300 : 1000
}

function clearTimeoutAll() {
    clearTimeout(timeoutQuestion)
    clearTimeout(timeoutConfirm)
    timeoutModules._answers.map(c => clearTimeout(c))
    timeoutModules._modules.map(c => clearTimeout(c))
    Object.keys(timeoutOption).map(c => clearTimeout(timeoutOption[c]))
}


// 모바일에서 사용되는 카테고리 타이틀
function getCategoryTitle({f, editQuestion }) {
    if (typeof editQuestion._question === 'undefined') return ''

    const { module_type, number } = editQuestion._question

    return f({id: `container.Survey.Create.ModuleRoot.category.title.${module_type}` }, {number})
}

// LEFT 메뉴 스크롤
const scrolls = {
    id: 'scrollId',
    to: 'scrollTo',
    bottom: 'scrollBottom'
}

// 본문 스크롤
const scrollerObj = _scrollerObj()

export const mode = 'edit'

// 문항 변경을 위해 문항 종류별로 상태값을 가지고 있자.
export const classModulesState = new ModulesStateClass()

const Component = (props) => {

    const { mbox, category, onChangeCategory, goPage, refetchQuestions, propsFetchData, refetchMbox } = props

    const { surveyQuestionsModuleSample } = propsFetchData

    const { formatMessage: f } = useIntl()

    const params = useParams()

    const survey_no = Number(params.survey_no)

    const [ state, setState ] = useState(initialState)


    // 원본데이터
    const [ originQuestion, setOriginQuestion ] = useState(null)

    const [ pageMenu, setPageMenu ] = useState('') // lists, move

    const dispatch = useDispatch()

    // 문항편집 열리는 이벤트
    const onOpenEdit = useCallback(async(survey_question_no) => {
        dispatch({type: BACKDROPSHOW})

        setTimeout(() => {
            const row  = props.questions.find(c => survey_question_no === c._question.survey_question_no)

            setState(prevState => ({
                ...prevState,
                edit: {
                    ...prevState.edit,
                    module_type: row._question.module_type,
                    survey_question_no: row._question.survey_question_no
                },
                viewType: 'one'
            }))

            setOriginQuestion(row)
        }, 1)
    }, [props.questions, dispatch])


    // 문항편집 닫힐때 이벤트
    const onCloseEdit = useCallback(async(method) => {
   
        await setState(prevState => ({
            ...prevState,
            focus: {
                ...prevState.focus,
                survey_question_no: prevState.edit.survey_question_no, 
                uuid: randomstring.generate(7)
            },
            viewType: initialState.viewType,
            edit: { ...initialState.edit },
            editQuestion: method === 'close' ? { ...initialState.editQuestion } : { ...prevState.editQuestion }
        }))

        setOriginQuestion(null)
        
        dispatch({type: BACKDROPHIDE})

        clearTimeoutAll()
          
    }, [dispatch])
    

    // 문항 편집에서 화면열릴때 초기값을 저장해준다
    const onInitEdit = useCallback(async(questions) => {
       
        clearTimeoutAll()
        
        setState(prevState => ({
            ...prevState,
            editQuestion: questions
        }))
    }, [])


    // 문항 타입 변경해도, 최초 값으로 돌리기
    const onChangeRollback = useCallback(() => {
        setState(prevState => ({
            ...prevState,
            edit: {
                ...prevState.edit,
                module_type: originQuestion._question.module_type
            },
            editQuestion: originQuestion
        }))
    }, [originQuestion])


    // 문항별로 값이 바뀔때 기존 data값도 바꿔서 미리보기 형식으로 보이게하는 이벤트
    const onChangeEdit = useCallback((questions) => {
        // 데이터 가져오기전 값이 호출 된거는 거르자 
        if (typeof questions.__typename === 'undefined') return 

        switch(state.edit.module_type) {
            case '_00':
                setState(prevState => {
                    const _question = {
                        ...questions._question,
                        question: prevState.editQuestion._question.question,
                        confirm: prevState.editQuestion._question.confirm
                    }

                    return {
                        ...prevState,
                        editQuestion: {
                            ...prevState.editQuestion,
                            _question
                        }
                    }
                })
                break
            case '_01':
            case '_02':
            case '_12':
            case '_13':
                setState(prevState => {
                    const _question = {
                        ...questions._question,
                        question: prevState.editQuestion._question.question,
                        confirm: prevState.editQuestion._question.confirm
                    }

                    // 모듈 추가 할경우 prevState에는 해당 배열이 없으므로 미적용
                    const _modules = questions._modules.map(c => {
                        // questions.modules랑 같은값 찾기 없으면 questions.moduels의 answer 값적용 (보기 추가시 발생..)
                        const { answer } = prevState.editQuestion._modules.find(({ survey_module_no }) => c.survey_module_no === survey_module_no) || { answer: c.answer }

                        return {
                            ...c,
                            answer
                        }
                    })

                    const _option = {
                        ...questions._option,
                        is_comment_placeholder: prevState.editQuestion._option.is_comment_placeholder
                    }

                    return {
                        ...prevState,
                        editQuestion: {
                            ...prevState.editQuestion,
                            _question,
                            _modules,
                            _option
                        }
                    }
                })
                break

            case '_03':
            case '_04':

                setState(prevState => {
                    const _question = {
                        ...questions._question,
                        question: prevState.editQuestion._question.question,
                        confirm: prevState.editQuestion._question.confirm
                    }

                    // 모듈 추가 할경우 prevState에는 해당 배열이 없으므로 미적용
                    const _modules = questions._modules.map(c => {
                        // questions.modules랑 같은값 찾기 없으면 questions.moduels의 answer 값적용 (보기 추가시 발생..)
                        const { column } = prevState.editQuestion._modules.find(({ survey_module_no }) => c.survey_module_no === survey_module_no) || { column: c.column }

                        return {
                            ...c,
                            column
                        }
                    })

                    const _answers = questions._answers.map(c => {
                        // questions.modules랑 같은값 찾기 없으면 questions.moduels의 answer 값적용 (보기 추가시 발생..)
                        const { answer } = prevState.editQuestion._answers.find(({ survey_module_answer_no }) => c.survey_module_answer_no === survey_module_answer_no) || { answer: c.answer }

                        return {
                            ...c,
                            answer
                        }
                    })


                    const _option = {
                        ...questions._option,
                        is_comment_placeholder: prevState.editQuestion._option.is_comment_placeholder
                    }

                    return {
                        ...prevState,
                        editQuestion: {
                            ...prevState.editQuestion,
                            _question,
                            _modules,
                            _answers,
                            _option
                        }
                    }
                })
                break
            case '_05':
                setState(prevState => {
                    const _question = {
                        ...questions._question,
                        question: prevState.editQuestion._question.question,
                        confirm: prevState.editQuestion._question.confirm
                    }

                    // 모듈 추가 할경우 prevState에는 해당 배열이 없으므로 미적용
                    const _modules = questions._modules.map((c, i) => {
                        // questions.modules랑 같은값 찾기 없으면 questions.moduels의 answer 값적용 (보기 추가시 발생..)
                        const { answer, point } = prevState.editQuestion._modules.find(({ survey_module_no }) => c.survey_module_no === survey_module_no) || { answer: c.answer, point: c.point }

                        return {
                            ...c,
                            answer,
                            point
                        }
                    })

                    const _option = {
                        ...questions._option,
                        left_label: prevState.editQuestion._option.left_label,
                        right_label: prevState.editQuestion._option.right_label,
                        is_comment_placeholder: prevState.editQuestion._option.is_comment_placeholder,
                    }

                    return {
                        ...prevState,
                        editQuestion: {
                            ...prevState.editQuestion,
                            _question,
                            _modules,
                            _option
                        }
                    }
                })
                break
            case '_06': 
                setState(prevState => {
                    const _question = {
                        ...questions._question,
                        question: prevState.editQuestion._question.question,
                        confirm: prevState.editQuestion._question.confirm
                    }

                    // 모듈 추가 할경우 prevState에는 해당 배열이 없으므로 미적용
                    const _modules = questions._modules.map(c => {
                        // questions.modules랑 같은값 찾기 없으면 questions.moduels의 answer 값적용 (보기 추가시 발생..)
                        const { column } = prevState.editQuestion._modules.find(({ survey_module_no }) => c.survey_module_no === survey_module_no) || { column: c.column }

                        return {
                            ...c,
                            column
                        }
                    })


                    const _answers = questions._answers.map((c, i) => {
                        // questions.modules랑 같은값 찾기 없으면 questions.moduels의 answer 값적용 (보기 추가시 발생..)
                        const { answer, point } = prevState.editQuestion._answers.find(({ survey_module_answer_no }) => c.survey_module_answer_no === survey_module_answer_no) || { answer: c.answer, point: c.point }

                        return {
                            ...c,
                            answer,
                            point
                        }
                    })

                    const _option = {
                        ...questions._option,
                        left_label: prevState.editQuestion._option.left_label,
                        right_label: prevState.editQuestion._option.right_label,
                        is_comment_placeholder: prevState.editQuestion._option.is_comment_placeholder
                    }

                    return {
                        ...prevState,
                        editQuestion: {
                            ...prevState.editQuestion,
                            _question,
                            _modules,
                            _answers,
                            _option
                        }
                    }
                })
                break
            case '_07':
                setState(prevState => {
                    const _question = {
                        ...questions._question,
                        question: prevState.editQuestion._question.question,
                        confirm: prevState.editQuestion._question.confirm
                    }

                    // 모듈 추가 할경우 prevState에는 해당 배열이 없으므로 미적용
                    const _modules = questions._modules.map((c, i) => {
                        // questions.modules랑 같은값 찾기 없으면 questions.moduels의 answer 값적용 (보기 추가시 발생..)
                        const { answer, point } = prevState.editQuestion._modules.find(({ survey_module_no }) => c.survey_module_no === survey_module_no) || { answer: c.answer, point: c.point }

                        return {
                            ...c,
                            answer,
                            point
                        }
                    })

                    const _option = {
                        ...questions._option,
                        is_comment_placeholder: prevState.editQuestion._option.is_comment_placeholder
                    }

                    return {
                        ...prevState,
                        editQuestion: {
                            ...prevState.editQuestion,
                            _question,
                            _modules,
                            _option
                        }
                    }
                })
                break
            case '_08': 
                setState(prevState => {
                    const _question = {
                        ...questions._question,
                        question: prevState.editQuestion._question.question,
                        confirm: prevState.editQuestion._question.confirm
                    }

                    // 모듈 추가 할경우 prevState에는 해당 배열이 없으므로 미적용
                    const _modules = questions._modules.map(c => {
                        // questions.modules랑 같은값 찾기 없으면 questions.moduels의 answer 값적용 (보기 추가시 발생..)
                        const { column } = prevState.editQuestion._modules.find(({ survey_module_no }) => c.survey_module_no === survey_module_no) || { column: c.column }

                        return {
                            ...c,
                            column
                        }
                    })

                    const _answers = questions._answers.map((c, i) => {
                        // questions.modules랑 같은값 찾기 없으면 questions.moduels의 answer 값적용 (보기 추가시 발생..)
                        const { answer, point } = prevState.editQuestion._answers.find(({ survey_module_answer_no }) => c.survey_module_answer_no === survey_module_answer_no) || { answer: c.answer, point: c.point }

                        return {
                            ...c,
                            answer,
                            point
                        }
                    })

                    const _option = {
                        ...questions._option,
                        is_comment_placeholder: prevState.editQuestion._option.is_comment_placeholder
                    }

                    return {
                        ...prevState,
                        editQuestion: {
                            ...prevState.editQuestion,
                            _question,
                            _modules,
                            _answers,
                            _option
                        }
                    }
                })
                break
            case '_09':
                setState(prevState => {
                    const _question = {
                        ...questions._question,
                        question: prevState.editQuestion._question.question,
                        confirm: prevState.editQuestion._question.confirm
                    }

                    const _option = {
                        ...questions._option,
                        national_flag: questions._option.national_flag.map(c => c.code),
                        min_length: prevState.editQuestion._option.min_length,        
                        max_length: prevState.editQuestion._option.max_length,        
                        min_number: prevState.editQuestion._option.min_number,        
                        max_number: prevState.editQuestion._option.max_number,        
                        unit: prevState.editQuestion._option.unit              
                    }

                    return {
                        ...prevState,
                        editQuestion: {
                            ...prevState.editQuestion,
                            _question,
                            _option
                        }
                    }
                })
                break
            case '_10':
            case '_20':
                setState(prevState => {
                    const _question = {
                        ...questions._question,
                        question: prevState.editQuestion._question.question,
                        confirm: prevState.editQuestion._question.confirm
                    }

                    const _option = {
                        ...questions._option             
                    }

                    return {
                        ...prevState,
                        editQuestion: {
                            ...prevState.editQuestion,
                            _question,
                            _option
                        }
                    }
                })
                break
            case '_11':
            case '_17':
            case '_18':
                setState(prevState => {
                    const _question = {
                        ...questions._question,
                        question: prevState.editQuestion._question.question,
                        confirm: prevState.editQuestion._question.confirm
                    }

                    const _modules = [...new Array(questions._answers.length).fill({}).map((c, i) => ({
                        indexs: (i + 1),
                        survey_question_no: questions._question.survey_question_no,
                        survey_module_no: uuidv4()
                    }))]
        
                    const _answers = questions._answers.map(c => {
                        // questions.modules랑 같은값 찾기 없으면 questions.moduels의 answer 값적용 (보기 추가시 발생..)
                        const { answer } = prevState.editQuestion._answers.find(({ survey_module_answer_no }) => c.survey_module_answer_no === survey_module_answer_no) || { answer: c.answer }

                        return {
                            ...c,
                            answer
                        }
                    })

                    const _option = {
                        ...questions._option,
                        is_comment_placeholder: prevState.editQuestion._option.is_comment_placeholder       
                    }

                    return {
                        ...prevState,
                        editQuestion: {
                            ...prevState.editQuestion,
                            _question,
                            _modules,
                            _answers,
                            _option
                        }
                    }
                })
                break
            case '_14':
                setState(prevState => {
                    const _question = {
                        ...questions._question,
                        question: prevState.editQuestion._question.question,
                        confirm: prevState.editQuestion._question.confirm
                    }

                    // 모듈 추가 할경우 prevState에는 해당 배열이 없으므로 미적용
                    const _modules = questions._modules.map((c, i) => {
                        // questions.modules랑 같은값 찾기 없으면 questions.moduels의 answer 값적용 (보기 추가시 발생..)
                        const { answer, point } = prevState.editQuestion._modules.find(({ survey_module_no }) => c.survey_module_no === survey_module_no) || { answer: c.answer, point: c.point  }

                        return {
                            ...c,
                            indexs: (i + 1),
                            answer,
                            point
                        }
                    })


                    const _option = {
                        ...questions._option,
                        is_comment_placeholder: prevState.editQuestion._option.is_comment_placeholder
                    }


                    return {
                        ...prevState,
                        editQuestion: {
                            ...prevState.editQuestion,
                            _question,
                            _modules,
                            _option
                        }
                    }
                })
                break
            case '_15':
                setState(prevState => {
                    const _question = {
                        ...questions._question,
                        question: prevState.editQuestion._question.question,
                        confirm: prevState.editQuestion._question.confirm
                    }

                    // 모듈 추가 할경우 prevState에는 해당 배열이 없으므로 미적용
                    const _modules = questions._modules.map(c => {
                        // questions.modules랑 같은값 찾기 없으면 questions.moduels의 answer 값적용 (보기 추가시 발생..)
                        const { column } = prevState.editQuestion._modules.find(({ survey_module_no }) => c.survey_module_no === survey_module_no) || { column: c.column }

                        return {
                            ...c,
                            column
                        }
                    })

                    const _answers = questions._answers.map((c, i) => {
                        // questions.modules랑 같은값 찾기 없으면 questions.moduels의 answer 값적용 (보기 추가시 발생..)
                        const { answer, point } = prevState.editQuestion._answers.find(({ survey_module_answer_no }) => c.survey_module_answer_no === survey_module_answer_no) || { answer: c.answer, point: c.point }

                        return {
                            ...c,
                            indexs: (i + 1),
                            answer,
                            point,
                            survey_question_no: questions._question.survey_question_no
                        }
                    })


                    const _option = {
                        ...questions._option,
                        is_comment_placeholder: prevState.editQuestion._option.is_comment_placeholder
                    }

                    return {
                        ...prevState,
                        editQuestion: {
                            ...prevState.editQuestion,
                            _question,
                            _modules,
                            _answers,
                            _option
                        }
                    }
                })
                break
            case '_16':
                setState(prevState => {
                    const _question = {
                        ...questions._question,
                        question: prevState.editQuestion._question.question,
                        confirm: prevState.editQuestion._question.confirm
                    }

                    // 모듈 추가 할경우 prevState에는 해당 배열이 없으므로 미적용
                    const _modules = questions._modules.map(c => {
                        // module 01 참조. 약관동의형은 일단 보기 한개만 제공이라 이렇게 할 필요없는데... 보기 추가도 가능할지몰리서 일단은...
                        const { answer } = prevState.editQuestion._modules.find(({ survey_module_no }) => c.survey_module_no === survey_module_no) || { answer: c.answer }

                        return {
                            ...c,
                            answer
                        }
                    })

                    const _option = {
                        ...questions._option
                    }

                    return {
                        ...prevState,
                        editQuestion: {
                            ...prevState.editQuestion,
                            _question,
                            _modules,
                            _option
                        }
                    }
                })
                break
            case '_19':
                setState(prevState => {
                    const _question = {
                        ...questions._question,
                        question: prevState.editQuestion._question.question,
                        confirm: prevState.editQuestion._question.confirm
                    }

                    const _option = {
                        ...questions._option,
                        is_comment_placeholder: prevState.editQuestion._option.is_comment_placeholder
                    }
                    
                    return {
                        ...prevState,
                        editQuestion: {
                            ...prevState.editQuestion,
                            _question,
                            _option
                        }
                    }
                })
                break
            case '_21':
                setState(prevState => {
                    const _question = {
                        ...questions._question,
                        question: prevState.editQuestion._question.question,
                        confirm: prevState.editQuestion._question.confirm
                    }

                    const _option = {
                        ...questions._option,
                        label: prevState.editQuestion._option.label,
                        is_comment_placeholder: prevState.editQuestion._option.is_comment_placeholder,
                    }

                    return {
                        ...prevState,
                        editQuestion: {
                            ...prevState.editQuestion,
                            _question,
                            _modules: questions._modules,
                            _option
                        }
                    }
                })
                break
            default:
                break
        }
    }, [state.edit.module_type])


    // 문항편집에서 텍스트 내용 바뀔때 실시간 미리보기에서도 바뀐다.
    const onChangeQuestionEdit = useCallback((props) => {
        const { value } = props

        clearTimeout(timeoutQuestion)

        timeoutQuestion = setTimeout(() => {
            setState(prevState => ({
                ...prevState,
                editQuestion: {
                    ...prevState.editQuestion,
                    _question: {
                        ...prevState.editQuestion._question,
                        question: value
                    }
                }
            }))
        }, getDelay(state.viewType)) 
    }, [state.viewType])

    // 1문 1답 확인 버튼
    const onChangeConfirmEdit = useCallback((value) => {
        clearTimeout(timeoutConfirm)

        timeoutConfirm = setTimeout(() => {
            setState(prevState => ({
                ...prevState,
                editQuestion: {
                    ...prevState.editQuestion,
                    _question: {
                        ...prevState.editQuestion._question,
                        confirm: value
                    }
                }
            }))
        }, getDelay(state.viewType)) 
    }, [state.viewType])


    // 문항편집에서 옵션값 INPUT형태. 바뀔때 실시간으로 미리보기에서도 바꿔준다
    const onChangeModulesEdit = useCallback((e, key, index) => {
        const column = e.currentTarget.name
        const value = e.currentTarget.value
        clearTimeout(timeoutModules[key][index])

        timeoutModules[key][index] = setTimeout(() => {
            setState(prevState => {
          
                if (typeof prevState.editQuestion[key] === 'undefined') return prevState

                const values = prevState.editQuestion[key].map((c, i) => {
                    return i === index ? { ...c, [column]: value} : c
                })
                
                return ({
                    ...prevState,
                    editQuestion: {
                        ...prevState.editQuestion,
                        [key]: values
                    }
                })
            })
              
        }, getDelay(state.viewType)) 
    }, [state.viewType])


    // 문항편집에서 옵션값 바뀔때 실시간으로 미리보기에서도 바꿔준다
    const onChangeOptionEdit = useCallback((e) => {
        const column = e.currentTarget.name
        const value = e.currentTarget.value
        clearTimeout(timeoutOption[column])

        timeoutOption[column] = setTimeout(() => {
            setState(prevState => ({
                ...prevState,
                editQuestion: {
                    ...prevState.editQuestion,
                    _option: {
                        ...prevState.editQuestion._option,
                        [column]: value
                    }
                }
            }))
        }, getDelay(state.viewType)) 
    }, [state.viewType])


    // 문항편집에서 옵션값 바뀔때 실시간으로 미리보기에서도 바꿔준다
    const _onChangeOptionEdit = useCallback((column, value) => {
        clearTimeout(timeoutOption[column])

        timeoutOption[column] = setTimeout(() => {
            setState(prevState => ({
                ...prevState,
                editQuestion: {
                    ...prevState.editQuestion,
                    _option: {
                        ...prevState.editQuestion._option,
                        [column]: value
                    }
                }
            }))
        }, getDelay(state.viewType)) 
    }, [state.viewType])

    // 페이지 리스트 오픈 이벤트
    const onOpenPageLists = useCallback(() => {
        setPageMenu(prevState => prevState === 'lists' ? '' : 'lists')
    }, [])


    // 페이지 리스트 닫기 이벤트
    const onClosePage = useCallback(() => {
        setPageMenu('')
    }, [])

    // 페이지 이동 오픈 이벤트
    const onOpenPageMove = useCallback(() => {
        setPageMenu(prevState => prevState === 'move' ? '' : 'move')
    }, [])

    // 문항 편집에서 클래식화면일경우 편집 문항으로 포커스 가게하는 이벤트
    const onChangeFocusEdit = useCallback(async() => {
        setState(prevState => ({
            ...prevState,
            focus: {survey_question_no: prevState.edit.survey_question_no, uuid: randomstring.generate(7)}
        }))
    }, [])

    // 편집화면에서 미리보기 모드 변경 이벤트
    const onChangeMethodView = async(_type) => {
        await dispatch({ type: BACKDROPSHOW })
        setTimeout(async() => {
            await setState(prevState => ({
                ...prevState,
                viewType: _type
            }))

            if (_type === 'multi') await onChangeFocusEdit()
            
            dispatch({ type: BACKDROPHIDE })
        }, 10)
    }

    const onChangeModuleType = useCallback((module_type) => {
        setState(prevState => {
            // 동일한거면 변경하지말자.
            if (module_type === prevState.edit.module_type) return prevState

            const survey_question_no = prevState.edit.survey_question_no

            const modulesStateValue = classModulesState.get()

            let editQuestion = modulesStateValue[module_type]


            editQuestion = classModulesState.changePK(survey_question_no, editQuestion)
            
            // 문항 종류 변경
            const _question = modulesStateValue['_question']
            _question.module_type = module_type

            return {
                ...prevState,
                edit: {
                    ...prevState.edit,
                    module_type,
                    change_type: true
                },
                editQuestion: {
                    ...prevState.editQuestion,
                    ...editQuestion,
                    _question
                },
                viewType: 'one'
            }
        })
    }, [])

    // 편집화면 컴포넌트 (편집아닐때는 시작완료글/문항/페이지 메뉴 보인다)
    const ViewComponent = useCallback((_props) => {
        const { _method, _editOpen, _edit, _pageMenu, _page, _pages, _refetchQuestions, _refetchPages } = _props

        return (
            _editOpen ? (
                <div className='Pillar'>
                    <SurveyCreateEditComponent scrolls={scrolls}>
                        <SurveyCreateEditContainer scrolls={scrolls} survey_no={survey_no} method={_method} edit={_edit} on={{
                            close: onCloseEdit,
                            init: onInitEdit,
                            rollback: onChangeRollback,
                            change: onChangeEdit,
                            changeQuestion: onChangeQuestionEdit,
                            changeModules: onChangeModulesEdit,
                            changeOption: onChangeOptionEdit,
                            _changeOption: _onChangeOptionEdit,
                            changeConfirmEdit: onChangeConfirmEdit,
                            changeModuleType: onChangeModuleType
                        }} refetchQuestions={_refetchQuestions}/>
                    </SurveyCreateEditComponent>
                </div>
            ) : (
                <>
                <div className='Pillar'>
                    <SurveyCreateMenuContainer
                        method={_method}
                        scrollerObj={scrollerObj}
                        pages={_pages} 
                        page={_page} 
                        refetchQuestions={_refetchQuestions} 
                        refetchPages={_refetchPages} 
                        pageMenu={_pageMenu}
                        onOpenPageLists={onOpenPageLists} 
                        onOpenPageMove={onOpenPageMove} 
                        onClosePage={onClosePage} 
                        onChangeCategory={onChangeCategory}
                        goPage={goPage}
                    />
                </div>
                {
                    _pageMenu && (
                        <div className='Pillar'>
                            <SurveyCreatePagesContainer 
                                survey_no={survey_no}
                                pageMenu={_pageMenu} 
                                pages={_pages} 
                                page={_page} 
                                goPage={goPage}
                                onOpenPageLists={onOpenPageLists}
                                onOpenPageMove={onOpenPageMove}
                                onClosePage={onClosePage}
                                refetchQuestions={_refetchQuestions} 
                                refetchPages={_refetchPages} 
                            />
                        </div>
                    )
                }
                </>
            )
        )
    }, [
        survey_no, 
        goPage, 
        onOpenPageLists, 
        onOpenPageMove, 
        onClosePage, 
        onCloseEdit, 
        onInitEdit, 
        onChangeRollback,
        onChangeEdit, 
        onChangeQuestionEdit, 
        onChangeModulesEdit, 
        onChangeOptionEdit, 
        _onChangeOptionEdit,
        onChangeConfirmEdit,
        onChangeCategory,
        onChangeModuleType
    ])

    /*
        편집화면일때 아닐때(모듈 추가 리스트 보임) 구분 
        편집화면=edit
        편집화면!=lists
        페이지 리스트 확인클릭시=pageLists
        페이지 이동클릭시=pageMovee
    */


    // 문항편집이면 
    const editOpen = state.edit.survey_question_no > 0

    const layout = !editOpen ? (
        pageMenu === 'lists' ? 'pageLists' :
        pageMenu === 'move' ? 'pageMove' : 'lists'
    ) : 'edit'

    const styleMulti = {
        display: !editOpen || state.viewType === 'multi' ? 'block' : 'none'
    }

    const styleOne = {
        display: editOpen && state.viewType === 'one' ? 'block' : 'none'
    }

    const categoryTitle = getCategoryTitle({f, editQuestion: state.editQuestion})

    const { indexs: currentPage } = props.pages.find(c => c.survey_page_no === props.page) || { indexs: 1 }


    // 현재 수정한 값이 있는지 체크
    const isEdit = (editOpen && originQuestion && !isEqual(originQuestion, state.editQuestion))

    
    // 창이 닫히면, 샘플 기본값을 담는다
    useEffect(() => {
        if (surveyQuestionsModuleSample && state.edit.module_type === '') {
            classModulesState.init(surveyQuestionsModuleSample)
        }
    }, [surveyQuestionsModuleSample, state])


    // 갑이 변경 되면 담는다.
    useEffect(() => {  
        if (state.edit.module_type) {
            if (state.editQuestion.__typename) {

                // 편집 하고 저장하고나면 module_type과 editQuestion이 다른데, 같을때만 상태값을 저장해야 진짜 수정하는 값의 상태값만 담을수있다.
                if (state.edit.module_type === state.editQuestion.__typename.replace('Module', '')) {
                    classModulesState.changeState(state.editQuestion)
                }

            }
        }
    }, [state])


    // 수정된 값이 있는지 체크 
    useEffect(() => {
        if (isEdit) dispatch({ type: ISEDITDO })
        else dispatch({ type: ISEDITINIT })
    }, [isEdit, dispatch])

    useEffect(() => {
        return () => dispatch({ type: ISEDITINIT })
    }, [dispatch])
    
    return (
        <>
        <div className='OuterPillar'>
            {
                useMemo(() => (
                    <SurveyCreateLeftComponent category={category} title={categoryTitle} onChange={onChangeCategory}>
                        {
                            // 편집화면에서는 메뉴 안보여준다.
                            !editOpen && (
                                <Typography variant="body2">{f({id: `container.Survey.Create.ModuleRoot.currentPage` }, {currentPage})}</Typography>
                            )
                        }
                        
                    </SurveyCreateLeftComponent>
                ), [
                    f,
                    currentPage,
                    categoryTitle,
                    editOpen,
                    category,
                    onChangeCategory
                ])
            }
        </div>
        <div className='OuterPillar'>
            {!editOpen && <SurveyCreateModuleMethodComponent method={mbox.method} refetchMbox={refetchMbox}/>}
            <SurveyCreateComponent category={category} layout={layout}>
                {
                    useMemo(() => (
                        <ViewComponent 
                            _method={mbox.method}
                            _editOpen={editOpen}
                            _edit={state.edit} 
                            _pageMenu={pageMenu} 
                            _refetchQuestions={refetchQuestions} 
                            _refetchPages={props.refetchPages} 
                            _page={props.page} 
                            _pages={props.pages}
                        />
                    ), [mbox.method, state.edit, editOpen, pageMenu, props.page, props.pages, refetchQuestions, props.refetchPages])
                }
                <div className='Pillar'> 
                    <div style={styleMulti}>
                        <EditSurveyContext.Provider 
                            value={{
                                viewPicture: (styleMulti.display === 'block'), 
                                editOpen, 
                                edit: state.edit,
                                onOpenEdit
                            }}>
                            <SurveyCreateModuleContainer scrollerObj={scrollerObj} viewType={state.viewType} focus={state.focus} editQuestion={state.editQuestion} {...props}/>   
                        </EditSurveyContext.Provider>
                    </div>
                    <div style={styleOne}>
                        {
                            state.viewType === 'one'  && (
                                <EditSurveyContext.Provider value={{viewPicture: (styleOne.display === 'block')}}>
                                    <SurveyCreateModuleOneContainer focus={state.focus} editQuestion={state.editQuestion} {...props} currentSurveyQuestionNo={state.edit.survey_question_no}/>   
                                </EditSurveyContext.Provider>
                            )
                        }
                    </div>
                </div>
                <SurveyCreateModuleMethodViewComponent 
                    viewType={state.viewType} 
                    hidden={!editOpen} 
                    onChangeMethodView={onChangeMethodView}
                    onChangeFocusEdit={onChangeFocusEdit}
                />
                <>
                {
                    useMemo(() => (
                        // 편집화면에서는 메뉴 안보여준다.
                        !editOpen && (
                            <SurveyCreateMenuContainer
                                method={mbox.method}
                                isHeaderBar={true}
                                scrollerObj={scrollerObj}
                                pages={props.pages} 
                                page={props.page} 
                                refetchQuestions={refetchQuestions} 
                                refetchPages={props.refetchPages} 
                                pageMenu={pageMenu} 
                                onOpenPageLists={onOpenPageLists} 
                                onOpenPageMove={onOpenPageMove} 
                                onClosePage={onClosePage} 
                                onChangeCategory={onChangeCategory}
                                goPage={goPage}
                            />
                        )
                    ), [
                        mbox.method,
                        editOpen,
                        props.pages, 
                        props.page, 
                        onChangeCategory, 
                        onOpenPageLists,
                        onOpenPageMove,
                        onClosePage,
                        refetchQuestions,
                        props.refetchPages,
                        pageMenu,
                        goPage
                    ])
                }
               </>
            </SurveyCreateComponent>
        </div>
        {
            isEdit ? <BeforeUnload/> : null
        }
        </>
    )
}

export default withSurveyFetchMbox(mode)()(withSurveyFetchQuestions(mode)(withSurveyFetchData('edit')({surveyQuestionsModuleSample: true, _fetchPolicy: { questionsModuleSample: 'cache-first' }})(false)(Component)))