/* eslint-disable react-hooks/exhaustive-deps */
import { memo } from 'react'
import { SurveyCreateModuleQuestionComponent } from 'component'
import { isEqual } from 'lodash-es'

const Component = (props) => {
    const { questions, target, scrollerObj, mbox, replys, onConfirmRemove, onRemove, onOpenCopy, onOpenAppend } = props

    return (
        <>  
        {
            questions.map((c, i) => {         
                const reply = replys.filter(reply => reply.survey_question_no === c._question.survey_question_no)
                return (
                    <SurveyCreateModuleQuestionComponent 
                        key={c._question.survey_question_no}  
                        target={target} 
                        scrollerObj={scrollerObj}
                        mbox={mbox}
                        reply={reply} 
                        {...c} 
                        index={i} 
                        onConfirmRemove={onConfirmRemove}
                        onRemove={onRemove}
                        onOpenCopy={onOpenCopy}
                        onOpenAppend={onOpenAppend}
                    />
                )
            
            })
        }
        </>
    )
}

export default memo(Component, (prev, next) => {
    return  isEqual(prev.questions, next.questions) &&
            isEqual(prev.target, next.target) &&
            isEqual(prev.scrollerObj, next.scrollerObj) &&
            isEqual(prev.mbox, next.mbox) &&
            isEqual(prev.replys, next.replys) &&
            isEqual(prev.onConfirmRemove, next.onConfirmRemove) &&
            isEqual(prev.onRemove, next.onRemove) &&
            isEqual(prev.onOpenCopy, next.onOpenCopy) &&
            isEqual(prev.onOpenAppend, next.onOpenAppend)
})