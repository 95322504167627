import { useMemo } from 'react'
import { SurveyCreateLeftComponent, SurveyCreateComponent} from 'component'
import { SurveyCreateSettingContainer, SurveyCreateModuleContainer} from 'container'
import { withSurveyFetchMbox, withSurveyFetchQuestions } from 'hoc'
import EditSurveyContext from 'context/editSurvey'
import { scrollerObj as _scrollerObj } from 'utils/survey'

const scrollerObj = _scrollerObj()

const Component = (props) => {
    const { category, onChangeCategory } = props
    
    return (
        <>
        <div className='OuterPillar'>
            {
                useMemo(() => (
                    <SurveyCreateLeftComponent category={category} onChange={onChangeCategory}/>
                ), [category, onChangeCategory])
            }
        </div>
        <div className='OuterPillar'>
        <SurveyCreateComponent category={category}>
            <div className="Pillar">
                <SurveyCreateSettingContainer {...props} refetchMbox={props.refetchMbox}/> 
            </div>
            <div className="Pillar">
                <EditSurveyContext.Provider value={{editOpen: true}}>
                    <SurveyCreateModuleContainer scrollerObj={scrollerObj} {...props} viewType="multi"/>   
                </EditSurveyContext.Provider>
            </div>
        </SurveyCreateComponent> 
        </div>
        </>
    )
}

export default withSurveyFetchMbox('edit')()(withSurveyFetchQuestions('edit')(Component))