import { createContext, ReactNode, useEffect, memo } from 'react'
import { useDispatch } from 'react-redux'
import { SurveyEndingProps } from 'gql/survey_ending'
import { INIT } from 'reducer/survey/create/logic/jumpEnding'
import { withSurveyFetchData } from 'hoc'

interface Props {
    propsFetchData: {
        endings: SurveyEndingProps[];
        refetchEndings: () => void | undefined;
    },
    children: ReactNode
}

interface EndingContextProps {
    endings: SurveyEndingProps[];
} 

interface EndingEventContextProps {
    refetchEndings: () => void;
} 

export const EndingContext = createContext<EndingContextProps>({
    endings: []
})

export const EndingEventContext = createContext<EndingEventContextProps>({
    refetchEndings: () => {}
})

function EndingContextContainer(props: Props) {
    const { propsFetchData, children } = props
    const { endings, refetchEndings } = propsFetchData

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch({
            type: INIT,
            O_INIT: {
                rows: endings
            }
        })
    }, [ endings, dispatch ])

    return (
        <EndingContext.Provider value={{ endings }}>
            <EndingEventContext.Provider value={{ refetchEndings }}>
                {children}
            </EndingEventContext.Provider>
        </EndingContext.Provider>
    )
}

export default withSurveyFetchData('preview')({
    endings: true
})(false)(memo(EndingContextContainer))