import { ImageContainer } from 'container'
import { withBreakPoints } from 'hoc'

function getStyles(props) {
    const { sm, xs } = props

    let height = 'calc(100vh - 30em)'
    let cols = 2

    if (xs) {
        height = 'calc(100vh - 31em)'
    } else if (sm) {
        cols = 3
    }

    return { height, cols }
}

function Component(props) {
    const { breakpoints, onClose, onAppendUrl, onChange } = props

    const { md, sm, xs } = breakpoints

    const { height, cols } = getStyles({ md, sm, xs })
    return (
        <ImageContainer height={height} cols={cols} onClose={onClose} onAppendUrl={onAppendUrl} onChange={onChange}/>
    )
}

export default withBreakPoints(Component)

