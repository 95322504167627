import { useSelector } from 'react-redux'
import { RootState } from 'reducer'
import { SurveyAnalysisSummaryVariables, ViewMode } from 'gql/survey_analysis_summary'
import { SurveyMboxProps } from 'gql/survey_mbox'
import { QuestionOption } from "component/Survey/Create/NewAnalysis/Filter/Component"
import EditDataContainer from './EditData'
import LiveDataContainer from './LiveData'

export interface Props {
    mbox: SurveyMboxProps;
    design: {
        font_family: string;
        question: string;
        background_color: string;
        bar: string;
    }
    dialog: boolean;
    survey_no: SurveyAnalysisSummaryVariables['survey_no'];
    print: boolean;
    viewMode: SurveyAnalysisSummaryVariables['input']['viewMode']; 
    step: SurveyAnalysisSummaryVariables['input']['step']; 
    date: SurveyAnalysisSummaryVariables['input']['date'];
    conditionsPlay: QuestionOption | null;
}


export type Input = {
    print: Props['print']; 
    viewMode: Props['viewMode'];
    step: Props['step'];
    date: Props['date'];
    search: string;
    conditionsPlay: QuestionOption | null;
    target_survey_analysis_users_no: number | '';
}

function Container(props: Props) {
    const { mbox, design, dialog, survey_no, print, viewMode, step, date, conditionsPlay } = props
    // 나의결과보기에대한 번호  
    const { survey_analysis_users_no: target_survey_analysis_users_no } = useSelector((state: RootState) => state.analysisSummaryTargetUser)

    if (viewMode !== 'report') {
        if (conditionsPlay === null) return null
    }

    const input = {
        print, 
        viewMode, 
        step,
        date,
        search: '', 
        conditionsPlay,
        target_survey_analysis_users_no
    }

    return (
        <>
        {
            viewMode === ViewMode.Edit ? (
                <EditDataContainer
                    mbox={mbox}
                    design={design}
                    dialog={dialog}
                    survey_no={survey_no}
                    input={input}
                />
            ) : (
                <LiveDataContainer
                    mbox={mbox}
                    design={design}
                    dialog={dialog}
                    survey_no={survey_no}
                    input={input}
                />
            )
        }
        </>
    )

}

export default Container