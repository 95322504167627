import { useState, useEffect, useCallback, useRef } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { SHOW as ALERTSNACKBARSHOW } from 'reducer/alertSnackbar'
import PropsContext from 'context/props'
import { validation } from 'utils'
import { getImgPosition } from 'utils/survey'
import { UPDATE_SURVEY_START } from 'gql/survey_start'
import { UPDATE_SURVEY_ENDING } from 'gql/survey_ending'
import { ImageUploadStyled } from 'styled/Survey/Edit/ImageExample'
import { SurveyCreateThanksEditComponent, SurveyEditTopComponent } from 'component'
import {
    ImageContainer,
    SurveyCreateThanksEditStartContainer, 
    SurveyCreateThanksEditEndingContainer,
    SurveyEditImageAttachContainer
} from 'container'

const initialState = {
    tabIndex: 0,
    subTabIndex: 0,
    rows: {},
    show: 'contents', // contents, image
    init: false // 초기회시 component unmount 실행
}

let timer = null 

// LEFT 메뉴 스크롤
export const thanksScrolls = {
    id: 'scrollThanksId',
    to: 'scrollThanksTo',
    bottom: 'scrollThanksBottom'
}


const Component = (props) => {
    const { category, survey_no, data, loading, onEdit, onChangeInit, onChangeCategory } = props

    const { formatMessage: f } = useIntl()

    const [ tabIndex, setTabIndex ] = useState(initialState.tabIndex)

    // 완료글, 웹사이트 이동 / 결과보기 버튼 탭
    const [ subTabIndex, setSubTabIndex ] = useState(initialState.subTabIndex)
  
    const [ rows, setRows ] = useState(data)

    const [ show, setShow ] = useState(initialState.show)

    const [ init, setInit ]  = useState(initialState.init)

    const dispatch = useDispatch()

    const refMessage = useRef()
    const refButtonName = useRef()
    const refButtonLink = useRef()
    const refAnalysisName = useRef()

    const [ updateSurveyStart ] = useMutation(UPDATE_SURVEY_START, {
        onCompleted: () => {
            dispatch({ 
                type: ALERTSNACKBARSHOW, 
                variant: 'success',
                duration: 1000,
                message: f({id: 'container.Survey.Create.Thanks.Edit.save.start'}) 
            })
        },
        onError: () => {
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [ updateSurveyEnding ] = useMutation(UPDATE_SURVEY_ENDING, {
        onCompleted: () => {
            dispatch({ 
                type: ALERTSNACKBARSHOW, 
                variant: 'success', 
                duration: 1000,
                message: f({id: 'container.Survey.Create.Thanks.Edit.save.end'}) 
            })
        },
        onError: () => {
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        }
    })
    
    const changeTab = useCallback((index) => setTabIndex(index), [])

    const openImage = useCallback(() => setShow('image'), [])

    const handleOpenBranding = useCallback(() => setShow('branding'), [])

    const handleCloseBranding = useCallback(() => setShow('contents'), [])

    const removeImage = useCallback(() => {
        setRows(prevState => ({...prevState, img_src: '', img_position: 'stack'}))
    }, [])

    const changeUsed = useCallback((e) => {
        const used = e.target.checked
        setRows(prevState => ({...prevState, used}))
    }, [])

    const changeAlign = useCallback((align) => {
        setRows(prevState => ({...prevState, align}))
    }, [])

    const changeAgree = useCallback((e) => {
        const { checked: agree } = e.target
        setRows(prevState => ({...prevState, agree }))
    }, [])

    const changeAgreeIconType = useCallback((agree_icon_type) => {
        setRows(prevState => ({...prevState, agree_icon_type}))
    }, [])

    const handleChange = useCallback((type, value) => {
        setRows(prevState => ({...prevState, [type]: value}))
    }, [])

    const onClose = useCallback(() => {
        onChangeCategory('module')
    }, [onChangeCategory])

    const save = {
        'thanks-start': async() => {
            const message = refMessage.current.value
            const button_name = refButtonName.current.value
            const { img_src, img_position, img_name, used, align, agree, agree_icon_type } = rows

            const input = {
                survey_no,
                message,
                button_name,
                img_src,
                img_position,
                img_name,
                used, 
                align,
                agree,
                agree_icon_type
            }

            try {
                await dispatch({ type: BACKDROPSHOW })
                await updateSurveyStart({variables: { input }})
               
            } catch(e) {
                throw e
            } finally {
                await dispatch({ type: BACKDROPHIDE })
                onClose()
            }
        },
        'thanks-end': async() => {
            const message = refMessage.current.value
       
            const { 
                img_src, 
                img_position, 
                img_name,
                used, 
                align, 
                sns, 
                button_name,
                button_link,
                analysis_name,
                branding, 
                branding_src, 
                branding_size, 
                branding_link 
            } = rows

            if (button_name) {
                if (button_link && !validation.url(button_link)) {
                    changeTab(0)

                    setSubTabIndex(0)

                    dispatch({ 
                        type: ALERTSNACKBARSHOW, 
                        variant: 'error', 
                        message: f({id: 'container.Survey.Create.Thanks.Edit.buttonLink'})
                    })
    
                    return
                }
            }
            
            if (branding) {
                if (branding_src) {
                    if (branding_link && !validation.url(branding_link)) {
                        changeTab(1)

                        dispatch({ 
                            type: ALERTSNACKBARSHOW, 
                            variant: 'error', 
                            message: f({id: 'container.Survey.Create.Thanks.Edit.buttonLink'})
                        })
        
                        return
                    }
                }
            }

            const input = {
                survey_no,
                survey_ending_no: data.survey_ending_no,
                message,
                button_name,
                button_link: button_name ? button_link : '',
                img_src,
                img_position, 
                img_name,
                align,
                used,
                analysis_name,
                sns,        
                branding,
                branding_src,
                branding_size,
                branding_link: branding_src ? branding_link : '' 
            }

            try {
                await dispatch({ type: BACKDROPSHOW })
                await updateSurveyEnding({variables: { input }})
               
            } catch(e) {
                throw e
            } finally {
                dispatch({ type: BACKDROPHIDE })
            }

            onClose()
        }
    }

    // 처음값 초기화
    const changeInit = useCallback(async() => {
        await setRows(data)
        await setInit(true)

        // 화면단도 초기화
        onChangeInit(category)
        
    }, [data, category, onChangeInit])

    const on = {
        changeTab,
        openImage,
        removeImage,
        changeUsed,
        changeAlign,
        changeAgree,
        changeAgreeIconType,
        save,
        changeInit
    }

    // 완료글 - 웹사이트로 이동 / 결과보기 탭
    const handleChangeSubTabIndex = useCallback((index) => {
        setSubTabIndex(index)
    }, [])

    const appendImage = useCallback((props) => {
        const { img_src, img_poster, img_name } = props
    
        setRows(prevState => ({
            ...prevState, 
            img_src, 
            img_poster,
            img_position: getImgPosition({ img_position: prevState.img_position, img_src }),
            img_name
        }))
    }, [])

    const changePositionImage = useCallback((e) => setRows(prevState => ({...prevState, img_position: e.currentTarget.value})), [])

    const closeImage = useCallback(() => setShow(initialState.show), [])

    const onImage = {
        append: appendImage,
        changePosition: changePositionImage,
        close: closeImage
    }


    // 처음 들어모면 rows setting
    useEffect(() => {   
        setRows(data)
    }, [data])


    // rows 셋팅되면 화면단 뿌려준다
    useEffect(() => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            onEdit.changeState(rows)
        }, 1)
    }, [rows, onEdit])


    useEffect(() => {
        if (init) setInit(false)
    }, [init])

    useEffect(() => {
        if (loading) dispatch({ type: BACKDROPSHOW })
        else dispatch({ type: BACKDROPHIDE })
    }, [loading, dispatch])

    // 초기화시 컴퍼넌트 초기화를 위해 null처리한다
    if (init) return null

    return (
        <>
        <PropsContext.Provider value={{ 
            category, 
            show, 
            rows, 
            tabIndex, 
            subTabIndex,
            refMessage, 
            refButtonName, 
            refButtonLink, 
            refAnalysisName, 
            on, 
            onEdit, 
            onClose,
            onOpenBranding: handleOpenBranding,
            onChange: handleChange,
            onChangeSubTabIndex: handleChangeSubTabIndex
        }}>
            <SurveyCreateThanksEditComponent scrolls={thanksScrolls} showType="contents">
                {                    
                    category === 'thanks-start' ? <SurveyCreateThanksEditStartContainer/> : <SurveyCreateThanksEditEndingContainer/>
                }
                        <SurveyEditTopComponent scrolls={thanksScrolls}/>
            </SurveyCreateThanksEditComponent>
            <SurveyCreateThanksEditComponent  scrolls={thanksScrolls} showType="image">
                {                    
                    show === 'image' && (
                        <SurveyEditImageAttachContainer
                            onlyImage={false}
                            img_src={rows.img_src} 
                            img_name={rows.img_name} 
                            show={show} 
                            position={rows.img_position} 
                            on={onImage}
                        />
                    )
                }
                        <SurveyEditTopComponent scrolls={thanksScrolls}/>
            </SurveyCreateThanksEditComponent>
            <SurveyCreateThanksEditComponent  scrolls={thanksScrolls} showType="branding">
                {                    
                    show === 'branding' && (
                        <ImageUploadStyled className="show">
                            <ImageContainer 
                                layout="vertical" 
                                onClose={handleCloseBranding} 
                                onAppendUrl={({ img_src }) => handleChange('branding_src', img_src)} 
                                onChange={({ img_src }) => handleChange('branding_src', img_src)}
                            />
                        </ImageUploadStyled>
                    )
                }
                        <SurveyEditTopComponent scrolls={thanksScrolls}/>
            </SurveyCreateThanksEditComponent>
        </PropsContext.Provider>

        </>
    )
}


export default Component