import { useState, useContext, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useIntl } from 'react-intl'
import EditDesignContext from 'context/editDesign'
import { GET_SURVEY_DESIGNS_ALL, CREATE_SURVEY_DESIGNS_COPY, DELETE_SURVEY_DESIGNS } from 'gql/survey_designs'
import { UPDATE_SURVEY_MBOX_DESIGN } from 'gql/survey_mbox_design'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { SHOW as POPOVERSHOW, HIDE as POPOVERHIDE } from 'reducer/popover'
import { SHOW as ALERTSNACKBARSHOW } from 'reducer/alertSnackbar'
import { withSurveyDefaultDesign } from 'hoc'
import { SurveyCreateDesignMyEditPopComponent } from 'component'
import { SurveyCreateDesignCustomContainer } from 'container'
import DesignComponent from 'component/Survey/Create/Design/Component'

const initialState = {
    edit: {
        open: false,
        survey_designs_no: '',
        template: false
    },
    rows: []
}

const Component = (props) => {
    const { propsSurveyDefaultDesign } = props
    const { refetchMboxDesign } = useContext(EditDesignContext)

    const { formatMessage: f } = useIntl()

    const { design: defaultDesign } = propsSurveyDefaultDesign

    const dispatch = useDispatch()
    const params = useParams()

    const survey_no = Number(params.survey_no)

    

    const [ edit, setEdit ] = useState(initialState.edit)
    const [ rows, setRows ] = useState(initialState.rows)

    const { refetch } = useQuery(GET_SURVEY_DESIGNS_ALL, {
        variables: { 
            survey_no
        },
        onCompleted: (data) => {
            setRows([defaultDesign, ...data.surveyDesignsAll])
            dispatch({ type: BACKDROPHIDE })
        },
        onError: (e) => {
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    })

    const [ updateSurveyMboxDesign ] = useMutation(UPDATE_SURVEY_MBOX_DESIGN, {
        onCompleted: async (data) => {
            await refetchMboxDesign()
            dispatch({ type: BACKDROPHIDE })
            dispatch({ 
                type: ALERTSNACKBARSHOW, 
                variant: 'success', 
                duration: 1000,
                message: f({id: 'container.Survey.Create.Design.My.apply.success'})
            })
        },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [ createSurveyDesignsCopy ] = useMutation(CREATE_SURVEY_DESIGNS_COPY, {
        onCompleted: async (data) => {        
            dispatch({ type: BACKDROPHIDE })

            // 복사는 나의 테마에서만 되기때문에 template은 false 이다.
            setEdit({open: true, survey_designs_no: data.createSurveyDesignsCopy, template: false})

        },
        onError: () => {
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        }
    })


    const [ deleteSurveyDesigns ] = useMutation(DELETE_SURVEY_DESIGNS, {
        onCompleted: async (data) => {
            refetch()
        },
        onError: () => {
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const handleEditPopApply = useCallback((props) => {
        const {
            numbering,
            line_color,
            background_size,
            background_opacity,
            background_color,
            background_image, 
            background_filter,
            question,
            font_family,
            answer, 
            answer_button, 
            answer_sbutton
        } = props

        dispatch({ type: BACKDROPSHOW })

        dispatch({ type: POPOVERHIDE })

        const input = {
            survey_no,
            numbering,
            question,
            answer,
            answer_button,
            answer_sbutton,
            background_color,
            line_color,
            background_image,
            background_filter,
            background_size,
            background_opacity,
            font_family
        }

        updateSurveyMboxDesign({variables: { input }})
    }, [survey_no, dispatch, updateSurveyMboxDesign])

    const handleEditPopEdit = useCallback((survey_designs_no, template) => {
        dispatch({ type: POPOVERHIDE })
        setEdit({open: true, survey_designs_no, template})
    }, [dispatch]) 

    const handleEditPopCopy = useCallback((survey_designs_no) => {
        dispatch({ type: BACKDROPSHOW })
        dispatch({ type: POPOVERHIDE })
        createSurveyDesignsCopy({ variables: { survey_no, survey_designs_no } })
    }, [survey_no, dispatch, createSurveyDesignsCopy]) 

    const handleEditPopRemove = useCallback((survey_designs_no) => {
        dispatch({ type: BACKDROPSHOW })
        dispatch({ type: POPOVERHIDE })
        deleteSurveyDesigns({ variables: { survey_no, survey_designs_no } })
    }, [survey_no, dispatch, deleteSurveyDesigns]) 

    const handleOpenEdit = useCallback(() => {
        setEdit({open: true, survey_designs_no: '', template: false })
    }, [])

    const handleCloseEdit = useCallback(async(template) => {
        await Promise.all([
            refetch(),
            setEdit({
                ...initialState.edit,
                template
            })
        ])
        refetchMboxDesign()
    }, [refetch, refetchMboxDesign])

    const handleOpenEditPop = useCallback((e, survey_designs_no, template) => {
        dispatch({ 
            type: POPOVERSHOW, 
            component: <SurveyCreateDesignMyEditPopComponent 
                            survey_designs_no={survey_designs_no}
                            template={template}
                            onEdit={handleEditPopEdit}
                            onCopy={handleEditPopCopy}
                            onRemove={handleEditPopRemove}
                        />,
            anchorEl: e.currentTarget,
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center'
            },
            transformOrigin: {
                vertical: 'top',
                horizontal: 'center'
            },
            isClose: false
        })
    }, [dispatch, handleEditPopEdit, handleEditPopCopy, handleEditPopRemove])

    return (
        <>
        {
            edit.open ? (
                <SurveyCreateDesignCustomContainer survey_designs_no={edit.survey_designs_no} template={edit.template} onClose={handleCloseEdit}/> 
            ) : (
                <DesignComponent survey_no={survey_no} rows={rows} template={edit.template} onOpen={handleOpenEdit} onOpenPop={handleOpenEditPop} onApply={handleEditPopApply}/>
            )
        }
        </>
    )
}

export default withSurveyDefaultDesign(Component)