import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { SHOW as ANSWERERRORPAGESHOW } from 'reducer/answerErrorPage'
import { useIntl } from 'react-intl'
import HeadTag from 'styled/HeadTag'
import { Step, ViewMode } from 'gql/survey_analysis_summary'
import SurveyCreateAnalysisSummaryReportComponent from 'component/Survey/Create/NewAnalysis/Summary/Report'
import SurveyCreateAnalysisSummaryTitleComponent from 'component/Survey/Create/NewAnalysis/Summary/Title'
import OrderShareComponent from 'component/Survey/Create/NewAnalysis/Summary/OrderShare'
import Container from './Container'

// 임의로 배열값준다 ( 디자인 edit 에서는 쓰이지 않는다)
const conditionsPlay = []

const Component = (props) => {
    const { survey_no, propsFetchData } = props
    const { mbox, surveyMboxAnalysisDesign } = propsFetchData

    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    useEffect(() => {
        if (!surveyMboxAnalysisDesign.open) {
            dispatch({ type: ANSWERERRORPAGESHOW, message: f({id: 'container.Survey.Create.Analysis.Summary.Report.open'}), f })
    
        }
    }, [surveyMboxAnalysisDesign.open, dispatch, f])

    return (
        <>
        <HeadTag type="analysis" title={mbox.title} url={window.location.href}/>
        {
            surveyMboxAnalysisDesign.open && (
                <SurveyCreateAnalysisSummaryReportComponent design={surveyMboxAnalysisDesign}>
                    <SurveyCreateAnalysisSummaryTitleComponent 
                        survey_no={survey_no} 
                        design={surveyMboxAnalysisDesign} 
                        title={mbox.title} 
                        surveyArgs={mbox.args} 
                    />
                    <OrderShareComponent survey_no={survey_no} design={surveyMboxAnalysisDesign} />
                    <Container 
                        mbox={mbox}
                        dialog={false}
                        conditionsPlay={conditionsPlay}
                        design={surveyMboxAnalysisDesign}
                        survey_no={survey_no}
                        step={Step.End}
                        date=""
                        search="" 
                        viewMode={ViewMode.Report}
                        print={false}
                    />
                </SurveyCreateAnalysisSummaryReportComponent>
            )
        }
        </>
    )
}

export default Component