import { useState, useMemo, useCallback } from 'react'
import { SurveyCreateComponent, SurveyCreateLeftComponent } from 'component'
import { SurveyCreateModuleContainer, SurveyCreateDesignMyContainer } from 'container'
import { withSurveyFetchMbox, withSurveyFetchQuestions } from 'hoc'
import EditSurveyContext from 'context/editSurvey'
import EditDesignContext from 'context/editDesign'
import { scrollerObj as _scrollerObj } from 'utils/survey'

const initialState = {
    editDesign: ''
}

const scrollerObj = _scrollerObj()

const Component = (props) => {
    const { category, onChangeCategory, design, refetchMboxDesign } = props
    
    const [ editDesign, setEditDesign ] = useState(initialState.editDesign)

    // 실시간 변경 이벤트
    const handleChange = useCallback((props) => setEditDesign(props), [])

    return (
        <>
        <div className='OuterPillar'>
            {
                useMemo(() => (
                    <SurveyCreateLeftComponent category={category} onChange={onChangeCategory}/>
                ), [category, onChangeCategory])
            }
        </div>
        <div className='OuterPillar'>
            <SurveyCreateComponent category={category}>
                <EditDesignContext.Provider value={{onChange: handleChange, refetchMboxDesign, originDesign: design}}>
                    <div className='Pillar'>
                        <SurveyCreateDesignMyContainer/>
                    </div>
                </EditDesignContext.Provider>
                <EditSurveyContext.Provider value={{viewPicture: true, editOpen: true, customDesign: true}}> 
                    <div className='Pillar'>
                        <SurveyCreateModuleContainer scrollerObj={scrollerObj} editDesign={editDesign} {...props} viewType="multi"/>
                    </div>
                </EditSurveyContext.Provider>
            </SurveyCreateComponent> 
        </div>
        </>
    )
}

export default withSurveyFetchMbox('edit')()(withSurveyFetchQuestions('edit')(Component))