import { memo, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { useDispatch } from 'react-redux'
import { useLazyQuery } from '@apollo/client'
import { useIntl } from 'react-intl'
import queryString from 'query-string'
import randomString from 'randomstring'
import JSZip from 'jszip'
import FileSaver from 'file-saver'
import { parseGraphqlError } from 'utils'
import { SHOW as ERRORPAGESHOW } from 'reducer/answerErrorPage'
import { GET_SURVEY_ANALYSIS_MODULE19_INFO } from 'gql/survey_analysis_modules'
import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import GetAppIcon from '@material-ui/icons/GetApp'
import ErrorIcon from '@material-ui/icons/ErrorOutline'
import { withStyles } from '@material-ui/core/styles'

const MyPaper = withStyles({
    root: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        padding: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    }
})(Paper)

// 값이 정상적인지 체크
const isValid = (props: { survey_question_no?: string, survey_analysis_users_no?: string, survey_no?: string }) => {
    const { survey_question_no, survey_analysis_users_no, survey_no } = props

    if (!survey_question_no || !survey_analysis_users_no || !survey_no) return false

    if (Number.isNaN(Number(survey_question_no)) || Number.isNaN(Number(survey_analysis_users_no)) || Number.isNaN(Number(survey_no))) return false
    
    return true
}

// key 스트링으로 만들기
const makeKey = (props: { survey_question_no?: string, survey_analysis_users_no?: string, survey_no?: string }) => {
    const { survey_question_no, survey_analysis_users_no, survey_no } = props

    return `${survey_question_no}.${survey_analysis_users_no}.${survey_no}`
}

const mode = 'live'

// 접속시 다운로드 하는 변하지 않는 랜덤 키값
const random = randomString.generate(3)

let timer: ReturnType<typeof setTimeout>

// 다운로드 파일 전역변수
let Src = ''
let Filename = ''

const handleSaveFile = async(props: { src: string, filename: string }) => {
    const { src, filename } = props
            
    const zip = new JSZip()

    try {
        const blob = await fetch(src).then(response => {
            if (response.status !== 200) return null
            return response.blob()
        })

        if (blob) {
            zip.file(filename, blob)

            const data = await zip.file(filename).async('blob')
            
            FileSaver.saveAs(data, filename)

            return true
        }

        return false
    } catch (e) {
        return false
    }
}

function FileDownloadContainer() {
    const { formatMessage: f } = useIntl()

    const dispatch = useDispatch()

    // 다운로드 클릭했으면 10초정도 기다려야한다.
    const [ action, setAction ] = useState(false)

    const [ _, setRandom ] = useState(0)

    const [ getData ] = useLazyQuery(GET_SURVEY_ANALYSIS_MODULE19_INFO, {
        onCompleted: async(data) => {
            const { src, filename } = data.surveyAnalysisModule19Info

            Src = src
            Filename = filename

            const res = await handleSaveFile({ src, filename })
    
            // 다운로드가 안됐다면..전역변수 초기화
            if (!res) {
                Src = ''
                Filename = ''
            }

            clearTimeout(timer)
            timer = setTimeout(() => {
                setAction(false)
            }, 500)
        },
        onError: (e) => {
            const msg = parseGraphqlError(e)

            if (msg === 'Not Authenticated' || msg === 'Bad Params') setRandom(Math.random())
            else if (msg === 'Nothing') dispatch({ type: ERRORPAGESHOW, message: f({id: 'container.Survey.Create.Analysis.Rows.FileDownload.error.0'}), logoHref: '/', buttonHref: '/' })
            else if (msg === 'Different Authenticated') dispatch({ type: ERRORPAGESHOW, message: f({id: 'container.Survey.Create.Analysis.Rows.FileDownload.error.1'}), logoHref: '/', buttonHref: '/' })
            else dispatch({ type: ERRORPAGESHOW })
        }
    })
    
    const location = useLocation()
    const search = queryString.parse(location.search)

    const handleSave = async() => {
        if (action) return
        setAction(true)

        // 기존에 지정된 파일이 있다면..
        if (Src && Filename) {
            const res = await handleSaveFile({ src: Src, filename: Filename })
            
            if (!res) {
                Src = ''
                Filename = ''
            }

            clearTimeout(timer)
            timer = setTimeout(() => {
                setAction(false)
            }, 500)
        } else {
            getData({
                variables: {
                    key: makeKey({ survey_question_no, survey_analysis_users_no, survey_no }),
                    mode,
                    random: randomString.generate(3)
                }
            })    
        }
    }

    const handleMoveHome = () => {
        const url = `${String(process.env.REACT_APP_LANDING)}?login=open&redirect_url=/file/download?key=${search.key}`
        window.location.href = url
    }

    const [ survey_question_no, survey_analysis_users_no, survey_no ] = String(search.key).split('.')

    useEffect(() => {
        // 정상적인 값이 있을때만 
        if (isValid({ survey_question_no, survey_analysis_users_no, survey_no })) {
            setAction(true)
            getData({
                variables: {
                    key: makeKey({ survey_question_no, survey_analysis_users_no, survey_no }),
                    mode,
                    random
                }
            })
        }
    }, [ survey_question_no, survey_analysis_users_no, survey_no, getData])


     // 정상적인 값인지 체크
     if (!isValid({ survey_question_no, survey_analysis_users_no, survey_no })) {
        return (
            <MyPaper elevation={0}>
                <ErrorIcon fontSize='large' color="secondary" style={{marginBottom: 10}}/>
                <Typography style={{textAlign: 'center'}}>
                    {f({id: 'container.Survey.Create.Analysis.Rows.FileDownload.msg.0'})}
                </Typography>
            </MyPaper>
        )
    }

    if (!localStorage.getItem('authorization')) {
        return (
            <MyPaper elevation={0}>
                <Button color='primary' variant="contained" size='large' style={{marginBottom: 10}} onClick={handleMoveHome}>
                    {f({id: 'container.Survey.Create.Analysis.Rows.FileDownload.button.home'})}
                </Button>
                <Typography style={{textAlign: 'center'}}>
                    {f({id: 'container.Survey.Create.Analysis.Rows.FileDownload.msg.1'})}
                </Typography>
            </MyPaper>
        )
    }

    return (
        <MyPaper elevation={0}>
            {
                action ? (
                    <CircularProgress style={{marginBottom: 10}}/> 
                ) : (
                    <IconButton disabled={action} onClick={handleSave}>
                        <GetAppIcon fontSize='large' color='primary'/>
                    </IconButton>
                )
            }
  
            <Typography style={{textAlign: 'center'}}>
                {f({id: 'container.Survey.Create.Analysis.Rows.FileDownload.msg.2'})}
            </Typography>
        </MyPaper>
    )
}

export default memo(FileDownloadContainer)