/**
 * 요약데이터 많을때 보여지는 안내메시지 (로그인한 고객 화면)
 */
import { useIntl } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import ErrorTwoToneIcon from '@material-ui/icons/ErrorTwoTone'
import { ViewMode } from 'gql/survey_analysis_summary'
import SurveyCreateAnalysisSummaryDataComponent from 'component/Survey/Create/NewAnalysis/Summary/Data'
import { MyPaper } from './ErrorUserMessage'

function ErrorUserMessage() {
    const { formatMessage: f } = useIntl()

    return (
        <SurveyCreateAnalysisSummaryDataComponent top={0} viewMode={ViewMode.Report} dialog={0} onTop={() => {}}>
            <MyPaper>
                <ErrorTwoToneIcon fontSize='large' color='secondary' style={{marginBottom: 10}}/>
                <Typography>
                    {f({id: 'container.Survey.Create.Analysis.Summary.ErrorPannelMessage.content'})}
                </Typography>
            </MyPaper>
        </SurveyCreateAnalysisSummaryDataComponent>
    )
}

export default ErrorUserMessage