import { useEffect, useReducer } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getSearchCookie, setSearchCookie } from 'ts-utils/summary'
import { ViewMode } from 'gql/survey_analysis_summary'
import SurveyCreateAnalysisInsightComponent from 'component/Survey/Create/NewAnalysis/Insight/Component'
import { withSurveyFetchData } from 'hoc'
import useInsightData from 'hooks/useInsightData'
import usePageViewData from 'hooks/usePageViewData'
import LinerProgressComponent from './LinerProgress'

const initialState = {
    pageViewOrder: {
        order: 'indexs',
        orderBy: false
    },
    date: '',
    deviceType: 'all', // all browser tablet mobile
    openCalendar: false
}

const handleReducer = (state, action) => {
    switch (action.type) {
        case 'date': { // 상단 지수 디바이스 선택
            return { ...state, date: action.date }
        }
        case 'deviceType': { // 상단 지수 디바이스 선택
            return { ...state, deviceType: action.deviceType }
        }
        case 'pageViewOrder': { // 페이지뷰 정렬 설정
            return { ...state, pageViewOrder: action.pageViewOrder }
        }
        case 'openCalendar': { // 페이지뷰 기간설정 모달
            return { ...state, openCalendar: action.openCalendar }
        }
        default: {
            throw new Error(`unexpected action.type: ${action.type}`)
        }
    }
}

// 그래프 이미지 ID
const ids = [
    'chartDevice',
    'chartGroupPeriod',
    'chartGroupWeekDay',
    'chartGroupTime',
    'refererDomainList',
    'refererCityCount'
]

export function getDateCookieName(survey_no) {
    return `anaylsis-date-${survey_no}`
}

const Component = (props) => {

    const { propsFetchData } = props
    const { mbox, refetchMbox } = propsFetchData

    const { formatMessage: f } = useIntl()

    // 검색날짜 버튼 이름
    const dateCookieName = getDateCookieName(mbox.survey_no)
    // 디바이스 버튼 이름
    const deviceCookieName = `insight-device-${mbox.survey_no}`
    // 성공률 성공수
    const completeCookieName = `insight-complete-${mbox.survey_no}`
    // 성공률 성공수
    const refererCityCookieName = `insight-referer-city-${mbox.survey_no}`

    const dispatch = useDispatch()
    const params = useParams()

    const [ states, dispatchStates ] = useReducer(handleReducer, {
        ...initialState, 
        date: getSearchCookie(dateCookieName) || '',
        deviceType: getSearchCookie(deviceCookieName) || 'all'
    })

    const survey_no = Number(params.survey_no)
    
    const [ insightData, setInsightData, { loading: insightLoading, refetch: refetchInsight } ] = useInsightData({ survey_no })

    const [ 
        pageViewData, 
        setPageViewData, 
        { 
            refetch: refetchPageView ,
            loading: pageviewLoading
        } 
    ] = usePageViewData({ survey_no, method: mbox.method })

    const loading = Boolean(pageviewLoading || insightLoading)

    const handle = {
        changeDeviceType: (e) => {
            const { value: deviceType } = e.target
            setSearchCookie(deviceCookieName, deviceType)
            dispatchStates({type: 'deviceType', deviceType})
        },
        changeOrder: (order) => {
            const orderBy = order === states.pageViewOrder.order ? !states.pageViewOrder.orderBy : states.pageViewOrder.orderBy
            dispatchStates({type: 'pageViewOrder', pageViewOrder: {
                order,
                orderBy
            }})
        },
        openCalendar: () => dispatchStates({type: 'openCalendar', openCalendar: true}),
        closeCalendar: () => dispatchStates({type: 'openCalendar', openCalendar: false}),
        changeInitial: () => {
            dispatchStates({type: 'date', date: ''})
        },
        save: (value) => {            
            handle.closeCalendar()
            dispatchStates({type: 'date', date: value})
        }
    }

    // 인사이트 데이터 가져오기 날짜별
    useEffect(() => {
        setInsightData({ date: states.date, deviceType: states.deviceType })
    }, [setInsightData, states.date, states.deviceType])

    // 페이지 뷰 데이터 가져오기
    useEffect(() => {
        const { order, orderBy } = states.pageViewOrder

        setPageViewData({ date: states.date, order, orderBy, deviceType: states.deviceType })
        
    }, [states.pageViewOrder, states.date, states.deviceType, setPageViewData])

    return (
        <>
        {
            loading && <LinerProgressComponent viewMode={ViewMode.View} message={f({id: 'hoc.Survey.Insight.loadingMsg'})} />
        }
        <SurveyCreateAnalysisInsightComponent 
            survey_no={survey_no} 
            ids={ids}
            loading={Boolean(pageviewLoading || insightLoading)}
            insightData={insightData} 
            pageViewData={pageViewData}
            mbox={mbox}
            dateCookieName={dateCookieName}
            completeCookieName={completeCookieName}
            refererCityCookieName={refererCityCookieName}
            {...states} 
            on={handle} 
            refetchMbox={refetchMbox}
            refetchInsight={refetchInsight}
            refetchPageView={refetchPageView} 
        />
        </>
    )
}

export default withSurveyFetchData('preview')({mbox: true})(false)(Component)