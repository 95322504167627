import { memo, useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { INITIAL } from 'reducer/analysis/summary/targetUser'
import EditContainer from './Edit'
import Dialog from 'styled/Survey/Live/Dialog'

function EditPopComponent(props) {
    const { open, onClose } = props

    const dispatch = useDispatch()

    // dialog 펼쳐질때 SurveyCreateAnalysisSummaryEditContainer 동시에 마운트하면 좀 매끄러럽지가 않아서 살짝 딜레이줫다
    const [ _open, _setOpen ] = useState(false)

    const handleClose = useCallback(() => {
        dispatch({ type: INITIAL })
        onClose()
    }, [dispatch, onClose])

    useEffect(() => {
        setTimeout(() => {
            _setOpen(open)
        }, 1)
    }, [open])

    return (
        <Dialog open={open} onClose={handleClose}>
           {_open && <EditContainer onClose={handleClose}/>}
        </Dialog>
    )
}

export default memo(EditPopComponent)