// 모바일에서는 제작페이지가 반응형이 아니라 안내사항을 줘야한다 
// 최상단에서 처리하는게좋을거같디
import { useCallback, useState } from 'react'
import { useHistory, useParams } from "react-router-dom"
import PermitContainer from './Permit'
import RootContainer from './Root'
import { useIntl } from 'react-intl'
import oc from 'open-color-js'

const getLabelsObject = (f) => ([
    {
        key: 'create',
        text: f({id: 'container.Survey.Create.Container.steps.0'}),
        color: ''
    },
    {
        key: 'share',
        text: f({id: 'container.Survey.Create.Container.steps.1'}),
        color: oc.gray1
    },
    {
        key: 'analysis',
        text: f({id: 'container.Survey.Create.Container.steps.2'}),
        color: oc.gray1
    }
])

export default function DetectDevice(props) {
    const history = useHistory()
    const params = useParams()

    const survey_no = Number(params.survey_no)

    const { formatMessage: f } = useIntl()

    const [ state, setState ] = useState({ category: 'module' }) // module, thanks, design, logic, setting)

    // 상단 라벨 
    const labelsObject = getLabelsObject(f)

    // 제작페이지 카테고리
    const handleChangeCategory= useCallback((category, args={}) => {
        setState({
            category,
            ...args
        })
    }, [])


    // 제작, 배포, 결과 이동
    const handleChangeStep = useCallback((step) => {
        const { key } = labelsObject.find((c, i) => i === step)

        // create에서는 또 클릭하면 에러나서리..
        if (key === params.step && key === 'create') return 

        history.push(`/survey/form/${key}/${survey_no}`)

        if (key === 'create') {
            setState({ category: 'module' })
        }
    }, [survey_no, labelsObject, history, params.step])


    const step = params.step

    const activeStep = labelsObject.map(c => c.key).indexOf(step)

    const labels = labelsObject.map(c => c.text)

    const backgroundcolor = labelsObject[activeStep].color

    return (
        <PermitContainer>
            <RootContainer 
                containerState={state}
                step={step}
                activeStep={activeStep}
                labels={labels}
                backgroundcolor={backgroundcolor}
                onChangeCategory={handleChangeCategory}
                onChangeStep={handleChangeStep}
                {...props}
            />
        </PermitContainer>
       
    )
}