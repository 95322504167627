import { createContext, ReactNode, useEffect, memo } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { withSurveyFetchData, withSurveyFetchMboxWithRelation } from 'hoc'
import { HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { SHOW as ALERTSNACKBARSHOW } from 'reducer/alertSnackbar'
import { SurveyQuestionModuleProps } from 'gql/survey_question_modules'
import { SurveyPageProps } from 'gql/survey_page'
import { SurveyEndingProps } from 'gql/survey_ending'
import { SurveyStartProps } from 'gql/survey_start'
import { SurveyMboxProps, Method } from 'gql/survey_mbox'
import { getKeyOfArray } from 'ts-utils'

interface Props {
    warning?: boolean;
    mbox: SurveyMboxProps;
    start: SurveyStartProps;
    propsFetchData: {
        surveyQuestionsModuleAll: SurveyQuestionModuleProps[];
        pages: SurveyPageProps[];
        endings: SurveyEndingProps[];
        refetchSurveyQuestionsModuleAll: () => void;
    },
    children: ReactNode
}

export type SurveyQuestionModulePropsInKey = { [a: number]: SurveyQuestionModuleProps[] } | undefined

export interface ItemContextProps {
    questions: SurveyQuestionModuleProps[];
    questionsKeyPageNo: SurveyQuestionModulePropsInKey;
    questionsKeyQuestionNo: SurveyQuestionModulePropsInKey;
    pages: SurveyPageProps[];
    endings: SurveyEndingProps[];
    mbox: Pick<SurveyMboxProps, 'survey_no' | 'method'>;
    start: Pick<SurveyStartProps, 'message' | 'img_src' | 'used'>;
    refetchSurveyQuestionsModuleAll: () => void;
} 

export const Context = createContext<ItemContextProps>({
    questions: [],
    questionsKeyPageNo: undefined,
    questionsKeyQuestionNo: undefined,
    pages: [],
    endings: [],
    mbox: {
        survey_no: 0,
        method: Method.One
    },
    start: {
        message: '',
        img_src: '',
        used: false
    },
    refetchSurveyQuestionsModuleAll: () => {}
})

function ContextContainer(props: Props) {
    const { warning=true, mbox, start, propsFetchData, children } = props
    const { surveyQuestionsModuleAll, pages, endings, refetchSurveyQuestionsModuleAll } = propsFetchData

    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    const questionsKeyPageNo = getKeyOfArray('_question.survey_page_no', surveyQuestionsModuleAll)
    const questionsKeyQuestionNo = getKeyOfArray('_question.survey_question_no', surveyQuestionsModuleAll)

    useEffect(() => {
        if (warning) {
            dispatch({ 
                type: ALERTSNACKBARSHOW, 
                message: f({id: 'container.Survey.Create.Logic.Jump.Context.warning'}), 
                variant: 'warning',
                duration: 15000
            })
    
            setTimeout(() => {
                dispatch({ type: BACKDROPHIDE })
            }, 5000)
        }
    }, [warning, dispatch, f])

    return (
        <Context.Provider value={{ 
            questions: surveyQuestionsModuleAll,
            questionsKeyPageNo,
            questionsKeyQuestionNo,
            pages,
            endings,
            mbox: {
                survey_no: mbox.survey_no,
                method: mbox.method
            },
            start,
            refetchSurveyQuestionsModuleAll
        }}>
            {children}
        </Context.Provider>
    )
}

export default withSurveyFetchMboxWithRelation({
    mode: 'edit'
})(false)(withSurveyFetchData('preview')({
    surveyQuestionsModuleAll: true,
    pages: true,
    endings: true
})(true)(memo(ContextContainer)))