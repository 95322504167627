import { memo, useMemo } from "react"
import { useIntl } from "react-intl"
import { SurveyMboxDesignProps } from 'gql/survey_mbox_design'
import { SurveyMboxCoverProps } from 'gql/survey_mbox_cover'
import withSurveyFetchData from 'hoc/Survey/FetchData'
import { 
    SurveyCreateComponent, 
    SurveyCreateLeftComponent, 
    SurveyCreateBrandComponent
} from 'component'
import PreviewContainer from './Preivew'

export interface Props {
    survey_no: number;
    propsFetchData: {
        mboxDesign: SurveyMboxDesignProps;
        mboxCover: SurveyMboxCoverProps;
        refetchMboxCover?: () => void;
    };
    category: string;
    onChangeCategory: (a: string) => void;
}

type BrandScrollsProps = {
    id: string;
    to: string;
    bottom: string;
}

export const brandScrolls: BrandScrollsProps = {
    id: 'scrollBrandId',
    to: 'scrollBrandTo',
    bottom: 'scrollBrandBottom'
}

function BrandContainer(props: Props) {
    const { survey_no, propsFetchData, category, onChangeCategory } = props
    const { mboxDesign, mboxCover, refetchMboxCover } = propsFetchData

    const { used, src, animation, question_top_used, question_top_align } = mboxCover

    const { formatMessage: f } = useIntl()

    return (
        <>
        {
            useMemo(() => (
                <div className='OuterPillar'>
                    <SurveyCreateLeftComponent category={category} title={f({id: 'component.Survey.Create.Menu.Brand.label'})} onChange={onChangeCategory}/>
                </div>
            ), [f, category, onChangeCategory])
        }
         <div className='OuterPillar'>
            <SurveyCreateComponent category={category}>
                <SurveyCreateBrandComponent 
                    survey_no={survey_no}
                    used={used} 
                    src={src} 
                    animation={animation} 
                    question_top_used={question_top_used} 
                    question_top_align={question_top_align} 
                    onChangeCategory={onChangeCategory}
                    refetch={refetchMboxCover}
                />
                <div className="Pillar" style={{position: 'relative'}}>
                    <PreviewContainer brand={{ src, animation }} design={mboxDesign}/>
                </div>
            </SurveyCreateComponent>
        </div>
        </>
    )
}

const args = {
    mboxDesign: true,
    mboxCover: true
}
export default withSurveyFetchData('preview')(args)(false)(memo(BrandContainer))