/**
 * 로딩이 길므로... 라이너 프로그레스로 처리
 */

import { memo } from 'react'
import HtmlParser from 'react-html-parser'
import { ViewMode } from 'gql/survey_analysis_summary'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

interface Props {
    message?: string;
    viewMode: ViewMode;
}

const useStyles = makeStyles(theme => ({
    root: (props: { viewMode: ViewMode }) => {
        const { viewMode } = props
        return {
            position: 'fixed',
            background: 'rgba(245, 245, 245, .3)',
            left: 0,
            top: viewMode === ViewMode.View ? 117 : 0,
            right: 0,
            bottom: 0,
            zIndex: 1,
            [theme.breakpoints.down('xs')]: {
                top: viewMode === ViewMode.View ? 166 : 0,
            }
        }
        
    },
    liner: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        width: 300,
        transform: 'translate(-50%, -50%)',
        zIndex: 10
    },
    message: {
        position: 'absolute',
        left: '50%',
        top: '55%',
        transform: 'translate(-50%, -55%)',
        zIndex: 10,
        textAlign: 'center',
        color: 'balck',
        fontWeight: 900,
        [theme.breakpoints.down('xs')]: {
            top: '60%',
            transform: 'translate(-50%, -60%)',
        }
    }
}))

function LinearProgressComponent(props: Props) {
    const { viewMode, message } = props
    const classes = useStyles({ viewMode })

    return (
        <>
        <div className={classes.root} />
        <LinearProgress className={classes.liner} />
        {
            message && (
            <Typography variant="body2" className={classes.message} >
                {HtmlParser(message)}
            </Typography>        
            )
        }
        </>
    )
}

export default memo(LinearProgressComponent)