import withSurveyFetchData from 'hoc/Survey/FetchData'
import ThanksRootContainer from './ThanksRoot'

const Component = (props) => {
    const { category, onChangeCategory, propsFetchData } = props
    const { start, loadingStart } = propsFetchData

    return <ThanksRootContainer category={category} data={start} loading={loadingStart} onChangeCategory={onChangeCategory}/>
}

export default withSurveyFetchData('edit')({ start: true })(false)(Component)