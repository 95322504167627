/**
 * 요약데이터 많을때 보여지는 안내메시지 (로그인한 고객 화면)
 */
import { useIntl } from 'react-intl'
import { parseHtmlIntl } from 'ts-utils'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import ErrorTwoToneIcon from '@material-ui/icons/ErrorTwoTone'
import { ViewMode } from 'gql/survey_analysis_summary'
import useTokenInfo from 'hooks/useTokenInfo'
import useChannelIO from 'hooks/useChannel'
import SurveyCreateAnalysisSummaryDataComponent from 'component/Survey/Create/NewAnalysis/Summary/Data'

export const MyPaper = withStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '30px 20px',
        height: 'calc(100vh - 16.1em)',
        textAlign: 'center',
        fontSize: 16,
        [theme.breakpoints.down('xs')]: {
            height: 'calc(100vh - 22.3em)',
            fontSize: 11
        }
    }
}))(props => <Paper {...props} variant="outlined" />)

function ErrorUserMessage() {
    const { formatMessage: f } = useIntl()
    
    const { user_no, email } = useTokenInfo()
    const channelIO = useChannelIO({ user_no, email })


    async function openChannel() {
        const ChannelIO = await channelIO()
        ChannelIO('showMessenger')
    }


    return (
        <SurveyCreateAnalysisSummaryDataComponent top={0} viewMode={ViewMode.View} dialog={0} onTop={() => {}}>
            <MyPaper>
                <ErrorTwoToneIcon fontSize='large' color='secondary' style={{marginBottom: 10}}/>
               
                    {parseHtmlIntl(f({id: `container.Survey.Create.Analysis.Summary.ErrorUserMessage.content`}))}
               
                <Button variant="contained" color="primary" onClick={openChannel} style={{marginTop: 20}}>
                    {f({id: 'container.Survey.Create.Analysis.Summary.ErrorUserMessage.button.channel'})}
                </Button>
            </MyPaper>
        </SurveyCreateAnalysisSummaryDataComponent>
    )
}

export default ErrorUserMessage