import { useSelector } from 'react-redux'
import { 
    SurveyCreateComponent, 
    SurveyCreateLeftComponent,
    SurveyCreateLogicMenuComponent, 
    SurveyCreateLogicHelpComponent
} from 'component'
import JumpContainer from './Logic/Jump/Container'
import PageRandomContainer from './Logic/PageRandom/Container'
import QuestionRandomContainer from './Logic/QuestionRandom/Container'

const Component = (props) => {
    const { category, onChangeCategory } = props

    const state = useSelector(states => states.surveyCreateLogicMenuSelected)

    const selected = state.selected

    return (
        <>
         <div className='OuterPillar'>
            <SurveyCreateLeftComponent category={category} onChange={onChangeCategory}>
                <SurveyCreateLogicMenuComponent selected={selected}/>
                <SurveyCreateLogicHelpComponent layout={selected}/>
            </SurveyCreateLeftComponent>
        </div>
        <div className='OuterPillar'>
        <SurveyCreateComponent category={category} layout={selected}>
            <div className="Pillar">
                <SurveyCreateLogicMenuComponent/>
            </div>
            {
                selected === 'page_random' ? (
                    <PageRandomContainer/>
                ) : selected === 'question_random' ? (
                    <QuestionRandomContainer/>
                ) : selected === 'jump' ? (
                    <JumpContainer onChangeCategory={onChangeCategory}/>
                ) : null       
            }
        </SurveyCreateComponent>
        </div>
        </>
    )
}

export default Component