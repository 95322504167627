import { useMemo, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import withSurveyFetchMbox from 'hoc/Survey/FetchMbox'
import { SurveyCreateComponent, SurveyCreateLeftComponent, SurveyCreateMethodComponent, SurveyCreateMethodPreviewComponent } from 'component'
import useChangeMethod from 'hooks/useChangeMethod'

const Component = (props) => {
    const { category, mbox, refetchMbox, onChangeCategory } = props
    const { method } = mbox

    const dispatch = useDispatch()

    const params = useParams()
    const survey_no = Number(params.survey_no)

    const [ complete, onChange ] = useChangeMethod(survey_no)

    const layout = 'method'

    const handleChange = useCallback((val) => {
        dispatch({ type: BACKDROPSHOW })
        onChange(val)
    }, [onChange, dispatch])

    useEffect(() => {
        if (complete) {
            refetchMbox()
            dispatch({ type: BACKDROPHIDE })
        }
    }, [complete, refetchMbox, dispatch])

    return (
        <>
        <div className='OuterPillar'>
            {
                useMemo(() => (
                    <SurveyCreateLeftComponent category={category} onChange={onChangeCategory}/>
                ), [category, onChangeCategory])
            }
        </div>
        <div className='OuterPillar'>
            <SurveyCreateComponent category={category} layout={layout}>
                <div className="Pillar">
                    <SurveyCreateMethodComponent method={method} onChange={handleChange}/>
                </div>
                <div className="Pillar">
                    <SurveyCreateMethodPreviewComponent method={method}/>
                </div>
            </SurveyCreateComponent> 
        </div>
        </>
    )
}

export default withSurveyFetchMbox('edit')()(Component)