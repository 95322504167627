import { useReducer, useEffect } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { CHANGE } from 'reducer/survey/create/logic/menuAlarm'
import { UPDATE_SURVEY_MBOX_OPTION_PAGE_RANDOM, UPDATE_SURVEY_MBOX_OPTION_EXCEPT_LAST_PAGE_RANDOM } from 'gql/survey_mbox_option'
import { withSurveyFetchData } from 'hoc'
import PageRandomComponent from 'component/Survey/Create/Logic/PageRandom/Component'

// 초기값
const initialState = {
    page_random: false,
    except_last_page_random: false
}

function PageRanDomComponent (props) {
    const dispatch = useDispatch()
    const params = useParams()

    const { propsFetchData  } = props
    const { mboxOption } = propsFetchData
    const { page_random, except_last_page_random } = mboxOption

    const survey_no = Number(params.survey_no)

    const handleReducer = (state, action) => {
        switch (action.type) {
            case 'page_random': { // 페이지 랜덤
                return { ...state, page_random: action.page_random }
            }
            case 'except_last_page_random': { // 마지막 페이지 제외
                return { ...state, except_last_page_random: action.except_last_page_random }
            }
            default: {
                throw new Error(`unexpected action.type: ${action.type}`)
            }
        }
    }

    const [ states, dispatchStates ] = useReducer(handleReducer, initialState)

    const [ updateSurveyMboxOptionPageRandom ] = useMutation(UPDATE_SURVEY_MBOX_OPTION_PAGE_RANDOM, {
        onCompleted: (data) => {
            dispatch({ type: CHANGE, kinds: ['page_random'] })
        },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [ updateSurveyMboxOptionExceptLastPageRandom ] = useMutation(UPDATE_SURVEY_MBOX_OPTION_EXCEPT_LAST_PAGE_RANDOM, {
        onCompleted: (data) => {
            dispatch({ type: CHANGE, kinds: ['page_random'] })
        },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const handlePageRandom = {
        change: async(e) => {
            const page_random = e.target.checked
            updateSurveyMboxOptionPageRandom({variables: { survey_no, page_random }})
        },
        changeExceptLastPageRandom: async(e) => {
            const except_last_page_random = e.target.checked

            updateSurveyMboxOptionExceptLastPageRandom({variables: { survey_no, except_last_page_random }})
        }
    }

    useEffect(() => {
        dispatchStates({ type: 'page_random', page_random})
        dispatchStates({ type: 'except_last_page_random', except_last_page_random})
    }, [page_random, except_last_page_random, dispatch])

    useEffect(() => {
        dispatch({type: BACKDROPHIDE })
    }, [dispatch])

    return (
        <PageRandomComponent {...states} on={handlePageRandom}/>
    )
}

export default withSurveyFetchData('edit')({mboxOption: true})(false)(PageRanDomComponent)