// 레포트 편집에서는 사용자가 테스트로 응답한 값을 보여준다.
import { memo } from 'react'
import { Mode } from 'gql/survey_analysis_summary'
import withSurveySummary, { SummaryData } from 'hoc/Survey/Summary'
import { Props as ParentProps, Input } from './Container'
import ViewContainer from './View'

export interface Props {
    mbox: ParentProps['mbox'];
    design: ParentProps['design'];
    dialog: ParentProps['dialog'];
    survey_no: ParentProps['survey_no'];
    input: Input;
    summaryData: SummaryData;
}

function EditDataComponent(props: Props) {
    const { mbox, design, dialog, survey_no, input, summaryData } = props

    return (
        <ViewContainer
            mbox={mbox}
            design={design}
            dialog={dialog}
            survey_no={survey_no}
            input={input}
            summaryData={summaryData}
        />
    )
}

export default withSurveySummary(Mode.Edit)(true)(memo(EditDataComponent))