import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import { CREATE_SURVEY_QUESTION } from 'gql/survey_question'
import { CREATE_SURVEY_PAGE } from 'gql/survey_page'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { HIDE as SURVEYCREATEMENUHIDE } from 'reducer/survey/create/menu'
import { animateScroll as scroll } from 'react-scroll'
import useLimitQuestionMessage from 'hooks/useLimitQuestionMessage'
import { SurveyCreateMenuComponent } from 'component'

function MenuContainer(props) {
    const params = useParams()
    const {  scrollerObj, page, refetchQuestions, refetchPages, goPage, onClosePage } = props

    const survey_no = Number(params.survey_no)

    const dispatch = useDispatch()

    const callLimitQuestionMessage = useLimitQuestionMessage()

    const [ createSurveyQuestion ] = useMutation(CREATE_SURVEY_QUESTION, {
        onCompleted: async() => {
            // dispatch({ type: SURVEYCREATEMENUHIDE })

            await refetchQuestions()
            
            setTimeout(() => scroll.scrollToBottom({
                scrollTo: {
                    duration: 800,
                    smooth: 'easeInOutQuart'
                },
                containerId: scrollerObj.id
            }), 100)
        },
        onError: (e) => {
            dispatch({ type: BACKDROPHIDE })
            
            callLimitQuestionMessage(e)
        }
    })

    const [ createSurveyPage ] = useMutation(CREATE_SURVEY_PAGE, {
        onCompleted: async (data) => {
            dispatch({ type: SURVEYCREATEMENUHIDE })
            
            await refetchPages()
            goPage(data.createSurveyPage)
        },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })


    // 툴바에 위치한 기능
    const handleCreateModules = useCallback(async(module_type) => {
        const survey_page_no = Number(page)
        dispatch({ type: BACKDROPSHOW })
        createSurveyQuestion({variables: { survey_no, module_type, survey_page_no }})
    }, [ survey_no, page, createSurveyQuestion, dispatch ])


    const handleCreatePage = useCallback(async() => {
        dispatch({ type: BACKDROPSHOW })
        await onClosePage()
        createSurveyPage({variables: { survey_no }})
    }, [ survey_no, dispatch, onClosePage, createSurveyPage ])
    
    return (
       <SurveyCreateMenuComponent 
            onCreateModules={handleCreateModules} 
            onCreatePage={handleCreatePage} 
            {...props}
        />
    )
}

export default MenuContainer