import EditSurveyContext from 'context/editSurvey'
import { scrollerObj as _scrollerObj } from 'utils/survey'
import { withSurveyFetchMboxWithRelation, withSurveyFetchQuestions } from 'hoc'
import { SurveyCreateModuleContainer } from 'container'

const scrollerObj = _scrollerObj()

function PreviewContainer(props: any) {
    return (
        <EditSurveyContext.Provider value={{editOpen: true}}>
            <SurveyCreateModuleContainer 
                scrollerObj={scrollerObj} 
                viewType="multi" 
                {...props}
            />
        </EditSurveyContext.Provider>
    )
}

export default withSurveyFetchMboxWithRelation({mode: 'edit', fetchPolicy: 'cache-first'})(false)(withSurveyFetchQuestions('edit')(PreviewContainer))