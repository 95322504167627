import { isEqual } from 'lodash'
import { SurveyCreateThanksPreviewEndingComponent, SurveyCreateThanksPreviewStartComponent } from 'component'
import PropsContext from 'context/props'
import AccessMethodContext from 'context/accessMethod'
import IsEditComponent from './IsEdit'

export default (props) => {
    const { survey_no, category, rows, data, design, mbox } = props

    const isEqualValue = isEqual(data, rows)

    return (
        <>
        <AccessMethodContext.Provider value={{mode: 'editing'}}>
            <PropsContext.Provider value={{mbox, design}}>
            {
                category === 'thanks-start' ? (
                    <SurveyCreateThanksPreviewStartComponent rows={rows} isChange={!isEqualValue}/>
                ) : (
                    <SurveyCreateThanksPreviewEndingComponent survey_no={survey_no} rows={rows} isChange={!isEqualValue}/>
                )
            }
            </PropsContext.Provider>
        </AccessMethodContext.Provider>
        <IsEditComponent isEqualValue={isEqualValue}/>
        </>
    )
}