import { useEffect } from 'react'
import { withSurveyFetchMbox, withPrevState, withSurveySetState, withSurveySetReplys, withSurveyFetchQuestionsWithJumpOne } from 'hoc'
import PropsContext from 'context/props'
import AccessMethodContext from 'context/accessMethod'
import { memoryReply } from 'container/Survey/Live/Container'
import { SurveyCreateModuleOneComponent } from 'component'
import { mode } from '../ModuleRoot'

const Component = (props) => {

    const { focus, mbox, option, design, editQuestion, propsSetReplys, propsSetState, rowsJump } = props
    const { 
        replys, 
        format,
        refComments01,
        refComments02,
        refComments09,
        refComments10,
        refComments12,
        refComments13,
        refComments17,
        refComments18,
        refComments20,
        refCommentsWith, 
        handleChangeReplys
    } = propsSetReplys
    const { replyComments } = propsSetState

    useEffect(() => {
        // 편집문항 열때마다 reply 값을 초기화 해줘여한다
        memoryReply.rows = []
    }, [])


    if (typeof editQuestion.__typename === 'undefined') return null

    return (
        <AccessMethodContext.Provider value={{mode: 'editing'}}>
            <PropsContext.Provider value={{
                onChangeReplys: handleChangeReplys, 
                refComments01,
                refComments02,
                refComments09,
                refComments10,
                refComments12,
                refComments13,
                refComments17,
                refComments18,
                refComments20,
                refCommentsWith,
                format,
                replys,
                rowsJump,
                mbox,
                option, 
                replyComments, 
                caution: '',
                design
            }}>
                <SurveyCreateModuleOneComponent focus={focus} question={editQuestion}/>
            </PropsContext.Provider>
        </AccessMethodContext.Provider>
    )
}

export default withPrevState(withSurveySetState(withSurveySetReplys(withSurveyFetchMbox(mode)()(withSurveyFetchQuestionsWithJumpOne({ mode, loading: false })(Component)))))