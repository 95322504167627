import { useState } from 'react'
import { useIntl } from 'react-intl'
import {
    SurveyCreateThanksEditTabsComponent, 
    SurveyCreateThanksEditTabComponent, 
    SurveyCreateThanksEditQuestionComponent,
    SurveyCreateThanksEditOptionEndingComponent,
    SurveyCreateThanksEditEndingComponent
} from 'component'
import LabelComponent from 'component/Survey/Create/Thanks/Edit/Label'

const initialState = {
    isImageUpload: false
}

export default () => {
    const { formatMessage: f } = useIntl()

    const [ isImageUpload, setIsImageUpload ] = useState(initialState.isImageUpload)

    const tabs = [f({id: 'container.Survey.Edit.01.tabs.0'}), f({id: 'container.Survey.Edit.01.tabs.1'})]

    const handleOption = {
        changeIsImageUpload: () => setIsImageUpload(prevState => !prevState)
    }

    return (
        <div style={{position: 'relative'}}>
            <LabelComponent/>
            <SurveyCreateThanksEditTabsComponent tabs={tabs}/>
            <SurveyCreateThanksEditTabComponent index={0}>
                <SurveyCreateThanksEditQuestionComponent placeholder={f({id: 'container.Survey.Create.Thanks.EditEnding.placeholder'})}/>
                <SurveyCreateThanksEditEndingComponent/>
            </SurveyCreateThanksEditTabComponent>
            <SurveyCreateThanksEditTabComponent index={1}>
                <SurveyCreateThanksEditOptionEndingComponent isImageUpload={isImageUpload} on={handleOption}/>
            </SurveyCreateThanksEditTabComponent>
        </div>
    )
}