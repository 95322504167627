import { useState, useEffect } from 'react'
import { SurveyCreateSettingComponent } from 'component'
import { useMutation } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { UPDATE_SURVEY_MBOX_MARK_NUMBER, UPDATE_SURVEY_MBOX_MARK_REQUIRED } from 'gql/survey_mbox'

const initialState = {
    mark_number: true,
    mark_required: true
}

export default (props) => {
    const params = useParams()
    const dispatch = useDispatch()

    const survey_no = Number(params.survey_no)

    const [ state, setState ] = useState(initialState)
    
    const [ updateSurveyMboxMarkNumber ] = useMutation(UPDATE_SURVEY_MBOX_MARK_NUMBER, {
        onCompleted: async() => {
            await props.refetchMbox()
            await dispatch({ type: BACKDROPHIDE })
        },
        onError: async() => {
            await dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [ updateSurveyMboxMarkRequired ] = useMutation(UPDATE_SURVEY_MBOX_MARK_REQUIRED, {
        onCompleted: async () => {
            await props.refetchMbox()
            await dispatch({ type: BACKDROPHIDE })
        },
        onError: async() => {
            await dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const handle = {
        changeNumber: async(e) => {
            const mark_number = e.target.checked
        
            await dispatch({ type: BACKDROPSHOW })
            updateSurveyMboxMarkNumber({variables: { survey_no, mark_number }})
        },
        changeRequired: async(e) => {
            const mark_required = e.target.checked
        
            await dispatch({ type: BACKDROPSHOW })
            updateSurveyMboxMarkRequired({variables: { survey_no, mark_required }})
        }
    }

    useEffect(() => {
        setState({ 
            mark_number: props.mbox.mark_number, 
            mark_required: props.mbox.mark_required
        })
    }, [props.mbox])

    return (
        <SurveyCreateSettingComponent {...state} on={handle}/>
    )
}