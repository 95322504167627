import { useReducer, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'
import { Step, ViewMode } from 'gql/survey_analysis_summary'
import { getSearchCookie, setSearchCookie } from 'ts-utils/summary'
import Container from '@material-ui/core/Container'
import { withPrevState, withSurveyFetchData } from 'hoc'
import { createKey } from 'hoc/Survey/RowsPaging'
import { getDate } from 'styled/Survey/Analysis/Calendar'
import BeforeUnload from 'styled/BeforeUnload'
import { RootState } from 'reducer'
import SurveyCreateAnalysisSummaryComponent from 'component/Survey/Create/NewAnalysis/Summary/Component'
import SurveyCreateAnalysisButtonsComponent from 'component/Survey/Create/NewAnalysis/Buttons'
import PersonalComponent from 'component/Survey/Create/NewAnalysis/Rows/Personal/Component'
import SummaryButtonsComponent from 'component/Survey/Create/NewAnalysis/Summary/Buttons'
import RowsButtonsComponent from 'component/Survey/Create/NewAnalysis/Rows/Buttons'
import SummaryContainer from './Summary/Container'
import RowsContainer from './Rows/Container'
import { getDateCookieName } from './Insight'

export type OnChangeStartPoint = (value: number) => void;

export const defaultDesign = {
    font_family: 'Roboto',
    question: '50,50,50,1',
    background_color: '255, 255, 255, 1',
    bar: '64, 80, 181, 1'
}

const initialState = {
    confirmInit: false, // 필터, ip, 날짜 검색 조건이 있는상태에서 카테고리 이동시 (summmary <---> rows)
    selectedIndex: {type: '', index: ''}, //필터 추가인지 추가된 필터에서 문항 변경인지 type: add, change
    step: Step.End,
    date: '', // 2020.11.12~2020.12.17 형식으로 저장, 전체는 빈값
    questions: [],
    calendar: false, //달력 온오프
    help: false // help 도움말 팝업
}
    
export function parseDate(sdate: string, edate: string) {
    if (!sdate || !edate) return ''

    return `${sdate}~${edate}`
}

const handleReducer = (state: any, action: any) => {
    switch (action.type) {
        case 'confirmInit': { // 조건분석 선택한값
            return { ...state, confirmInit: action.confirmInit }
        }
        case 'selectedIndex': { // 조건분석 선택한값
            return { ...state, selectedIndex: action.selectedIndex }
        }
        case 'calendar': { // 검색 달력
            return { ...state, calendar: action.calendar }
        }
        case 'step': { // 모두, 완료, 미완료
            return { ...state, step: action.step }
        }
        case 'date': { // 검색날짜
            return { ...state, date: action.date }
        }
        case 'questions': { // 설문문항
            return { ...state, questions: action.questions }
        }
        case 'items': { // 통계데이터
            return { ...state, items: action.items }
        }
        case 'itemsMore': { // 통계데이터 more 기본 5개
            return { ...state, itemsMore: action.itemsMore }
        }
        case 'help': {
            return { ...state, help: action.help }
        }
        default: {
            throw new Error(`unexpected action.type: ${action.type}`)
        }
    }
}

const Component = (props: any) => {
    const { tab, propsFetchData, usePrevState } = props
    const { mbox, surveyQuestionsModuleAll: questions, endings, refetchSurveyQuestionsModuleAll: refetchQuestions, refetchEndings } = propsFetchData

    // 검색날짜 버튼 이름
    const dateCookieName = getDateCookieName(mbox.survey_no)

    // 스텝 버튼 이름 (완료 진행중)
    const stepCookieName = `summary-step-${mbox.survey_no}`

    // 진행상태 - 전체는 없어졌으므로 완료로 변경해준다
    if (getSearchCookie(stepCookieName)) {
        if (Number(getSearchCookie(stepCookieName)) === Step.All) {
            setSearchCookie(stepCookieName, Step.End)
        }
    }

    const params = useParams()
    const survey_no = Number(params.survey_no)

    const [ states, dispatchStates ] = useReducer(handleReducer, {
        ...initialState,
        date: getSearchCookie(dateCookieName) || '',
        step: getSearchCookie(stepCookieName) || Step.End
    })

    const [ startpoint, setStartpoint ] = useState(0)

    const prevTab = usePrevState(tab)

    const { data: conditionsPlay } = useSelector((state: RootState) => state.analysisFilterSaveData)

    const isConditionsPlay = conditionsPlay ? Object.keys(conditionsPlay).length > 0 : false

    const handleButtons = {
        changeInitial: () =>  {
            if (isConditionsPlay) createKey(survey_no)
            dispatchStates({type: 'date', date: ''})
            setStartpoint(0)
        },
        changeDate: (date: string) => {
            const newDate = getDate(date)
            const oriDate = getDate(states.date)
            // 다른 날짜로 변경시에는 응답 데이터 키값도 변경해줘여야 새롭게 데이터를 받는다.
            if (!(newDate.sdate === oriDate.sdate && newDate.edate === oriDate.edate)) {
                if (isConditionsPlay) createKey(survey_no)
            }
            dispatchStates({type: 'date', date })
            dispatchStates({type: 'calendar', calendar: false})
            setStartpoint(0)
        },
        openCalendar: () => dispatchStates({type: 'calendar', calendar: true}),
        closeCalendar: () => dispatchStates({type: 'calendar', calendar: false}),
        changeStep: useCallback((e: any) => { 
            const { value } = e.target

            if (isConditionsPlay) createKey(survey_no)

            setSearchCookie(stepCookieName, value)

            dispatchStates({ type: 'step', step: value })
            setStartpoint(0)
        }, [survey_no, isConditionsPlay, stepCookieName])
    }

    const handleChangeStartPoint = useCallback((value: number) => {
        setStartpoint(value)
    }, [])

    useEffect(() => {
        // summary <--> rows 탭 이동시 체크
        if (prevTab && prevTab !== tab) {
            if (states.date) {
                dispatchStates({ type: 'confirmInit', confirmInit: true })
            }
        }
    }, [tab, prevTab, states.date])

    // DB에 적용할 date 
    const { sdate, edate } = getDate(states.date)
    const dbDate = parseDate(sdate, edate)

    return (
        <>
        <SurveyCreateAnalysisSummaryComponent>
        {
                    
            tab === 'summary' ? (    
                <>
                <Container maxWidth="xl">
                    <SurveyCreateAnalysisButtonsComponent 
                        survey_no={survey_no} 
                        questions={questions} 
                        endings={endings}
                        open={states.calendar} 
                        step={states.step} 
                        wdate={Number(mbox.wdate)} 
                        date={states.date} 
                        dateCookieName={dateCookieName}
                        on={handleButtons}
                    >
                        <SummaryButtonsComponent
                            survey_no={survey_no} 
                            questions={questions} 
                            refetchQuestions={refetchQuestions}
                            refetchEndings={refetchEndings}                           
                        />
                    </SurveyCreateAnalysisButtonsComponent>
                </Container>
                <Container maxWidth="xl">
                    <SummaryContainer 
                        mbox={mbox}
                        dialog={true}
                        design={defaultDesign}
                        survey_no={survey_no}
                        step={states.step}
                        date={dbDate}
                        viewMode={ViewMode.View}
                        conditionsPlay={conditionsPlay}
                        print={false}
                    />
                </Container>
                <BeforeUnload/>
                </>
            ) : tab === 'rows' ? (
                <>
                <Container maxWidth="xl">
                    <SurveyCreateAnalysisButtonsComponent 
                        survey_no={survey_no} 
                        questions={questions} 
                        endings={endings}
                        open={states.calendar} 
                        step={states.step} 
                        wdate={Number(mbox.wdate)} 
                        date={states.date} 
                        dateCookieName={dateCookieName}
                        on={handleButtons}
                        onChangeStartPoint={handleChangeStartPoint}
                    >
                        <RowsButtonsComponent
                            survey_no={survey_no} 
                            questions={questions} 
                            step={states.step} 
                            date={dbDate}
                            conditionsPlay={conditionsPlay}
                            refetchQuestions={refetchQuestions}
                            refetchEndings={refetchEndings}          
                        />
                    </SurveyCreateAnalysisButtonsComponent>
                </Container>
                <Container maxWidth="xl">
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }}>
                            <RowsContainer
                                survey_no={survey_no}
                                step={states.step}
                                date={dbDate}
                                questions={questions}
                                conditionsPlay={conditionsPlay}
                                startpoint={startpoint}
                                onChangeStartPoint={handleChangeStartPoint}
                            />
                        </div>
                        <PersonalComponent questions={questions} endings={endings}/>
                    </div>
                
                </Container>
                </>
            ) : ''
        }
        </SurveyCreateAnalysisSummaryComponent>
        </>
    )
}

export default withSurveyFetchData('live')({
    mbox: true,
    surveyQuestionsModuleAll: true,
    endings: true,
    _fetchPolicy: { 
        mbox: 'cache-first',
        surveyQuestionsModuleAll: 'cache-first',
        endings: 'cache-first'
    }
})(false)(withPrevState(Component))