import withSurveyFetchData from 'hoc/Survey/FetchData'
import ThanksRootContainer from './ThanksRoot'

const Component = (props) => {
    const { category, onChangeCategory, propsFetchData } = props
    const { ending, loadingEnding } = propsFetchData

    return <ThanksRootContainer category={category} data={ending} loading={loadingEnding} onChangeCategory={onChangeCategory}/>
}

export default withSurveyFetchData('edit')({ ending: true })(false)(Component)