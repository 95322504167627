import { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { useIntl } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import DiagramContainer from './Diagram'
import { Context } from './Context'

// Diagram:다이어그램, Point:포인트합산(Point, Ending), Examples:보기 많은 선택 시
export enum Selected {
    Diagram, 
    Point,
    Ending,
    ExampleCount
}

const MyBox = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    transition: all .3s;

    & > div:nth-child(1) {
        grid-area: content-1;
        position: relative;

        & > div:nth-child(1) {
            display: block;
            height: 100%;
            grid-area: diagram;
        }
    }

    grid-template-areas: "content-1";
    grid-template-columns: 1fr;

`

const MessageBox = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

function Container() {
    const { questions } = useContext(Context)

    const { formatMessage: f } = useIntl()

    const [ mount1, setMount1 ] = useState<boolean>(false)

    useEffect(() => {
        setTimeout(() => {
            setMount1(true)
        }, 300)
    }, [])

    return (
        <MyBox>
            <div>
                <div style={{position: 'relative'}}>
                    {
                        mount1 && (
                            questions.length < 50 ? <DiagramContainer/> : <MessageBox><Typography>{f({id: 'container.Survey.Create.Logic.Jump.Container.message'})}</Typography></MessageBox>
                        )
                    }
                </div>
            </div>
        </MyBox>
    )
}

export default Container