import { useReducer, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { SHOW as POPOVERSHOW } from 'reducer/popover'
import clipboard from 'copy-to-clipboard'
import { useIntl } from 'react-intl'
import { getColorConcentration } from 'utils'
import { getShareLink } from 'utils/survey'
import { getShareDomain } from 'ts-utils/survey'
import { withSurveyFetchData } from 'hoc'
import randomString from 'randomstring'
import { 
    SurveyCreateShareFrameComponent, 
    SurveyCreateShareEmbedComponent, 
    SurveyCreateShareSnsComponent,
    SurveyCreateShareQrComponent,
    SurveyCreateShareEmailComponent,
    SurveyCreateShareStandardComponent,
    SurveyCreateSharePageComponent,
    SurveyCreateSharePopupComponent,
    SurveyCreateSharePopoverComponent, 
    SurveyCreateShareCodeModalComponent
} from 'component'
import { ColorPickerContainer } from 'container'

// 초기값
function initialState(f) {
    return {
        selected: 'sns',
        copyLink: false,
        standard: {
            width: '100',
            widthUnit: '%',
            height: '98',
            heightUnit: 'vh',
            shadow: true
        },
        popup: {
            text: f({id: 'container.Survey.Create.Share.Frame.popup.text'}),
            color: '63, 81, 181, 1',
            fontSize: 16,
            imageSrc: '',
            imageSize: 100,
            imageSizeUnit: 'px'
        },
        popover: {
            shape: 'add',
            color: '63, 81, 181, 1',
            imageSrc: '',
            size: 50,
            position: 'bottom-right'
        },
        code: {
            open: false,
            html: ''
        }
    }
}

const random = randomString.generate(5)

const handleReducer = (state, action) => {
    switch (action.type) {
        case 'selected': { // 카테고리 선택
            return { ...state, selected: action.selected }
        }
        case 'copyLink': { // 링크 복사
            return { ...state, copyLink: action.copyLink }
        }
        case 'standard': { // 페이지 상태값
            return { ...state, standard: action.standard }
        }
        case 'popup': { // 팝업 상태값
            return { ...state, popup: action.popup }
        }
        case 'popover': { // 팝오버 상태값
            return { ...state, popover: action.popover }
        }
        case 'code': { // 팝오버 상태값
            return { ...state, code: action.code }
        }
        default: {
            throw new Error(`unexpected action.type: ${action.type}`)
        }
    }
}

// 모달창 사용하는 카테고리
function isModal(key) {

    const is = ['email', 'standard', 'page', 'popup', 'popover']
    
    return is.includes(key)
}

// 파업창 사이즈
function getPopupImageSize(size, unit) {
    let customSize = size
    if (customSize < 1) customSize = 1

    if (unit === '%' && customSize > 100) {
        customSize = 100
    }

    return customSize
}


function getBtoa(url) {
    if (!url) return ''
    if (/^http/.test(url)) return url

    const customUrl = `${process.env.REACT_APP_ADMIN}${url}`

    const a = customUrl.split('userFiles')
    const b = btoa(a[0]).replace('==', '')
    const c = btoa(a[1]).replace('==', '')

    return `${c}DPOj7sG${b}`
}

const Component = (props) => {
    const { propsFetchData } = props
    const { mbox } = propsFetchData
    
    const { formatMessage: f } = useIntl()

    const dispatch = useDispatch()
   
    const [ states, dispatchStates ] = useReducer(handleReducer, initialState(f))


    const listoDomain = getShareDomain(mbox.survey_no)

    // listoDomain 짧은거면 min-url 
    const dataUrl = listoDomain === process.env.REACT_APP_NEW_RESPOND ? 'jjUVyey9x' : 'gRPOI'

    const shareLink = getShareLink({domain: listoDomain, args: mbox.args})

    const handleFrame = {
        change: (key) => {
            dispatchStates({ type: 'selected', selected: key})
        }
    }

    const hanldeEmail = {
        openCode: (type, getresponseType, button) => {

            /*const code = 
                type === 'stibee' ? 'email=$%email%$&name=$%name%$' : 
                type === 'mailchimp' ? 'email=*|EMAIL|*&name=*|FNAME|* *|LNAME|*' : 'email=[[email]]&name=[[name]]'*/

            const code = 
                type === 'stibee' ? 'email=$%email%$' : 
                type === 'mailchimp' ? 'email=*|EMAIL|*' : 
                type === 'getresponse' ? getresponseType === 'html' ? 'email=[[email]]' : `email={{CONTACT "subscriber_email"}}`
                : `email=${f({id: 'container.Survey.Create.Share.Frame.email.replace'})}`

            const url = `${shareLink.survey.email}?${code}`
            
            const html = button ? `<a href='${url}' target='_blank'>${f({id: 'container.Survey.Create.Share.Frame.email.start'})}</a>` : url

            dispatchStates({ type: 'code', code: {...states.code, open: true, html }})
        }
    }

    const handleStandard = {
        changeWidth: (width) => {
            dispatchStates({ type: 'standard', standard: {...states.standard, width }})
        },
        changeWidthUnit: (widthUnit) => {
            dispatchStates({ type: 'standard', standard: {...states.standard, widthUnit }})
        },
        changeHeight: (height) => {
            dispatchStates({ type: 'standard', standard: {...states.standard, height }})
        },
        changeHeightUnit: (heightUnit) => {
            dispatchStates({ type: 'standard', standard: {...states.standard, heightUnit }})
        },
        changeShadow: (shadow) => {
            dispatchStates({ type: 'standard', standard: {...states.standard, shadow }})
        },
        openCode: () => {
            let dataShadow = ''
            if (!states.standard.shadow) dataShadow = "data-shadow='none'"
            
            const html = `
            <iframe id='listo-survey' data-args='${mbox.args}' data-url='${dataUrl}' data-width='${states.standard.width}${states.standard.widthUnit}' data-height='${states.standard.height}${states.standard.heightUnit}' ${dataShadow} frameborder="0"></iframe>
            <script src="${process.env.REACT_APP_ASSETS}/js/standard.js?${random}"></script>`

            dispatchStates({ type: 'code', code: {...states.code, open: true, html }})
        }
    }

    const handlePage = {
       
        openCode: () => {
            const lang = mbox.lang === 'KR' ? 'ko' : 'en'

            const html = `
                <!doctype html><html lang="${lang}"> <head><meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"> <title>${mbox.title}</title><style type="text/css">html, body { margin: 0; height: 100%; overflow: hidden; } iframe { position: absolute; left:0; right:0; bottom:0; top:0; border:0; width: 100%; height: 100%; }</style> </head><body><iframe src="${shareLink.survey.web}?${Math.random()}" frameborder="0" allow="camera; microphone; autoplay; encrypted-media;"></iframe></body></html>`
            
            dispatchStates({ type: 'code', code: {...states.code, open: true, html }})
        }
    }

    const handlePopup = {
        changeText: useCallback((e) => {
            const { value: text } = e.target
            dispatchStates({ type: 'popup', popup: {...states.popup, text }})
        }, [states.popup]),
        changeFontSize: useCallback((e) => {
            const { value: fontSize } = e.target
            const customSize = fontSize < 1 ? 1 : fontSize > 99 ? 99 : fontSize
            dispatchStates({ type: 'popup', popup: {...states.popup, fontSize: customSize }})
        }, [states.popup]),
        changeImageSrc: useCallback((imageSrc) => {
            dispatchStates({ type: 'popup', popup: {...states.popup, imageSrc }})
        }, [states.popup]),
        changeImageSize: useCallback((e) => {
            const { value: imageSize } = e.target
    
            const customSize = getPopupImageSize(imageSize, states.popup.imageSizeUnit)

            dispatchStates({ type: 'popup', popup: {...states.popup, imageSize: customSize }})
        }, [states.popup]),
        changeImageSizeUnit: useCallback((e) => {
            const { value: imageSizeUnit } = e.target

            const customSize = getPopupImageSize(states.popup.imageSize, imageSizeUnit)

            dispatchStates({ type: 'popup', popup: {...states.popup, imageSize: customSize, imageSizeUnit }})
        }, [states.popup]),
        openColorPicker: (e) => {
            const { color } = states.popover

            dispatch({ 
                type: POPOVERSHOW, 
                component: <ColorPickerContainer color={color} onChange={handlePopup.changeColorPicker}/>,
                anchorEl: e.currentTarget,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                isClose: false
            })
        },
        changeColorPicker:  useCallback((color) => {
            const { r, g, b, a } = color.rgb
            dispatchStates({ type: 'popup', popup: {...states.popup, color: `${r},${g},${b},${a}` }})

        }, [states.popup]), 
        openCode: () => {
            const { popup } = states
            const { text, color, fontSize, imageSrc, imageSize, imageSizeUnit } = popup

            const src = getBtoa(imageSrc)

            let html = ''
            if (!src) {
                const textColor = getColorConcentration(color) ? 'white' : 'black'
                html = ` 
                    <div id='listo-survey' data-args='${mbox.args}' data-url='${dataUrl}' data-style='abled' style='display: table; border-radius: 3px; background-color: rgba(${color}); padding: .7em; cursor: pointer; color: ${textColor}; font-size: ${fontSize}px;'>${text}</div>
                    <script src="${process.env.REACT_APP_ASSETS}/js/popup.js?${random}"></script>
                `
            } else {
                html = ` 
                    <img id='listo-survey' data-args='${mbox.args}' data-url='${dataUrl}' data-style='abled' data-src='${src}' style='display: block; border-radius: 3px; cursor: pointer; max-width: ${imageSize}${imageSizeUnit};'/>
                    <script src="${process.env.REACT_APP_ASSETS}/js/popup.js?${random}"></script>
                `
            }

            dispatchStates({ type: 'code', code: {...states.code, open: true, html }})
        }
    }

    const handlePopover = {
        changeShape: (shape) => {
            dispatchStates({ type: 'popover', popover: {...states.popover, shape }})
        },
        changeImageSrc: useCallback((imageSrc) => {
            dispatchStates({ type: 'popover', popover: {...states.popover, imageSrc }})
        }, [states.popover]),
        changeSize: useCallback((size) => {
            const customSize = size < 1 ? 1 : size > 150 ? 150 : size
            dispatchStates({ type: 'popover', popover: {...states.popover, size: customSize }})
        }, [states.popover]),
        openColorPicker: (e) => {
            const { color } = states.popover

            dispatch({ 
                type: POPOVERSHOW, 
                component: <ColorPickerContainer color={color} onChange={handlePopover.changeColorPicker}/>,
                anchorEl: e.currentTarget,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                isClose: false
            })

        },
        changeColorPicker: color => {
            const { r, g, b, a } = color.rgb
            dispatchStates({ type: 'popover', popover: {...states.popover, color: `${r},${g},${b},${a}` }})

        },
        changePosition: (position) => {
            dispatchStates({ type: 'popover', popover: {...states.popover, position }})
        },
        openCode: () => {
            const src = getBtoa(states.popover.imageSrc)

            const html = `
            <listo-survey data-args='${mbox.args}' data-url='${dataUrl}' data-shape='${states.popover.shape}' data-color='${states.popover.color}' data-src='${src}' data-size='${states.popover.size}' data-position='${states.popover.position}'></listo-survey>
            <script src="${process.env.REACT_APP_ASSETS}/js/popover.js?${random}"></script>`

            dispatchStates({ type: 'code', code: {...states.code, open: true, html }})
        }
    }

    const handleCode = {
        close: () => {
            dispatchStates({ type: 'code', code: {
                ...states.code,
                open: false
            }})
        },
        copyCode: () => {
            clipboard(states.code.html)
            dispatchStates({ type: 'copyLink', copyLink: true})
        }
    }

    useEffect(() => {
        if (states.copyLink) {
            setTimeout(() =>  dispatchStates({ type: 'copyLink', copyLink: false}), 2000)
        }
    }, [dispatch, states.copyLink])


    useEffect(() => {
        async function receiveMessage(e) {
            if (e.origin === process.env.REACT_APP_ADMIN) {
                
                if (typeof e.data.dispatch === 'object') {
                    dispatch(e.data.dispatch)
                }
            } 
        }

        window.addEventListener("message", receiveMessage)
    }, [dispatch])
    
    return (
        <>
        <SurveyCreateShareFrameComponent selected={states.selected} on={handleFrame}>
            <div style={{display: states.selected === 'sns' ? 'block' : 'none'}}>
                <SurveyCreateShareSnsComponent title={mbox.title} args={mbox.args} listoDomain={listoDomain}/>
            </div>
            <div style={{display: states.selected === 'qrcode' ? 'block' : 'none'}}>
                <SurveyCreateShareQrComponent args={mbox.args}  listoDomain={listoDomain}/>
            </div>
            <div style={{display: states.selected === 'email' ? 'block' : 'none'}}>
                <SurveyCreateShareEmailComponent args={mbox.args} on={hanldeEmail} listoDomain={listoDomain}/>
            </div>
            <div style={{display: states.selected === 'standard' ? 'block' : 'none'}}>
                <SurveyCreateShareEmbedComponent args={mbox.args} {...states}>
                    <SurveyCreateShareStandardComponent standard={states.standard} on={handleStandard}/>
                </SurveyCreateShareEmbedComponent>
            </div>
            <div style={{display: states.selected === 'page' ? 'block' : 'none'}}>
                <SurveyCreateShareEmbedComponent args={mbox.args} {...states}>
                    <SurveyCreateSharePageComponent on={handlePage}/>
                </SurveyCreateShareEmbedComponent>
            </div>
            <div style={{display: states.selected === 'popup' ? 'block' : 'none'}}>
                <SurveyCreateShareEmbedComponent args={mbox.args} {...states}>
                    <SurveyCreateSharePopupComponent popup={states.popup} on={handlePopup}/>
                </SurveyCreateShareEmbedComponent>
            </div>
            <div style={{display: states.selected === 'popover' ? 'block' : 'none'}}>
                <SurveyCreateShareEmbedComponent args={mbox.args} {...states}>
                    <SurveyCreateSharePopoverComponent popover={states.popover} on={handlePopover}/>
                </SurveyCreateShareEmbedComponent>
            </div>
        </SurveyCreateShareFrameComponent>
        {
            isModal(states.selected) && (
                <SurveyCreateShareCodeModalComponent selected={states.selected} {...states.code} copyLink={states.copyLink} on={handleCode}/>
            )
        }
        </>
    )
}

export default withSurveyFetchData('preview')({mbox: true})(false)(Component)