import { useState, useMemo, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { SurveyCreateComponent, SurveyCreateLeftComponent } from 'component'
import { SurveyCreateThanksEditContainer, SurveyCreateThanksPreviewContainer } from 'container'
import withSurveyFetchMbox from 'hoc/Survey/FetchMbox'
import { useIntl } from 'react-intl'

// 텍스트값 입력후 반영 딜레이 시간
const delay = 300
let timeoutRef = {}

function clearTimeoutAll() {
    Object.keys(timeoutRef).map(c => clearTimeout(timeoutRef[c]))
}

let timer = null

const Component = (props) => {
    const { category, data, loading, onChangeCategory } = props

    const { formatMessage: f } = useIntl()

    const params = useParams()
    const survey_no = Number(params.survey_no)

    const [ rows, setRows ] = useState(data)
   
    // 초기화시 화면단 텍스트 초기화를 위해...
    const changeInit = useCallback((_category) => {
        clearTimeoutAll()
        setRows(data)
    }, [data])


    const changeStateStart = useCallback((row) => {
        const { img_src, img_position, img_name, used, align, agree, agree_icon_type } = row

        clearTimeout(timer)

        timer = setTimeout(() => {
            setRows(prevState => ({
                ...prevState,
                img_src,
                img_position,
                img_name,
                used,
                align,
                agree, 
                agree_icon_type
            }))
        }, 1) 
    }, [])

    const changeStateEnding = useCallback((row) => {
        const { message, ...other } = row
        clearTimeout(timer)

        timer = setTimeout(() => {
            setRows(prevState => ({
                ...prevState,
                ...other
            }))
        }, 1) 
    }, [])


    const changeState = useCallback((_rows) => {
        if (category === 'thanks-start') changeStateStart(_rows)
        else changeStateEnding(_rows)
    }, [category, changeStateStart, changeStateEnding])


    const changeRef = useCallback(props => {
        // 인풋값 적용
        const { name, value } = props

        clearTimeout(timeoutRef[name])

        timeoutRef[name] = setTimeout(() => {
            setRows(prevState => ({
                ...prevState,
                [name]: value
            }))
        }, delay)
    }, [])

    const changeButton = useCallback(e => {
        // 인풋값 적용
        const { name, value } = e.target

        clearTimeout(timeoutRef[name])

        timeoutRef[name] = setTimeout(() => {
            setRows(prevState => ({
                ...prevState,
                [name]: value
            }))
        }, delay)
    }, [])

    useEffect(() => {
        setRows(data)
    }, [data])

    return (
        <>
        {
            useMemo(() => (
                <div className='OuterPillar'>
                    <SurveyCreateLeftComponent category={category} title={f({id: `container.Survey.Create.ThanksRoot.category.title.${category}`})} onChange={onChangeCategory}/>
                </div>
            ), [f, category, onChangeCategory])
        }
        <div className='OuterPillar'>
            <SurveyCreateComponent category={category}>
                {
                    useMemo(() => {
                        return (
                            <div className="Pillar">
                            <SurveyCreateThanksEditContainer 
                                category={category}
                                onChangeCategory={onChangeCategory} 
                                survey_no={survey_no}
                                data={data}
                                loading={loading}
                                onEdit={{
                                    changeState,
                                    changeStateStart,
                                    changeStateEnding,
                                    changeRef,
                                    changeButton
                                }}
                                onChangeInit={changeInit}
                            />
                            </div>
                        )
                    
                    }, [
                        category,
                        onChangeCategory,
                        survey_no,
                        data,
                        loading, 
                        changeState,
                        changeStateStart,
                        changeStateEnding,
                        changeRef,
                        changeButton,
                        changeInit
                    ])
                }
                <div className="Pillar">
                    <SurveyCreateThanksPreviewContainer survey_no={survey_no} category={category} rows={rows} {...props}/>
                </div>
            </SurveyCreateComponent>
        </div>
        </>
    )
}

export default withSurveyFetchMbox('edit')()(Component)