import { useReducer, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { CHANGE } from 'reducer/survey/create/logic/menuAlarm'
import { GET_SURVEY_PAGE } from 'gql/survey_page'
import { UPDATE_SURVEY_MBOX_OPTION_QUESTION_RANDOM } from 'gql/survey_mbox_option'
import { withSurveyFetchData } from 'hoc'
import { SurveyCreateLogicQuestionRandomComponent } from 'component'

// 초기값
const initialState = {
    question_random: [],
    pages: [],
    allCheckedQuestionRandom: [false, false],
    survey_page_no: '' //문항리스트 보이는 페이지 번호
}

function EditContainer(props) {
    const { page, onChangePage, propsFetchData } = props
    const { mboxOption, refetchMboxOption } = propsFetchData
    const { question_random } = mboxOption

    const dispatch = useDispatch()
    const params = useParams()
  
    const survey_no = Number(params.survey_no)

    const handleReducer = (state, action) => {
        switch (action.type) {
            case 'question_random': { // option update
                return { ...state, question_random: action.question_random }
            }
            case 'pages': { // 전체 페이지
                return { ...state, pages: action.pages }
            }

            case 'allCheckedQuestionRandom': {
                return { ...state, allCheckedQuestionRandom: action.allCheckedQuestionRandom }
            }
            case 'survey_page_no': { //문항리스트 보이는 페이지 번호
                return { ...state, survey_page_no: action.survey_page_no }
            }
            default: {
                throw new Error(`unexpected action.type: ${action.type}`)
            }
        }
    }

    const [ states, dispatchStates ] = useReducer(handleReducer, initialState)

    useQuery(GET_SURVEY_PAGE, {
        variables: { 
            survey_no
        },
        onCompleted: async (data) => { 
            await dispatchStates({ type: 'pages', pages: data.surveyPage })
            dispatchStates({ type: 'question_random', question_random: data.surveyPage.map(c => ({
                survey_page_no: c.survey_page_no,
                indexs: c.indexs,
                checked: question_random.includes(c.survey_page_no),
                checking: false,
            }))})
        },
        onError: (e) => {
            console.log(e)
            dispatch({ type: ERRORPAGESHOW })
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    })

    const [ updateSurveyMboxOptionEditOepn ] = useMutation(UPDATE_SURVEY_MBOX_OPTION_QUESTION_RANDOM, {
        onCompleted: () => {
            refetchMboxOption()
            dispatch({ type: CHANGE, kinds: ['question_random'] })
        },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })
    
    const handleQuestionRandom = {
        change: (survey_page_no) => {
            const question_random = states.question_random.map(c => c.survey_page_no === survey_page_no ? ({...c, checking: !c.checking}) : c)
            dispatchStates({type: 'question_random', question_random })
        },
        changeAll: (e) => {
            const name = e.target.name
            const index = Number(name.replace('c-', ''))
            const checked = e.target.checked
            const question_random = index === 0 ? states.question_random.map(c => !c.checked ? ({...c, checking: checked}) : c) : 
                                    states.question_random.map(c => c.checked ? ({...c, checking: checked}) : c)

            dispatchStates({type: 'question_random', question_random })

            // 전체 체크박스 상태값 변경
            const allChecked = states.allCheckedQuestionRandom.map((c, i) => i === index ? checked : c)
            dispatchStates({type: 'allCheckedQuestionRandom', allCheckedQuestionRandom: allChecked})
        },
        add: async() => {
            // checked가 false이고 checking가 true라면 checked true, checking: false로 바꾼다
            try {
                const question_random =  states.question_random.map(c => !c.checked && c.checking ? ({...c, checked: true, checking: false}) : c)
                                            .filter(c => c.checked).map(c => c.survey_page_no)
            
                dispatchStates({type: 'allCheckedQuestionRandom', allCheckedQuestionRandom: initialState.allCheckedQuestionRandom})

                updateSurveyMboxOptionEditOepn({variables: { survey_no, question_random }})
            } catch(e) {
                throw e
            }

        },
        remove: async () => {

            // checked가 true이고 checking가 true라면 checked false, checking: false로 바꾼다
            try {
                const question_random =  states.question_random.map(c => c.checked && c.checking ? ({...c, checked: false, checking: false}) : c)
                                            .filter(c => c.checked).map(c => c.survey_page_no)
            
                dispatchStates({type: 'allCheckedQuestionRandom', allCheckedQuestionRandom: initialState.allCheckedQuestionRandom})

                updateSurveyMboxOptionEditOepn({variables: { survey_no, question_random }})
            } catch(e) {
                throw e
            }
        },
        openQuestions: (survey_page_no) => {
            // 같을걸 클릭하면 문항리스트 닫는거다
            const _survey_page_no = states.survey_page_no === survey_page_no ? '' : survey_page_no

            dispatchStates({type: 'survey_page_no', survey_page_no: _survey_page_no})
            onChangePage(survey_page_no !== page ? survey_page_no : 0)
        }
    }

    useEffect(() => {
        dispatchStates({ type: 'question_random', question_random: states.pages.map(c => ({
            survey_page_no: c.survey_page_no,
            indexs: c.indexs,
            checked: question_random.includes(c.survey_page_no),
            checking: false,
        }))})
    }, [question_random, states.pages])

    return (
        <SurveyCreateLogicQuestionRandomComponent {...states} on={handleQuestionRandom}/>
    )
}

export default withSurveyFetchData('preview')({
    mboxOption: true,
    _fetchPolicy: {
        mboxOption: 'cache-first'
    }
})(false)(EditContainer)